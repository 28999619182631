import SVG from 'Components/SVG';
import { useEffect, useState } from 'react';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { MainType } from './Container';
import { paymentStatus } from 'Assets/paymentStatus';
import { Formik } from 'formik';

import { orderStatus } from 'Assets/orderStatus';
import Button from 'Components/Button';
import { RolesTranslation } from 'Assets/RolesTranslation';
import { AddUserModal, EditUserModal } from './components';
import { useNavigate } from 'react-router';
import { isMobile } from 'Assets/isMobile';

function UsersList(props: MainType) {
  const { actions, usersList, authorizedUser } = props;
  const { getUsersList, addUser, editUser, changeSuspendedStatus, resendLink, getAuthorizedUser } = actions;
  const temporaryOrdersList: any = usersList.data || [];
  const [openedModal, setOpenedModal] = useState('');
  const [editData, setEditData] = useState({ firstName: '', lastName: '', email: '', role: '', uuid: '' });
  const [openMenu, setOpenMenu] = useState('');
  const isAdmin = authorizedUser?.role === 'admin';
  const navigate = useNavigate();

  useEffect(() => {
    if (isAdmin) {
      getUsersList();
    } else {
      navigate(ROUTES.DASHBOARD_ORDERS);
    }
  }, [authorizedUser]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (!event.target.closest('.users-list__menu')) {
        setOpenMenu('');
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="users-list">
      <EditUserModal
        isOpen={openedModal === 'editUser'}
        onClose={() => {
          setOpenedModal('');
          setEditData({ firstName: '', lastName: '', email: '', role: '', uuid: '' });
        }}
        addUser={addUser}
        edit={true}
        getUsersList={getUsersList}
        editUser={editUser}
        data={editData}
        authorizedUser={authorizedUser}
        getAuthorizedUser={getAuthorizedUser}
      />
      <AddUserModal
        isOpen={openedModal === 'addUser'}
        onClose={() => {
          setOpenedModal('');
          setEditData({ firstName: '', lastName: '', email: '', role: '', uuid: '' });
        }}
        addUser={addUser}
        edit={false}
        getUsersList={getUsersList}
        editUser={editUser}
        data={editData}
        authorizedUser={authorizedUser}
        getAuthorizedUser={getAuthorizedUser}
      />
      <div className="users-list__header">
        <h1>Lista użytkowników</h1>
        {isMobile() ? <Button label={'+'} className="users-list__add-user-button" onClick={() => setOpenedModal('addUser')} /> : null}
      </div>
      <table className="users-list__table">
        <thead className="users-list__thead">
          {isMobile() ? null : (
            <tr>
              <th>Imię</th>
              <th>Nazwisko</th>
              <th>Rola</th>
              <th>Email</th>
              <th>Status</th>
              <th>
                <Button label={'Dodaj +'} className="users-list__add-user-button" onClick={() => setOpenedModal('addUser')} />
              </th>
            </tr>
          )}
        </thead>
        <tbody className="users-list__tbody">
          {temporaryOrdersList.map((user, index) => (
            <tr className={`users-list__tr `} id={user.uuid} key={`page-${index}`}>
              <Formik
                initialValues={{
                  paymentStatus: paymentStatus.filter((status) => status.value === user.paymentStatus),
                  orderStatus: orderStatus.filter((status) => status.value === user.status),
                }}
                enableReinitialize
                onSubmit={async () => null}>
                {() =>
                  isMobile() ? (
                    <>
                      <td className="users-list__td">
                        <p className={`users-list__role users-list__role--${user.role}`}>{RolesTranslation[user.role][0]}</p>
                      </td>
                      <td
                        className={`users-list__td  ${user.suspended ? 'users-list__td--suspended' : ''} ${
                          user.activate ? '' : 'users-list__td--inactive'
                        }`}>
                        {user.firstName} {user.lastName}
                      </td>
                      <td className="users-list__td users-list__menu">
                        <span onClick={() => (openMenu === user.uuid ? setOpenMenu('') : setOpenMenu(user.uuid))}>
                          <SVG type={SVG_TYPE.DOTS} />
                        </span>
                        <div className={`users-list__menu-dropdown ${user.uuid === openMenu ? 'users-list__menu-dropdown--show' : ''}`}>
                          <div
                            className="users-list__menu-dropdown-item"
                            onClick={() => {
                              setEditData(user);
                              setOpenedModal('editUser');
                              setOpenMenu('');
                            }}>
                            Edytuj
                          </div>
                          <div
                            className="users-list__menu-dropdown-item"
                            onClick={async () => {
                              await changeSuspendedStatus(user.uuid);
                              getUsersList();
                              setOpenMenu('');
                            }}>
                            {user.suspended ? 'Odblokuj' : 'Zawieś'}
                          </div>
                          {!user.activate ? (
                            <div
                              className="users-list__menu-dropdown-item"
                              onClick={async () => {
                                resendLink(user.email);
                                setOpenMenu('');
                              }}>
                              Wyślij ponownie link aktywacyjny
                            </div>
                          ) : null}
                        </div>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="users-list__td">{user.firstName}</td>
                      <td className="users-list__td">{user.lastName}</td>
                      <td className="users-list__td">
                        <p className={`users-list__role users-list__role--${user.role}`}>{RolesTranslation[user.role]}</p>
                      </td>
                      <td className="users-list__td">{user.email}</td>
                      <td className="users-list__td">
                        {user.suspended || !user.activate ? (
                          <span className={`users-list__badge  ${user.suspended ? 'users-list__badge--suspended' : ''}`}>
                            {user.suspended ? 'Zawieszony' : user.activate ? '' : 'Nieaktywny'}
                          </span>
                        ) : (
                          <span className={`users-list__badge users-list__badge--active `}>Aktywny</span>
                        )}
                      </td>
                      <td className="users-list__td users-list__menu">
                        <span onClick={() => (openMenu === user.uuid ? setOpenMenu('') : setOpenMenu(user.uuid))}>
                          <SVG type={SVG_TYPE.DOTS} />
                        </span>
                        <div className={`users-list__menu-dropdown ${user.uuid === openMenu ? 'users-list__menu-dropdown--show' : ''}`}>
                          <div
                            className="users-list__menu-dropdown-item"
                            onClick={() => {
                              setEditData(user);
                              setOpenedModal('editUser');
                              setOpenMenu('');
                            }}>
                            Edytuj
                          </div>
                          <div
                            className="users-list__menu-dropdown-item"
                            onClick={async () => {
                              await changeSuspendedStatus(user.uuid);
                              getUsersList();
                              setOpenMenu('');
                            }}>
                            {user.suspended ? 'Odblokuj' : 'Zawieś'}
                          </div>
                          {!user.activate ? (
                            <div
                              className="users-list__menu-dropdown-item"
                              onClick={async () => {
                                resendLink(user.email);
                                setOpenMenu('');
                              }}>
                              Wyślij ponownie link aktywacyjny
                            </div>
                          ) : null}
                        </div>
                      </td>
                    </>
                  )
                }
              </Formik>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UsersList;
