import * as FilesModule from '../../../Modules/FilesModule';
import { Files } from '../EnumTypes/ActionEnumTypes';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Files.ADD_ORDER_FILE_INIT,
});

const success = (data: any) => ({
  type: Files.ADD_ORDER_FILE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Files.ADD_ORDER_FILE_FAILURE,
});

const addOrderFile =
  ({ files, languageUuid = undefined, orderUuid = undefined }) =>
  async (dispatch) => {
    dispatch(init());
    const id = toast.loading('Trwa dodawanie pliku...');
    try {
      dispatch(ViewManagementModule.Actions.showLoader());
      const data = await FilesModule.Connector.addOrderFile({ files, languageUuid, orderUuid });
      dispatch(success(data));
      dispatch(ViewManagementModule.Actions.hideLoader());
      // toast.success('Pomyślnie dodano plik', { icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} /> });
      toast.update(id, {
        render: 'Pomyślnie dodano plik',
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} />,
      });
      return data.data;
    } catch (err: any) {
      dispatch(ViewManagementModule.Actions.hideLoader());
      dispatch(failure());
      toast.update(id, {
        render: 'Nie udało się dodać pliku',
        type: toast.TYPE.ERROR,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.CIRCLE_X} />,
      });
      // toast.error('Nie udało się dodać pliku', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
      // toast.error('Niepoprawne dane logowania');
      return err.response;
    }
  };

export default addOrderFile;
