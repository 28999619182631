import SVG from 'Components/SVG';
import Switch from 'Components/Switch';
import { SVG_TYPE } from 'Shared/enums';
import { useState } from 'react';

type CookieModalProps = {
  isOpen: boolean;
  cookies: { necessary: boolean; performance: boolean; marketing: boolean; functional: boolean };
  setCookies: (cookies: { necessary: boolean; performance: boolean; marketing: boolean; functional: boolean }) => void;
  onAcceptModal: () => void;
  onAcceptAllModal: () => void;
};

const CookieBanner = ({ isOpen, cookies, setCookies, onAcceptModal, onAcceptAllModal }: CookieModalProps) => {
  const [openedCookies, setOpenedCookies] = useState({ necessary: false, performance: false, marketing: false, functional: false });

  return (
    <div className={`cookie-modal ${isOpen ? 'cookie-modal--show' : ''}`}>
      <h2 className="cookie-modal__title">Ustawienia plików cookie</h2>
      <p className="cookie-modal__subtitle">
        Wskaż proszę czy nasza strona może używać cookies / innych podobnych technologii („cookies”). Możesz dowiedzieć się więcej w jaki sposób
        używamy plików cookies na tej stronie zapoznając się z naszą polityką cookies. W każdej chwili możesz zmienić ustawienia dotyczące plików
        cookies, usuwając je ze swojej przeglądarki, jak i blokując możliwość ich instalacji. Aby dowiedzieć się jak tego dokonać - skorzystaj z okna
        Pomocy dostępnego w Twojej przeglądarce. <br />
        <br />
        Więcej informacji na temat plików cookies znajdziesz w naszej{' '}
        <a href="https://highline.com.pl/polityka_prywatnosci.pdf" rel="noreferrer" target="_blank">
          {' '}
          Polityce Prywatności
        </a>
      </p>
      <h2 className="cookie-modal__title">Zarządanie zgodami</h2>
      <div className="cookie-modal__options">
        <div className={`cookie-modal__option-wrapper ${openedCookies.necessary ? 'cookie-modal__option-wrapper--open' : ''}`}>
          <div className={`cookie-modal__option ${openedCookies.necessary ? 'cookie-modal__option--open' : ''}`}>
            <div className="cookie-modal__option-top">
              <h3>Niezbędne pliki cookie</h3>
              <div>
                <p className="cookie-modal__necessary">Zawsze aktywne</p>
                <span
                  className="cookie-modal__open-button"
                  onClick={() => setOpenedCookies({ ...openedCookies, necessary: !openedCookies.necessary })}>
                  <SVG type={SVG_TYPE.BACK_CHEVRON} />
                </span>
                {/* <Switch checked={performanceCookie} onClick={(value) => setPerformanceCookie(value)} /> */}
              </div>
            </div>
            <p className="cookie-modal__option-description">
              Te pliki cookie są niezbędne do działania strony internetowej i nie mogą zostać wyłączone w naszych systemach. Zazwyczaj są one
              ustawiane tylko w odpowiedzi na Twoje działania, które stanowią żądanie usług, takie jak ustawienie preferencji prywatności, logowanie
              się lub wypełnienie formularzy. Możesz ustawić swoją przeglądarkę tak, aby blokować lub ostrzegać Cię o tych plikach cookie, ale
              niektóre części strony nie będą działać. Te pliki cookie nie przechowują żadnych informacji umożliwiających bezpośrednie
              zidentyfikowanie Ciebie.
            </p>
          </div>
        </div>
        <div className={`cookie-modal__option-wrapper ${openedCookies.performance ? 'cookie-modal__option-wrapper--open' : ''}`}>
          <div className={`cookie-modal__option ${openedCookies.performance ? 'cookie-modal__option--open' : ''}`}>
            <div className="cookie-modal__option-top">
              <h3>Wydajnościowe pliki cookie</h3>
              <div>
                <Switch checked={cookies.performance} onClick={(value) => setCookies({ ...cookies, performance: value })} />
                <span
                  className="cookie-modal__open-button"
                  onClick={() => setOpenedCookies({ ...openedCookies, performance: !openedCookies.performance })}>
                  <SVG type={SVG_TYPE.BACK_CHEVRON} />
                </span>
              </div>
            </div>
            <p className="cookie-modal__option-description">
              Te pliki cookie pozwalają nam liczyć wizyty i źródła ruchu, dzięki czemu możemy mierzyć i poprawiać wydajność naszej strony. Pomagają
              nam zrozumieć, które strony są najbardziej i najmniej popularne oraz jak użytkownicy poruszają się po stronie. Wszystkie informacje
              zbierane przez te pliki cookie są segregowane i dlatego anonimowe. Jeśli nie zezwolisz na te pliki cookie, nie będziemy wiedzieć, kiedy
              odwiedziłeś naszą stronę i nie będziemy mogli monitorować jej wydajności.
            </p>
          </div>
        </div>
        <div className={`cookie-modal__option-wrapper ${openedCookies.functional ? 'cookie-modal__option-wrapper--open' : ''}`}>
          <div className={`cookie-modal__option ${openedCookies.functional ? 'cookie-modal__option--open' : ''}`}>
            <div className="cookie-modal__option-top">
              <h3>Funkcjonalne pliki cookie</h3>
              <div>
                <Switch checked={cookies.functional} onClick={(value) => setCookies({ ...cookies, functional: value })} />
                <span
                  className="cookie-modal__open-button"
                  onClick={() => setOpenedCookies({ ...openedCookies, functional: !openedCookies.functional })}>
                  <SVG type={SVG_TYPE.BACK_CHEVRON} />
                </span>
              </div>
            </div>
            <p className="cookie-modal__option-description">
              Te pliki cookie umożliwiają stronie internetowej zapewnienie funkcjonalności i personalizacji. Mogą być ustawione przez nas lub przez
              dostawców zewnętrznych, których usługi dodaliśmy do naszych stron. Jeśli nie zezwolisz na te pliki cookie, niektóre lub wszystkie z tych
              usług mogą nie działać poprawnie.
            </p>
          </div>
        </div>
        <div className={`cookie-modal__option-wrapper ${openedCookies.marketing ? 'cookie-modal__option-wrapper--open' : ''}`}>
          <div className={`cookie-modal__option ${openedCookies.marketing ? 'cookie-modal__option--open' : ''}`}>
            <div className="cookie-modal__option-top">
              <h3>Reklamowe pliki cookie</h3>
              <div>
                <Switch checked={cookies.marketing} onClick={(value) => setCookies({ ...cookies, marketing: value })} />
                <span
                  className="cookie-modal__open-button"
                  onClick={() => setOpenedCookies({ ...openedCookies, marketing: !openedCookies.marketing })}>
                  <SVG type={SVG_TYPE.BACK_CHEVRON} />
                </span>
              </div>
            </div>
            <p className="cookie-modal__option-description">
              Te pliki cookie mogą być ustawione przez naszą stronę internetową przez naszych partnerów reklamowych. Mogą być one używane przez te
              firmy do budowania profilu Twoich zainteresowań i pokazywania Ci odpowiednich reklam na innych stronach. Nie przechowują one
              bezpośrednio informacji osobistych, ale opierają się na unikalnym identyfikatorze Twojej przeglądarki i urządzenia internetowego. Jeśli
              nie zezwolisz na te pliki cookie, będziesz doświadczać mniej ukierunkowanej reklamy.
            </p>
          </div>
        </div>
      </div>
      <div className="cookie-modal__buttons">
        <button onClick={onAcceptAllModal}>Akceptuj wszystkie</button>
        <button onClick={onAcceptModal}>Zapisz ustawienia</button>
      </div>
    </div>
  );
};

export default CookieBanner;
