import SVG from 'Components/SVG';
import { useEffect, useRef, useState } from 'react';
import { SVG_TYPE } from 'Shared/enums';
import moment from 'moment';

import { MainType } from './Container';
import Pagination from 'Components/Pagination';
import { paymentStatus } from 'Assets/paymentStatus';
import { Field, Formik } from 'formik';
import { Select } from 'Components/form';
import { orderStatus } from 'Assets/orderStatus';
import { SingleOrder } from './components';
import { isMobile } from 'Assets/isMobile';
import AddTranslatedFileModal from './components/AddTranslatedFileModal';
import { toast } from 'react-toastify';

function OrderList(props: MainType) {
  const { actions, ordersList, initialOrder, translations, usersLabel, invoices } = props;
  const {
    getOrdersList,
    getOrder,
    downloadAllFiles,
    getUsersLabel,
    assignEmployee,
    editOrder,
    addTranslatedFile,
    deleteFile,
    downloadAllTranslations,
    getInvoice,
    clearInvoice,
  } = actions;
  const [currentPage, setCurrentPage] = useState(1);
  const [showOrder, setShowOrder] = useState('');
  const [showEmployeeFor, setShowEmployeeFor] = useState('');
  const temporaryOrdersList: any = ordersList.data || [];
  const [search, setSearch] = useState('current');
  const [fadeOut, setFadeOut] = useState('');
  const [showModal, setShowModal] = useState('');
  const formRef = useRef(null);
  const getOrders = () => {
    getOrdersList(currentPage, search);
  };

  const assignEmployeeToOrder = async (orderUuid: string, userUuid: string) => {
    await assignEmployee(orderUuid, userUuid);
    await getOrders();
    setShowEmployeeFor('');
  };

  useEffect(() => {
    getUsersLabel();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    setFadeOut('');
  }, [search]);

  useEffect(() => {
    getOrders();
  }, [currentPage, search]);

  useEffect(() => {
    const orderListRow = document.querySelectorAll('.order-list__tr');
    orderListRow.forEach((element) => {
      const order = temporaryOrdersList.find((order) => order.uuid === element.id);
      if (order.user.length !== 0 && !order.user[0].suspended && order.user[0].activate) {
        element.removeAttribute('locked');
      } else {
        element.setAttribute('locked', 'true');
      }
    });
  }, [temporaryOrdersList]);

  useEffect(() => {
    if (showOrder) {
      getOrder(showOrder);
      clearInvoice();
    }
  }, [showOrder]);

  useEffect(() => {
    if (ordersList.data?.length) {
      const orderListRow = document.querySelectorAll('.order-list__tr');
      orderListRow.forEach((element) => {
        if (element.getAttribute('hasEvent') !== 'true') {
          element.addEventListener('click', (e: any) => {
            // e.stopPropagation();
            const row = e.target.closest('.order-list__tr');
            if (
              !e.target.closest('.select__wrapper') &&
              !e.target.closest('.modal__backdrop') &&
              (isMobile() ? true : !e.target.closest('.order-list__coordinator'))
            ) {
              if (row.hasAttribute('open')) {
                setShowOrder('');
                row.removeAttribute('open', false);
              } else {
                if (!row.getAttribute('locked') || isMobile()) {
                  row.setAttribute('open', true);
                  setShowOrder(row.id);
                  const orderListRow = document.querySelectorAll('.order-list__tr');
                  orderListRow.forEach((element) => {
                    if (element.id !== row.id) {
                      element.removeAttribute('open');
                    }
                  });
                }
              }
            }
          });
        }
        element.setAttribute('hasEvent', 'true');
      });
    }
  }, [ordersList]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (!event.target.closest('.order-list__coordinator')) {
        setShowEmployeeFor('');
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOpenCoordinatorMenu = (uuid: string) => {
    if (isMobile()) {
      null;
    } else {
      if (showOrder ? showOrder === uuid : true) {
        if (showEmployeeFor) {
          showEmployeeFor === uuid ? setShowEmployeeFor('') : setShowEmployeeFor(uuid);
        } else {
          setShowEmployeeFor(uuid);
        }
      }
    }
  };

  return (
    <div className="order-list">
      <SingleOrder
        downloadAllFiles={downloadAllFiles}
        data={initialOrder}
        setShowOrder={setShowOrder}
        showOrder={showOrder}
        editOrder={editOrder}
        translations={translations}
        currentPage={currentPage}
        getOrders={getOrders}
        usersLabel={usersLabel}
        assignEmployeeToOrder={assignEmployeeToOrder}
        search={search}
        getOrder={getOrder}
        getInvoice={getInvoice}
        downloadAllTranslations={downloadAllTranslations}
        invoices={invoices}
      />
      <h1 className="order-list__header">Lista zleceń</h1>
      <div className="order-list__search-wrapper">
        <div
          className={`order-list__search-item ${search === 'current' ? 'order-list__search-item--active' : ''}`}
          onClick={() => setSearch('current')}>
          Aktualne
        </div>
        <div
          className={`order-list__search-item ${search === 'completed' ? 'order-list__search-item--active' : ''}`}
          onClick={() => setSearch('completed')}>
          Zrealizowane
        </div>
        <div
          className={`order-list__search-item ${search === 'archived' ? 'order-list__search-item--active' : ''}`}
          onClick={() => setSearch('archived')}>
          Zarchiwizowane
        </div>
      </div>
      <table className="order-list__table">
        <thead className="order-list__thead">
          <tr>
            <th>Nr.</th>
            <th>Koordynator</th>
            {isMobile() ? null : (
              <>
                <th>Data i godzina</th>
                <th className="order-list__principal">Zleceniodawca</th>
              </>
            )}
            <th className="order-list__deadline">Termin realizacji</th>
            {isMobile() ? (
              <>
                <th>S. płatn.</th>
                <th>S. zlec.</th>
              </>
            ) : (
              <>
                <th className="order-list__payment-status">Status płatności</th>
                <th className="order-list__order-status">Status zlecenia</th>
              </>
            )}
          </tr>
        </thead>
        <tbody className="order-list__tbody">
          {temporaryOrdersList.map((order) => (
            <tr
              className={`order-list__tr ${showOrder === order.uuid ? 'order-list__tr--active' : showOrder ? 'order-list__tr--inactive' : ''} ${
                fadeOut === order.uuid ? 'order-list__tr--fade-out' : ''
              }`}
              id={order.uuid}
              key={`page-${order.uuid}`}>
              <Formik
                innerRef={formRef}
                initialValues={{
                  paymentStatus: paymentStatus.filter((status) => status.value === order.paymentStatus),
                  orderStatus: orderStatus.filter((status) => status.value === order.status),
                  openModal: true,
                }}
                enableReinitialize
                onSubmit={async (values) => {
                  if (values.orderStatus[0].value === 'finished' && order.status !== 'finished' && values.openModal) {
                    if (order.paymentStatus !== 'paid' && order.paymentStatus !== 'stripe_payment' && order.paymentStatus !== 'deferred_payment') {
                      formRef.current.setFieldValue(
                        'orderStatus',
                        orderStatus.filter((status) => status.value === order.status),
                      );
                      toast.error('Nie można dodać plików do nieopłaconego zlecenia', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
                    } else {
                      setShowModal(order.uuid);
                      setShowOrder(order.uuid);
                    }
                  } else {
                    const result = await editOrder(order.uuid, { ...order, ...values });
                    if (result.status === 'archivied' && search === 'current') {
                      setFadeOut(order.uuid);
                      setTimeout(() => {
                        setSearch('archived');
                      }, 500);
                    } else if (search === 'archived' && result.status !== 'archivied') {
                      setFadeOut(order.uuid);
                      setTimeout(() => {
                        setSearch('current');
                      }, 500);
                    } else {
                      getOrders();
                      if (result.status === 'finished') {
                        setSearch('completed');
                        setShowOrder('');
                      }
                    }
                  }
                }}>
                {({ handleSubmit, values, setFieldValue }) => (
                  <>
                    <td className="order-list__td">{order.number}</td>
                    <td className="order-list__td">
                      {
                        <div className="order-list__coordinator" onClick={() => handleOpenCoordinatorMenu(order.uuid)}>
                          {order.user.length ? (
                            <>
                              <div
                                className={`order-list__coordinator-badge order-list__coordinator-badge--${order.user[0].role} ${
                                  order.user[0].suspended ? 'order-list__coordinator-badge--suspended' : ''
                                }`}>
                                {order.user[0].firstName[0]}
                                {order.user[0].lastName[0]}
                              </div>
                              {order.user[0].suspended || !order.user[0].activate ? (
                                <span className="order-list__coordinator-badge--warning">!</span>
                              ) : null}
                            </>
                          ) : (
                            <SVG type={SVG_TYPE.UNKNOW_BADGE} />
                          )}
                          <div
                            className={`order-list__coordinator-menu ${order.uuid === showEmployeeFor ? 'order-list__coordinator-menu--show' : ''}`}>
                            {usersLabel.map((user) => (
                              <div
                                className="order-list__coordinator-menu-item"
                                key={`${user.value}+${order.uuid}`}
                                onClick={() => assignEmployeeToOrder(order.uuid, user.value)}>
                                {user.label}
                              </div>
                            ))}
                          </div>
                        </div>
                      }
                    </td>
                    {isMobile() ? null : (
                      <>
                        <td className="order-list__td">{moment(order.createdAt).format('DD.MM.yyyy, HH:mm')}</td>
                        <td className="order-list__td order-list__customer">
                          {
                            <>
                              <span
                                className={`order-list__customer-badge order-list__customer-badge--${
                                  order.contactData?.companyName ? 'company' : order?.contactData?.firstName ? 'person' : ''
                                }`}>
                                {order.contactData?.companyName ? 'F' : order?.contactData?.firstName ? 'i' : ''}
                              </span>
                              <p className="order-list__customer-name">
                                {order.contactData?.firstName
                                  ? `${order.contactData?.firstName} ${order.contactData?.lastName}`
                                  : order?.contactData?.companyName
                                  ? `${order?.contactData?.companyName}`
                                  : ''}
                              </p>
                            </>
                          }
                        </td>
                        <AddTranslatedFileModal
                          addTranslatedFile={addTranslatedFile}
                          isOpen={showModal == order.uuid}
                          uuid={order.uuid}
                          onClose={() => setShowModal('')}
                          data={order}
                          handleSubmit={handleSubmit}
                          deleteFile={deleteFile}
                          setFieldValue={setFieldValue}
                          initialOrder={initialOrder}
                          getOrder={getOrder}
                        />
                      </>
                    )}
                    <td
                      className={`order-list__td 
                      ${order.deadline && moment(order.deadline).date() === moment().date() && search === 'current' ? 'order-list__td--deadline' : ''}
                      ${order.deadline && moment(order.deadline).date() < moment().date() && search === 'current' ? 'order-list__td--outdated' : ''}
                      `}>
                      {order.deadline ? moment(order.deadline).utc().format('DD.MM.yyyy, HH:mm') : ''}{' '}
                    </td>
                    <td className="order-list__td">
                      <div className="order-list__select-wrapper">
                        {isMobile() ? (
                          <SVG type={SVG_TYPE[paymentStatus.find((item) => item.value === order.paymentStatus).svgType]} />
                        ) : (
                          <Field
                            name="paymentStatus"
                            className={`order-list__select order-list__select--payment-status ${
                              values.paymentStatus[0]?.value === 'paid' || values.paymentStatus[0]?.value === 'stripe_payment'
                                ? 'order-list__select--paid'
                                : ''
                            }
                            ${order.user.length === 0 || order.user[0].suspended || !order.user[0].activate ? 'order-list__select--disabled' : ''}
                          `}
                            required
                            component={Select}
                            placeholder=""
                            options={paymentStatus}
                            disabled={
                              order.user.length === 0 ||
                              values.paymentStatus[0].value === 'paid' ||
                              values.paymentStatus[0]?.value === 'stripe_payment' ||
                              order.user[0].suspended ||
                              order.status === 'impossible_to_valuation' ||
                              !order.user[0].activate
                            }
                            handleSubmit={handleSubmit}
                            filterOption={(option, rawInput) => {
                              if (!option.data.hidden) {
                                return option.data.value.toLowerCase().includes(rawInput.toLowerCase());
                              }
                              return false;
                            }}
                          />
                        )}
                      </div>
                    </td>
                    <td className="order-list__td">
                      <div className="order-list__select-wrapper">
                        {isMobile() ? (
                          <SVG type={SVG_TYPE[orderStatus.find((item) => item.value === order.status).svgType]} />
                        ) : (
                          <Field
                            name="orderStatus"
                            className={`order-list__select order-list__select--order-status ${
                              order.user.length === 0 || order.user[0].suspended || !order.user[0].activate ? 'order-list__select--disabled' : ''
                            } ${search === 'completed' ? 'order-list__select--paid' : ''}`}
                            required
                            component={Select}
                            placeholder=""
                            options={orderStatus}
                            filterOption={(option, rawInput) => {
                              if (order.status === 'archivied') {
                                if (order.savedStatus === option.data.value) {
                                  return option.data.value.toLowerCase().includes(rawInput.toLowerCase());
                                }
                              } else if (
                                ((order.status === 'impossible_to_valuation' || order.status === 'valued') && option.data.color === 'yellow') ||
                                option.data.color === 'any'
                              ) {
                                return option.data.value.toLowerCase().includes(rawInput.toLowerCase());
                              } else if (order.status !== 'impossible_to_valuation' && order.status !== 'valued' && option.data.color === 'green') {
                                return option.data.value.toLowerCase().includes(rawInput.toLowerCase());
                              } else {
                                return false;
                              }
                            }}
                            disabled={
                              order.user.length === 0 ||
                              order.user[0].suspended ||
                              !order.user[0].activate ||
                              order.status === 'impossible_to_valuation' ||
                              search === 'completed'
                            }
                            handleSubmit={handleSubmit}
                          />
                        )}
                      </div>
                    </td>
                  </>
                )}
              </Formik>
            </tr>
          ))}
        </tbody>
      </table>
      {ordersList.meta?.last_page > 1 ? (
        <Pagination
          className="order-list__pagination"
          totalPages={ordersList.meta?.last_page || 0}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      ) : null}
    </div>
  );
}

export default OrderList;
