import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';
import { FieldProps } from 'formik';

interface CheckboxProps {
  name: string;
  label?: string;
  className?: string;
  id?: string;

  //formik
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: (value) => void;
  handleOnChange?: any;
  isOnBlurAction: boolean;
  editUser: (data) => void;
  onFocus: any;
}

function Checkbox({ field, errors = {}, form, label, className = '' }: CheckboxProps & FieldProps) {
  const handleOnClick = () => {
    form.setFieldValue(field.name, !field.value);
  };

  return (
    <div className={`checkbox ${className}`}>
      <label
        className={`checkbox__option ${field.value !== true ? 'checkbox__option--not-selected' : 'checkbox__option--selected'}  ${
          form.touched[field.name] && errors[field.name] ? 'checkbox__option--error' : ''
        }`}>
        <input type="checkbox" value={field.value} onChange={() => handleOnClick()} />
        <span className="checkbox__dummy-input">
          <SVG className={`checkbox__svg ${field.value ? 'checkbox__svg--checked' : ''}`} type={SVG_TYPE.CHECKBOX_CHECKED} />
        </span>
        <span className="checkbox__title-wrapper">{label}</span>
      </label>
      {errors && form.touched && <div className="input__error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>}
    </div>
  );
}

export default Checkbox;
