import { isMobile } from 'Assets/isMobile';
import { orderStatus } from 'Assets/orderStatus';
import { paymentStatus } from 'Assets/paymentStatus';
import Button from 'Components/Button';
import SVG from 'Components/SVG';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { SVG_TYPE } from 'Shared/enums';

interface TranslationListProps {
  translationsList: Array<any>;
  setOpenedModal: (value: string) => void;
  setEditData: (value: any) => void;
  editTranslation: (uuid, data) => any;
  getTranslationsList?: (page, filters) => void;
  currentPage: number;
  selectedFilters: any;
  isAdmin: boolean;
}

function Translations(props: TranslationListProps) {
  const { translationsList, setOpenedModal, setEditData, editTranslation, getTranslationsList, currentPage, selectedFilters, isAdmin } = props;
  const [openMenu, setOpenMenu] = useState('');
  useEffect(() => {
    function handleClickOutside(event) {
      if (!event.target.closest('.users-list__menu')) {
        setOpenMenu('');
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <table className="translations-list__table">
      <thead className="translations-list__thead">
        <tr>
          <th>Tłumaczenie z</th>
          <th></th>
          <th>Tłumaczenie na</th>
          {isMobile() ? null : (
            <>
              <th>Stawka za tłum. zwykłe</th>
              <th>Stawka za tłum. przysięgłe</th>
              <th>Status</th>
            </>
          )}
          <th>
            {!isMobile() && isAdmin ? (
              <Button
                label={isMobile() ? '+' : 'Dodaj +'}
                className="translations-list__add-user-button"
                onClick={() => setOpenedModal('addTranslation')}
              />
            ) : null}
          </th>
        </tr>
      </thead>
      <tbody className="translations-list__tbody">
        {translationsList.map((translation, index) => (
          <tr className={`translations-list__tr `} id={translation.uuid} key={`page-${index}`}>
            <Formik
              initialValues={{
                paymentStatus: paymentStatus.filter((status) => status.value === translation.paymentStatus),
                orderStatus: orderStatus.filter((status) => status.value === translation.status),
              }}
              enableReinitialize
              onSubmit={async () => null}>
              {() => (
                <>
                  <td className="translations-list__td">
                    <div className="translations-list__language">
                      <img src={translation.translationFrom.files.url} alt={translation.translationFrom.language} width={17} />
                      {translation.translationFrom.language}
                    </div>
                  </td>
                  <td className="translations-list__td">
                    <SVG type={SVG_TYPE.ARROW_RIGHT} />
                  </td>
                  <td className="translations-list__td">
                    {' '}
                    <div className="translations-list__language">
                      <img src={translation.translationInto.files.url} alt={translation.translationInto.language} width={17} />
                      {translation.translationInto.language}
                    </div>
                  </td>
                  {isMobile() ? null : (
                    <>
                      <td className="translations-list__td">{translation.pricePerSign} zł / znak</td>
                      <td className="translations-list__td">{translation.pricePerSignCertifiedTranslation} zł / znak</td>
                      <td className="translations-list__td">
                        <p className={`translations-list__status translations-list__status--${translation.active ? 'active' : 'inactive'}`}>
                          {translation.active ? 'Aktywny' : 'Nieaktywny'}
                        </p>
                      </td>
                    </>
                  )}
                  <td className="users-list__td users-list__menu">
                    {isAdmin ? (
                      <span onClick={() => (openMenu !== translation.uuid && isAdmin ? setOpenMenu(translation.uuid) : setOpenMenu(''))}>
                        <SVG type={SVG_TYPE.DOTS} />
                      </span>
                    ) : null}
                    <div className={`users-list__menu-dropdown ${translation.uuid === openMenu ? 'users-list__menu-dropdown--show' : ''}`}>
                      <div
                        className="users-list__menu-dropdown-item"
                        onClick={() => {
                          setEditData(translation);
                          setOpenedModal('editTranslation');
                          setOpenMenu('');
                        }}>
                        Edytuj
                      </div>
                      <div
                        className="users-list__menu-dropdown-item"
                        onClick={async () => {
                          await editTranslation(translation.uuid, {
                            pricePerSign: translation.pricePerSign,
                            pricePerSignCertifiedTranslation: translation.pricePerSignCertifiedTranslation,
                            active: !translation.active,
                          });
                          getTranslationsList(currentPage, selectedFilters);
                          setOpenMenu('');
                        }}>
                        {!translation.active ? 'Aktywuj' : 'Dezaktywuj'}
                      </div>
                    </div>
                  </td>
                </>
              )}
            </Formik>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Translations;
