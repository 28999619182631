import { connect } from 'react-redux';
import UsersList from './Main';
import { Actions as viewManagmentModule } from 'Modules/ViewManagementModule';
import { Actions as UserActions } from 'Modules/UserModule';
import { bindActionCreators } from 'redux';
import { Actions as AuthActions } from 'Modules/AuthModule';
import { usersList } from 'Store/Selectors/UserSelector';
import { authorizedUser } from 'Store/Selectors/AuthSelector';

type ConnectedP = {
  usersList: any;
  authorizedUser: any;
};

type DispatchedP = {
  actions: {
    setIsNaviagtionHidden: (boolean) => void;
    getUsersList: () => void;
    addUser: (userData) => void;
    editUser: (userData, uuid) => void;
    changeSuspendedStatus: (uuid) => void;
    resendLink: (email) => void;
    getAuthorizedUser: () => void;
  };
};

const mapStateToProps = (state) => ({
  usersList: usersList(state),
  authorizedUser: authorizedUser(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      setIsNaviagtionHidden: viewManagmentModule.setIsNaviagtionHidden,
      getUsersList: UserActions.getUsersList,
      addUser: UserActions.addUser,
      changeSuspendedStatus: UserActions.changeSuspendedStatus,
      editUser: UserActions.editUser,
      resendLink: UserActions.resendLink,
      getAuthorizedUser: AuthActions.getAuthorizedUser,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
