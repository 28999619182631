import { connect } from 'react-redux';
import Dashboard from './Main';
import { Actions as viewManagmentModule } from 'Modules/ViewManagementModule';
import { bindActionCreators } from 'redux';
import { authorizedUser } from 'Store/Selectors/AuthSelector';
import { Actions as AuthActions } from 'Modules/AuthModule';
import { Actions as LanguagesActions } from 'Modules/LanguagesModule';

type ConnectedP = {
  authorizedUser: any;
};

type DispatchedP = {
  actions: {
    setIsNaviagtionHidden: (boolean) => void;
    logout: () => void;
    getLanguages: () => void;
    getTranslations: () => void;
    changePassword: (currentPassword,newPassword) => void;
  };
};

const mapStateToProps = (state) => ({
  authorizedUser: authorizedUser(state),
});


const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      setIsNaviagtionHidden: viewManagmentModule.setIsNaviagtionHidden,
      logout: AuthActions.logout,
      getLanguages: LanguagesActions.getLanguages,
      getTranslations: LanguagesActions.getTranslations,
      changePassword: AuthActions.changePassword,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
