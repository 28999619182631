import Button from 'Components/Button';
import SVG from 'Components/SVG';
import moment from 'moment';
import { useRef } from 'react';
import { SVG_TYPE } from 'Shared/enums';

type CertifiedQualityFormProps = {
  setIsDeadlineModalOpen: (value: boolean) => void;
  currentStep: number;
  customDeadline: string;
  currentOrder: any;
  certifiedShowMore: boolean;
  setCertifiedShowMore: (value: boolean) => void;
};

function CertifiedQualityForm({
  currentStep,
  setIsDeadlineModalOpen,
  customDeadline,
  currentOrder,
  setCertifiedShowMore,
  certifiedShowMore,
}: CertifiedQualityFormProps) {
  const phoneRef = useRef(null);
  const orderQualityPrice = currentOrder?.orderQualityPrices?.basic;
  const orderQualityDeadline = currentOrder?.orderQualityDeadline?.basic;

  return (
    <div
      className={`container-left__slide container-left__slide--${currentStep} container-left__slide--${
        certifiedShowMore ? 'certified-show-more' : 'certified'
      }`}>
      <div className="certified-quality">
        <div className="certified-quality__card">
          <h2 className="certified-quality__price">{orderQualityPrice} zł</h2>
          <div className="certified-quality__date-wrapper">
            <p className="certified-quality__date-header">Termin realizacji do:</p>
            <p className="certified-quality__date">
              {customDeadline && customDeadline === 'basic' && currentOrder.deadline
                ? moment(currentOrder.deadline).format('DD.MM.yyyy, HH:mm')
                : moment(orderQualityDeadline).format('DD.MM.yyyy, HH:mm')}
            </p>
            <p className={`service-levels__need-it-quicker `} onClick={() => setIsDeadlineModalOpen(true)}>
              Potrzebujesz go szybciej?
            </p>
            <p
              className={`certified-quality__tooltip-show-more certified-quality__tooltip-show-more--mobile `}
              onClick={() => setCertifiedShowMore(!certifiedShowMore)}>
              {certifiedShowMore ? 'Mniej szczegółów' : 'Więcej szczegółów'}
            </p>
          </div>
        </div>
        <div className="certified-quality__right">
          <SVG type={SVG_TYPE.CERTIFIED_QUALITY} className={'certified-quality__svg'} />
          <div className={`certified-quality__tooltip-wrapper ${certifiedShowMore ? 'certified-quality__tooltip-wrapper--show-more' : ''}`}>
            <p className="certified-quality__tooltip-header">
              Tłumaczenia przysięgłe wyceniamy na podstawie stron rozliczeniowych. Jedna strona rozliczeniowa wynosi 1 125 znaków{' '}
              <br className="certified-quality__br-desktop" /> ze spacjami.
              <br /> <br /> Po wykonanym tłumaczeniu przysięgłym najpierw przesyłamy <br className="certified-quality__br-desktop" />
              do Ciebie skan gotowego dokumentu, a następnie przesyłamy oryginał za pośrednictwem poczty lub kuriera.
            </p>
            <p className="certified-quality__tooltip-show-more">Więcej szczegółów </p>
            <span className="certified-quality__tooltip">
              Wszystkie dokumenty posiadające moc prawną, które potrzebujesz przetłumaczyć na inny język powinny zostać przetłumaczone przez{' '}
              <strong>tłumacza przysięgłego</strong>. Tłumaczenia takie są wiarygodne dla wszystkich instytucji{' '}
              <br className="certified-quality__br-desktop" />i traktowane jako dokumenty oficjalne.
              <br />
              <br /> Tłumaczenia przysięgłe dokumentów urzędowych sporządzane są przez tłumaczy{' '}
              <strong>zaprzysiężonych przez Ministerstwo Sprawiedliwości</strong>. Tłumaczenie jest drukowane, a następnie na każdej stronie podbijane
              pieczęcią <br className="certified-quality__br-desktop" />i podpisywane. Posiada ono klauzulę poświadczającą o zgodności tłumaczenia z
              okazanym dokumentem.
            </span>
          </div>
        </div>
      </div>

      <div className="service-levels__contact">
        <span className="service-levels__contact-text">
          <p>Nie jesteś pewny, który pakiet wybrać?</p>
          <p> Masz więcej pytań?</p>
          <p className="service-levels__contact-us">
            {' '}
            Skontaktuj się z nami! Jesteśmy dostępni w godzinach<strong> 8:00 - 18:00</strong>
          </p>
        </span>
        <a href="tel:48733135033" ref={phoneRef}>
          <Button
            className="service-levels__contact-us--desktop"
            onClick={() => console.log('')}
            label="+48 733 135 033"
            stroke
            iconOnLeft
            svg_type={SVG_TYPE.PHONE}
          />
          <Button
            className="service-levels__contact-us--mobile"
            onClick={() => phoneRef.current.click()}
            label="+48 733 135 033"
            stroke
            iconOnLeft
            svg_type={SVG_TYPE.PHONE}
          />
        </a>
      </div>
    </div>
  );
}

export default CertifiedQualityForm;
