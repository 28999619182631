import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionFailed from './Main';
import { Actions as OrderActions } from 'Modules/OrderModule';

type ConnectedP = {
  changelogs: Array<any>;
};

type DispatchedP = {
  actions: {
    createPayment: (orderUuid) => void;
  };
};
const mapStateToProps = (state) => ({
  changelogs: state.changelogs,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      createPayment: OrderActions.createPayment,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(TransactionFailed);
