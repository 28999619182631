import SVG from 'Components/SVG';
import * as AuthModule from 'Modules/AuthModule';
import { toast } from 'react-toastify';
import { SVG_TYPE } from 'Shared/enums';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Auth.RESET_PASSWORD_INIT,
});

export const success = (authData) => ({
  type: Auth.RESET_PASSWORD_SUCCESS,
  payload: {
    authData,
  },
});

const failure = () => ({
  type: Auth.RESET_PASSWORD_FAILURE,
});

const resetPassword = (currentPassword: string, newPassword: string) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await AuthModule.Connector.changePassword(currentPassword, newPassword);
    dispatch(success(response.data));
    toast.success('Hasło zostało zmienione', { icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} /> });
    return true;
  } catch (err: any) {
    switch (err.response.data.messsages || err.response.data.message) {
      case "You can't set an old password":
        toast.error('Nowe hasło nie może być takie samo jak poprzednie', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
        break;
      case 'Bad Password':
        toast.error('Podane hasło jest nieprawidłowe', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
        break;
      default:
        toast.error('Nie udało się zmienić hasła', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
        break;
    }

    dispatch(failure());
    return false;
  }
};

export default resetPassword;
