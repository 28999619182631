import { User } from '../EnumTypes/ActionEnumTypes';
import * as UserModule from '../../../Modules/UserModule';
import { SVG_TYPE } from 'Shared/enums';
import SVG from 'Components/SVG';
import { toast } from 'react-toastify';

const init = () => ({
  type: User.CHANGE_SUSPENDED_STATUS_INIT,
});

const success = () => ({
  type: User.CHANGE_SUSPENDED_STATUS_SUCCESS,
  payload: {},
});

const failure = () => ({
  type: User.CHANGE_SUSPENDED_STATUS_FAILURE,
});

const changeSuspendedStatus = (userUuid) => async (dispatch) => {
  dispatch(init());
  try {
    await UserModule.Connector.changeSuspendedStatus(userUuid);
    dispatch(success());
    toast.success('Pomyślnie zmieniono status użytkownika', { icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} /> });
    return true;
  } catch (err: any) {
    dispatch(failure());
    toast.error('Nie udało się zmienić statusu użytkownika', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
    return false;
  }
};

export default changeSuspendedStatus;
