interface ModalProps {
  children: React.ReactNode;

  className?: string;
}

function ContainerRight({ children, className = '' }: ModalProps) {
  return (
    <div className="container-right__placeholder">
      <div className={`container-right ${className}`}>{children}</div>
    </div>
  );
}

export default ContainerRight;
