enum ROUTES {
  HOME_PAGE = '/',
  ORDER_PAGE = '/order',
  LOGIN_PAGE = '/login',
  TRANSACTION_SUCCESS = '/transaction-success',
  TRANSACTION_FAILED = '/transaction-failed',
  DASHBOARD = '/dashboard',
  DASHBOARD_ORDERS = '/dashboard/orders',
  DASHBOARD_TRANSLATIONS = '/dashboard/translations',
  DASHBOARD_USERS = '/dashboard/users',
  REQUEST_SEND = '/request-send',
  RESTORE_PASSWORD = '/restore-password',
  RESET_PASSWORD = '/reset/password',
  LINK_EXPIRED = '/link-expired',
  SUCCESFUL_PASSWORD_RESET = '/succesful-password-reset',
  ACTIVATE_PAGE = '/activate',
  PAGE404 = '/404',
}

export default ROUTES;
