import * as AuthModule from 'Modules/AuthModule';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Auth.GET_RESET_PASSWORD_TOKEN_INIT,
});

export const success = (authData) => ({
  type: Auth.GET_RESET_PASSWORD_TOKEN_SUCCESS,
  payload: {
    authData,
  },
});

const failure = () => ({
  type: Auth.GET_RESET_PASSWORD_TOKEN_FAILURE,
});

const getResetPasswordToken = (email: string) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await AuthModule.Connector.getResetPasswordToken(email);
    dispatch(success(response.data));
    toast.success('Pomyślnie wysłano link do zmiany hasła', { icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} /> });
  } catch (err) {
    dispatch(failure());
    toast.error('Nie udało się wysłać linku do zmiany hasła', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
  }
};

export default getResetPasswordToken;
