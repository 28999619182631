import { createBrowserRouter } from 'react-router-dom';
import ActivatePage from 'Screens/ActivatePage/Container';
import Dashboard from 'Screens/Dashboard/Container';
import HomePage from 'Screens/HomePage/Container';
import LinkExpiredPage from 'Screens/LinkExpiredPage/Container';
import LoginPage from 'Screens/LoginPage/Container';
import OrderList from 'Screens/OrderList/Container';
import OrderPage from 'Screens/OrderPage/Container';
import Page404 from 'Screens/Page404/Container';
import RequestSend from 'Screens/RequestSend/Container';
import RestorePasswordPage from 'Screens/RestorePasswordPage/Container';
import SuccesfulPasswordReset from 'Screens/SuccesfulPasswordReset/Container';
import TransactionFailed from 'Screens/TransactionFailed/Container';
import TransactionSuccess from 'Screens/TransactionSuccess/Container';
import TranslationsList from 'Screens/TranslationsList/Container';
import UsersList from 'Screens/UsersList/Container';
import { ROUTES } from 'Shared/enums';
import ProtectedRoute from './protectedRoute';

export const router = createBrowserRouter([
  {
    path: ROUTES.HOME_PAGE,
    element: <HomePage />,
    errorElement: <Page404 />,
  },
  {
    path: ROUTES.ORDER_PAGE,
    element: <OrderPage />,
    errorElement: <Page404 />,
  },
  {
    path: ROUTES.LOGIN_PAGE,
    element: <LoginPage />,
    errorElement: <Page404 />,
  },
  {
    path: ROUTES.TRANSACTION_FAILED,
    element: <TransactionFailed />,
    errorElement: <Page404 />,
  },
  {
    path: ROUTES.TRANSACTION_SUCCESS,
    element: <TransactionSuccess />,
    errorElement: <Page404 />,
  },
  {
    path: ROUTES.REQUEST_SEND,
    element: <RequestSend />,
    errorElement: <Page404 />,
  },
  {
    path: ROUTES.RESTORE_PASSWORD,
    element: <RestorePasswordPage />,
    errorElement: <Page404 />,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    element: <RestorePasswordPage />,
    errorElement: <Page404 />,
  },
  {
    path: ROUTES.SUCCESFUL_PASSWORD_RESET,
    element: <SuccesfulPasswordReset />,
    errorElement: <Page404 />,
  },
  {
    path: ROUTES.LINK_EXPIRED,
    element: <LinkExpiredPage />,
    errorElement: <Page404 />,
  },
  {
    path: ROUTES.ACTIVATE_PAGE,
    element: <ActivatePage />,
    errorElement: <Page404 />,
  },
  {
    path: ROUTES.PAGE404,
    element: <Page404 />,
    errorElement: <Page404 />,
  },
  {
    path: ROUTES.DASHBOARD,

    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'orders',
        element: <OrderList />,
        errorElement: <Page404 />,
      },
      {
        path: 'translations',
        element: <TranslationsList />,
        errorElement: <Page404 />,
      },
      {
        path: 'users',
        element: <UsersList />,
        errorElement: <Page404 />,
      },
    ],
  },
  {
    path: '*',
    element: <Page404 />,
  },
]);
