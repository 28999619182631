import { User } from '../EnumTypes/ActionEnumTypes';
import * as UserModule from '../../../Modules/UserModule';
import { SVG_TYPE } from 'Shared/enums';
import SVG from 'Components/SVG';
import { toast } from 'react-toastify';

const init = () => ({
  type: User.EDIT_USER_INIT,
});

const success = (data) => ({
  type: User.EDIT_USER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.EDIT_USER_FAILURE,
});

const editUser = (userUuid, userData) => async (dispatch) => {
  dispatch(init());
  try {
    const data = await UserModule.Connector.editUser(userUuid, userData);
    dispatch(success(data));
    toast.success('Pomyślnie edytowano użytkownika', { icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} /> });
    return true;
  } catch (err: any) {
    dispatch(failure());
    toast.error('Nie udało się edytować użytkownika', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
    return false;
  }
};

export default editUser;
