import * as OrderModule from '../../../Modules/OrderModule';
import { Order } from '../EnumTypes/ActionEnumTypes';
// import * as OrderActionModel from '../Models/OrderActionModel';

const init = () => ({
  type: Order.POST_ORDER_CONTACT_DATA_INIT,
});

const success = (data: any) => ({
  type: Order.POST_ORDER_CONTACT_DATA_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Order.POST_ORDER_CONTACT_DATA_FAILURE,
});

const postOrderContactData = (orderForm: any, orderUuid) => async (dispatch) => {
  const dataToSend = { ...orderForm };
  dataToSend.specialization = undefined;
  dataToSend.translateFrom = undefined;
  dataToSend.files = undefined;
  dataToSend.priorities = undefined;
  dataToSend.translationsUuids = undefined;
  dataToSend.translateTo = undefined;
  dataToSend.translationType = undefined;
  dataToSend.quality = undefined;

  dataToSend.orderUuid = orderUuid.uuid;
  dataToSend.address = dataToSend.street + ' ' + dataToSend.houseNumber;
  dataToSend.type = dataToSend.city ? (dataToSend.type || 'person') : undefined;

  dispatch(init());
  try {
    const data = await OrderModule.Connector.postOrderContactData(dataToSend);
    dispatch(success(data));
    return true;
  } catch (err: any) {
    dispatch(failure());
    return false;
  }
};

export default postOrderContactData;
