import * as AuthModule from '../../../Modules/AuthModule';
import { Auth, Order } from '../EnumTypes/ActionEnumTypes';
import * as AuthActionModel from '../Models/AuthActionModel';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Auth.LOG_OUT_INIT,
});

export const success = (): AuthActionModel.LogoutSuccess => ({
  type: Auth.LOG_OUT_SUCCESS,
});

const clearStore = () => ({
  type: Order.CLEAR_STORE,
});

const failure = (): AuthActionModel.LogoutFailure => ({
  type: Auth.LOG_OUT_FAILURE,
});

const logout = () => {
  return async (dispatch, getState) => {
    dispatch(init());
    try {
      await AuthModule.Connector.logout();
      console.log(getState());

      dispatch(success());
      dispatch(clearStore());
      toast.success('Wylogowano', { icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} /> });
    } catch (err) {
      dispatch(failure());
      toast.error('Nie udało się wylogować', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
    }
  };
};

export default logout;
