import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';
import { RadioOption } from 'Shared/models/RadioOptionsModel';
import { Field, FieldProps } from 'formik';

interface RadioProps {
  options: RadioOption[];
  name: string;

  label?: string;
  className?: string;
  required?: boolean;
  row?: boolean;
  id?: string;

  //formik
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: (value) => void;
  handleOnChange?: any;
  isOnBlurAction: boolean;
  editUser: (data) => void;
  onFocus: any;
}

function Radio({ field, errors = {}, form, label, className, name, options, required, row, id }: RadioProps & FieldProps) {
  return (
    <div id={id} className={`radio__wrapper ${className ? className : ''}`}>
      {label ? (
        <h4 className="radio__label">
          {label} {required ? null : <p className="select__optional">(opcjonalne)</p>}
        </h4>
      ) : null}
      <div className={`radio__input-container ${row ? 'radio__input-container--row' : ''}`}>
        <>
          {options.map((option) => (
            <label
              key={`${option.value}_${name}`}
              className={`radio__option ${row ? 'radio__option--row' : ''} ${
                field.value !== option.value ? 'radio__option--not-selected' : 'radio__option--selected'
              }  ${form.touched[field.name] && errors[field.name] ? 'radio__option--error' : ''}`}>
              <Field className={`radio__input`} type="radio" name={field.name} value={option.value} />
              <span className="radio__title-wrapper">
                {option.label}
                {option.subTitle ? <p className="radio__sub-title">{option.subTitle}</p> : null}
              </span>
              <div className="radio__icon-group">
                <SVG type={SVG_TYPE.CIRCLE} />
                <SVG type={SVG_TYPE.CHECKED} className={'radio__checked-icon'} />
              </div>
            </label>
          ))}
        </>
      </div>
      {errors && form.touched && <div className="input__error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>}
    </div>
  );
}

export default Radio;
