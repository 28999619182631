import { Input } from 'Components/form';
import SVG from 'Components/SVG';
import { Field } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { SingleFile } from 'Screens/OrderPage/components';
import { SVG_TYPE } from 'Shared/enums';
import { extensionsWithAutomaticPricing } from 'Shared/utils';

interface OrderDataProps {
  data: any;
  isEditable: boolean;
  errors;
  specializations: { value: string; label: string; svgType: SVG_TYPE };
  downloadAllFiles: (uuid: string) => void;
  translations: any;
  downloadAllTranslations: (uuid: string) => void;
  search: string;
  invoices: { invoice_pdf: string; number: string };
}

function OrderData({
  data,
  isEditable,
  errors,
  specializations,
  downloadAllFiles,
  translations,
  search,
  downloadAllTranslations,
  invoices,
}: OrderDataProps) {
  const [translateFrom, setTranslateFrom] = useState<any>([]);
  const [translateTo, setTranslateTo] = useState<any>([]);

  useEffect(() => {
    if ((translations?.length, data.translations)) {
      const translateFrom = data.translations.length ? data.translations[0].translationFrom : null;
      const translateTo = data.translations.length ? data.translations.map((item) => item.translationInto) : null;
      setTranslateFrom(translateFrom);
      setTranslateTo(translateTo);
    }
  }, [translations, data]);

  return (
    <div className="single-order__content-wrap">
      <div className={`summary__item ${data.createdAt ? '' : 'summary__item--hidden'}`}>
        <p className="summary__item-title">Data i godzina:</p>
        <div className={`summary__item-value `}>{moment(data.createdAt).format('DD.MM.yyyy, HH:mm')}</div>
      </div>

      <div className={`summary__item ${data.deadline ? '' : 'summary__item--hidden'}`}>
        <p className="summary__item-title">Termin realizacji:</p>
        <div
          className={`summary__item-value 
           ${data.deadline && moment(data.deadline).date() === moment().date() && search === 'current' ? 'order-list__td--deadline' : ''}
          ${data.deadline && moment(data.deadline).date() < moment().date() && search === 'current' ? 'order-list__td--outdated' : ''}`}>
          {moment(data.deadline).utc().format('DD.MM.yyyy, HH:mm')}
        </div>
      </div>

      <p className="summary__title">Para językowa:</p>
      <div className="summary__languages">
        <span className="summary__badge">
          <img className="summary__flag" src={translateFrom ? translateFrom.files?.url : null} alt={translateFrom ? translateFrom.language : null} />
          {translateFrom ? translateFrom.language : null}
        </span>
        <SVG type={SVG_TYPE.ARROW_RIGHT} />
        <div className="summary__languages-item">
          {translateTo?.length
            ? translateTo.map((item: any) => (
                <div key={item.value}>
                  <span className="summary__badge">
                    <img className="summary__flag" src={item.files?.url} alt={item?.language} />
                    {item?.language}
                  </span>
                </div>
              ))
            : null}
        </div>
      </div>

      <div className={`summary__item `}>
        <p className="summary__item-title">Liczba znaków:</p>
        <div className="summary__item-value">
          {isEditable ? (
            <Field
              component={Input}
              name="signCount"
              className={'single-order__input'}
              required
              placeholder={'Uzupełnij liczbę znaków'}
              treshold={0}
              errors={errors}
            />
          ) : (
            data.signCount
          )}
        </div>
      </div>

      <div className={`summary__item ${specializations ? '' : 'summary__item--hidden'}`}>
        <p className="summary__item-title">Specjalizacja:</p>
        <div className="summary__item-value summary__item-value--specialization">
          {specializations ? specializations.svgType && <SVG type={specializations.svgType} /> : null}
          {specializations ? specializations.label : null}
        </div>
      </div>

      <div className={`summary__item ${data?.priorities ? '' : 'summary__item--hidden'}`}>
        <p className="summary__item-title">Czas realizacji:</p>
        <div className="summary__item-value">{data.priorities === 'standard' ? 'Standard' : 'Ekspres'}</div>
      </div>

      <div className={`summary__item ${data?.translationType ? '' : 'summary__item--hidden'}`}>
        <p className="summary__item-title">Typ tłumaczenia:</p>
        <div className="summary__item-value">{data.translationType === 'regular' ? 'Zwykłe' : 'Przysięgłe'}</div>
      </div>

      <div className={`summary__item `}>
        <p className="summary__item-title">Pakiet:</p>
        <div className="summary__item-value">
          {data.quality === 'basic' ? 'Basic' : null}
          {data.quality === 'standard' ? 'Comfort' : null}
          {data.quality === 'expert' ? 'Expert' : null}
          {!data.quality ? '?' : null}
        </div>
      </div>

      <div className={`summary__price`}>
        <p>Wycena:</p>
        <p className="summary__price--value"> {data.price ? `${data.price} zł ` : '?'}</p>
      </div>

      {data?.files?.length ? (
        <div className="summary__files-header single-order__files-header">
          <p>Załączone pliki:</p>
          {data?.files?.length ? (
            <span className="summary__download-all" onClick={() => downloadAllFiles(data.uuid)}>
              Pobierz wszystkie
            </span>
          ) : null}
        </div>
      ) : null}

      <div className="summary__files-wrapper">
        {data?.files?.length
          ? data?.files
              .filter((file: any) => !!extensionsWithAutomaticPricing.exec(file.name) && file.fileData.sign_count > 0)
              .map((item: any) => (
                <SingleFile
                  currentStep={2}
                  deleteFile={() => null}
                  key={`${item.name} ${item.updatedAt}`}
                  data={item}
                  values={[]}
                  isDownload
                  setFieldValue={() => null}
                />
              ))
          : null}

        {data?.files?.filter((file: any) => !extensionsWithAutomaticPricing.exec(file.name) || file.fileData.sign_count == 0).length ? (
          <div className="summary__incorrect-format">
            <h4 className="summary__incorrect-format-header">Osobna Wycena</h4>
            {data?.files
              .filter((file: any) => !extensionsWithAutomaticPricing.exec(file.name) || file.fileData.sign_count == 0)
              .map((item: File) => (
                <SingleFile
                  isDownload
                  currentStep={2}
                  deleteFile={() => null}
                  key={`${item.name}`}
                  data={item}
                  values={[]}
                  setFieldValue={() => null}
                />
              ))}
          </div>
        ) : null}

        {data?.translatedFiles?.length ? (
          <div className="summary__translated-files">
            <div className="summary__translated-files-header">
              <h4>Przetłumaczone pliki</h4>
              <span className="summary__download-all" onClick={() => downloadAllTranslations(data.uuid)}>
                Pobierz wszystkie
              </span>
            </div>

            {data?.translatedFiles.map((item: File) => (
              <SingleFile
                isDownload
                currentStep={2}
                deleteFile={() => null}
                key={`${item.name}`}
                data={item}
                values={[]}
                setFieldValue={() => null}
              />
            ))}
          </div>
        ) : null}

        {invoices?.number && data.paymentStatus === 'stripe_payment' ? (
          <div className="summary__translated-files">
            <div className="summary__translated-files-header">
              <h4>Faktura</h4>
            </div>

            <SingleFile
              isDownload
              currentStep={2}
              deleteFile={() => null}
              key={`${invoices.invoice_pdf}`}
              data={{ ...invoices, name: invoices.number }}
              values={[]}
              variant="invoice"
              setFieldValue={() => null}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default OrderData;
