import { User } from '../EnumTypes/ActionEnumTypes';
import * as UserModule from '../../../Modules/UserModule';

const init = () => ({
  type: User.GET_USERS_INIT,
});

const success = (data: any) => ({
  type: User.GET_USERS_SUCCESS,
  payload: {
    data: data.data.users,
  },
});

const failure = () => ({
  type: User.GET_USERS_FAILURE,
});

const getUsersList = () => async (dispatch) => {
  dispatch(init());
  try {
    const data = await UserModule.Connector.getUsers();
    dispatch(success(data));
  } catch (err: any) {
    dispatch(failure());
    console.log('GET_ORDERS_LIST', err.response);
  }
};

export default getUsersList;
