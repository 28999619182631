import { connect } from 'react-redux';
import LoginPage from './Main';
import { Actions as AuthActions } from 'Modules/AuthModule';
import { bindActionCreators } from 'redux';
import { authorizedUser } from 'Store/Selectors/AuthSelector';
import { Actions as viewManagmentModule } from 'Modules/ViewManagementModule';

type ConnectedP = {
  authorisedUser: any;
  location: any;
};

type DispatchedP = {
  actions: {
    login: (email: string, password: string) => any;
    setIsNaviagtionHidden: (boolean) => void;
  };
};
const mapStateToProps = (state) => ({
  authorisedUser: authorizedUser(state),
  location: state.router.location,
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      login: AuthActions.login,
      setIsNaviagtionHidden: viewManagmentModule.setIsNaviagtionHidden,

    },
    dispatch,
  ),
});
export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
