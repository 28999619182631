import * as LanguageModule from '../../../Modules/LanguagesModule';
import { Languages } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Languages.EDIT_TRANSLATION_INIT,
});

const success = (data: any) => ({
  type: Languages.EDIT_TRANSLATION_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Languages.EDIT_TRANSLATION_FAILURE,
});

const editTranslation = (uuid, formData) => async (dispatch) => {
    const dataToSend = {
        ...formData,
      };
    dataToSend.translationFromLanguageUuid = undefined;
    dataToSend.translationIntoLanguageUuid = undefined;
  dispatch(init());
  try {
    const data = await LanguageModule.Connector.editTranslation(uuid, dataToSend);
    dispatch(success(data));
    toast.success('Pomyślnie edytowano tłumaczenie', { icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} /> });
    return true;
  } catch (err: any) {
    dispatch(failure());
    toast.error('Nie udało się edytować tłumaczenia', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
    return false;
  }
};

export default editTranslation;
