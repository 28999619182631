import { SVG_TYPE } from 'Shared/enums';

export const orderStatus = [
  { value: 'impossible_to_valuation', label: 'Niewycenione', svgType: SVG_TYPE.PRICE_TAG, color: 'none' },
  { value: 'valued', label: 'Wycenione', svgType: SVG_TYPE.CLOCK, color: 'none' },
  { value: 'new_order', label: 'Nowe zlecenie', svgType: SVG_TYPE.BREIFCASE, color: 'green' },
  { value: 'in_realisation', label: 'W trakcie realizacji', svgType: SVG_TYPE.PROCESS, color: 'green' },
  { value: 'finished', label: 'Przetłumaczone', svgType: SVG_TYPE.FILE_CHECKED, color: 'green' },
  { value: 'archivied', label: 'Zarchiwizowane', svgType: SVG_TYPE.ARCHIVED, color: 'any' },
];
