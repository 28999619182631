import { isMobile } from 'Assets/isMobile';
import SVG from 'Components/SVG';
import { createRef, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { ChangePasswordModal, LeftBar, MobileDashboardMenu } from './components';
import { MainType } from './Container';

function Dashboard(props: MainType) {
  const { actions, authorizedUser } = props;
  const { setIsNaviagtionHidden, logout, getTranslations, getLanguages, changePassword } = actions;
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const node = createRef<any>();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);

  useEffect(() => {
    setIsNaviagtionHidden(true);
    getTranslations();
    getLanguages();
    localStorage.removeItem('lastPage');
    return () => {
      setIsNaviagtionHidden(false);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (!event.target.closest('.dashboard__menu')) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    await logout();
    localStorage.setItem('lastPage', '/dashboard');
    navigate(ROUTES.LOGIN_PAGE);
  };

  return (
    <div className="dashboard" ref={node}>
      <LeftBar pathname={pathname} navigate={navigate} authorizedUser={authorizedUser} />
      <MobileDashboardMenu
        pathname={pathname}
        navigate={navigate}
        authorizedUser={authorizedUser}
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
        handleLogout={handleLogout}
        setIsChangePasswordModalOpen={setIsChangePasswordModalOpen}
      />
      <ChangePasswordModal isOpen={!!isChangePasswordModalOpen} changePassword={changePassword} onClose={() => setIsChangePasswordModalOpen(false)} />
      <div className="dashboard__right-side">
        <div className="dashboard__header">
          <span className="dashboard__header-spacer" />
          <div className="dashboard__header-content">
            {isMobile() ? (
              <>
                <span className="dashboard__header-logo" onClick={() => navigate(ROUTES.DASHBOARD)}>
                  <SVG type={SVG_TYPE.LOGO} />
                </span>
                <div onClick={() => setIsMobileMenuOpen(true)}>
                  <SVG type={SVG_TYPE.HAMBURGER} />
                </div>
              </>
            ) : (
              <div className="dashboard__menu" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                {authorizedUser?.firstName} {authorizedUser?.lastName}
                <div className={`dashboard__chevron ${isDropdownOpen ? 'dashboard__chevron--open' : ''} `}>
                  <SVG type={SVG_TYPE.BACK_CHEVRON} />
                  <div className={`dashboard__dropdown-menu ${isDropdownOpen ? 'dashboard__dropdown-menu--open' : ''} `}>
                    <p onClick={() => setIsChangePasswordModalOpen(true)}>Zmień hasło</p>
                    <p onClick={() => handleLogout()}>Wyloguj</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
}

export default Dashboard;
