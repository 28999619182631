import SVG from 'Components/SVG';
import { useEffect } from 'react';
import { ROUTES, SVG_TYPE } from 'Shared/enums';

interface MobileDashboardMenuProps {
  pathname: string;
  navigate: (path: string) => void;
  authorizedUser: any;
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: (value: boolean) => void;
  handleLogout: () => void;
  setIsChangePasswordModalOpen: (value: boolean) => void;
}

function MobileDashboardMenu({
  pathname,
  navigate,
  authorizedUser,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  handleLogout,
  setIsChangePasswordModalOpen,
}: MobileDashboardMenuProps) {
  const isAdmin = authorizedUser?.role === 'admin';

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
      document.querySelector('html').style.overflow = 'hidden';
    } else {
      //remove overflow style from inline styles
      document.body.style.overflow = '';
      document.querySelector('html').style.overflow = '';
    }
  }, [isMobileMenuOpen]);

  return (
    <div className={`mobile-dashboard-menu ${isMobileMenuOpen ? 'mobile-dashboard-menu--show' : ''}`}>
      <div className="mobile-dashboard-menu__header">
        <SVG type={SVG_TYPE.LOGO} className="svg--logo" />
        <span className="mobile-dashboard-menu__close" onClick={() => setIsMobileMenuOpen(false)}>
          <SVG type={SVG_TYPE.CLOSE} />
        </span>
        <div className="mobile-dashboard-menu__user">
          <span className="mobile-dashboard-menu__badge">
            {authorizedUser?.firstName[0]} {authorizedUser?.lastName[0]}
          </span>
          <h2>
            {authorizedUser?.firstName} {authorizedUser?.lastName}
          </h2>
        </div>
      </div>

      <nav className="mobile-dashboard-menu__content">
        <ul className="mobile-dashboard-menu__list">
          <li>
            <a
              className={`mobile-dashboard-menu__link ${pathname === ROUTES.DASHBOARD_ORDERS ? 'mobile-dashboard-menu__link--active' : ''}`}
              onClick={() => {
                pathname !== ROUTES.DASHBOARD_ORDERS && navigate(ROUTES.DASHBOARD_ORDERS);
                pathname !== ROUTES.DASHBOARD_ORDERS && setIsMobileMenuOpen(false);
              }}>
              <SVG type={SVG_TYPE.LIST} />
              <span>Lista zleceń</span>
            </a>
          </li>

          <li>
            <a
              className={`mobile-dashboard-menu__link ${pathname === ROUTES.DASHBOARD_TRANSLATIONS ? 'mobile-dashboard-menu__link--active' : ''}`}
              onClick={() => {
                pathname !== ROUTES.DASHBOARD_TRANSLATIONS && navigate(ROUTES.DASHBOARD_TRANSLATIONS);
                pathname !== ROUTES.DASHBOARD_TRANSLATIONS && setIsMobileMenuOpen(false);
              }}>
              <SVG type={SVG_TYPE.COMMENTS} />
              <span>Języki i tłumaczenia</span>
            </a>
          </li>

          {isAdmin ? (
            <li>
              <a
                className={`mobile-dashboard-menu__link ${pathname === ROUTES.DASHBOARD_USERS ? 'mobile-dashboard-menu__link--active' : ''}`}
                onClick={() => {
                  pathname !== ROUTES.DASHBOARD_USERS && navigate(ROUTES.DASHBOARD_USERS);
                  pathname !== ROUTES.DASHBOARD_USERS && setIsMobileMenuOpen(false);
                }}>
                <SVG type={SVG_TYPE.USERS} />
                <span>Lista użytkowników</span>
              </a>
            </li>
          ) : null}
        </ul>
      </nav>

      <div className="mobile-dashboard-menu__footer">
        <p className="mobile-dashboard-menu__footer-button" onClick={() => handleLogout()}>
          <SVG type={SVG_TYPE.LOGOUT} />
          <span className="mobile-dashboard-menu__footer-label">Wyloguj</span>
        </p>
        <p
          className="mobile-dashboard-menu__footer-button"
          onClick={() => {
            setIsChangePasswordModalOpen(true);
            setIsMobileMenuOpen(false);
          }}>
          <SVG type={SVG_TYPE.LOCK} />
          <span className="mobile-dashboard-menu__footer-label">Zmień hasło</span>
        </p>
      </div>
    </div>
  );
}

export default MobileDashboardMenu;
