import { isMobile } from 'Assets/isMobile';
import { orderStatus } from 'Assets/orderStatus';
import Button from 'Components/Button';
import Modal from 'Components/Modal';
import SVG from 'Components/SVG';
import { useEffect, useRef, useState } from 'react';
import { SingleFile } from 'Screens/OrderPage/components';
import { SVG_TYPE } from 'Shared/enums';

interface AddTranslatedFileModalProps {
  isOpen: boolean;
  uuid: string;
  onClose?: () => void;
  data: any;
  addTranslatedFile: ({ files, orderUuid }) => any;
  handleSubmit: () => void;
  deleteFile: ({ uuid }) => any;
  setFieldValue: (name: string, value: any) => void;
  initialOrder: any;
  getOrder: (uuid: string) => any;
}

function AddTranslatedFileModal({
  isOpen,
  onClose,
  addTranslatedFile,
  handleSubmit,
  uuid,
  deleteFile,
  setFieldValue,
  data,
  initialOrder,
  getOrder,
}: AddTranslatedFileModalProps) {
  const [files, setFiles] = useState<any | null>([]);
  const [dragging, setDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (initialOrder.uuid === uuid) {
      setFiles(initialOrder.translatedFiles);
    }
  }, [initialOrder]);

  const onFileDrop = async (fileArr: FileList) => {
    const newFiles = Array.from(fileArr);
    const tempArray = [...files];
    setShowError(false);
    setFiles([...tempArray, ...newFiles]);
    newFiles.forEach(async (item) => {
      const formData = new FormData();
      formData.append('files', item);

      const result = await addTranslatedFile({ files: formData, orderUuid: uuid });
      if (result) {
        newFiles.forEach((item) => {
          Object.defineProperty(item, 'uuid', {
            value: result[0].uuid,
            enumerable: true,
          });
          tempArray.push(item);
          setFiles(tempArray);
          getOrder(uuid);
        });
      } else {
        setFiles(tempArray);
      }
    });
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragExit = () => {
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    onFileDrop(e.dataTransfer.files);
  };

  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFileDrop(e.target.files);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleRemoveFile = async (fileUuid) => {
    const tempArr = [...files];
    const result = await deleteFile({ uuid: fileUuid });
    if (result) {
      setFiles(tempArr.filter((item) => item.uuid !== fileUuid));
      fileInputRef.current.value = '';
    }
    getOrder(uuid);
  };

  const onSubmit = async () => {
    if (files.length) {
      setFieldValue('openModal', false);
      await handleSubmit();
      onClose();
    } else {
      setShowError(true);
    }
  };

  const handleCancel = () => {
    setFieldValue(
      'orderStatus',
      orderStatus.filter((status) => status.value === data.status),
    );
    onClose();
  };

  return (
    <Modal isOpen={!!isOpen} onClose={onClose} className={'add-translated-file-modal'}>
      <h2 className="add-user-modal__header">Dodawanie gotowego tłumaczenia</h2>

      <div
        className={`add-translated-file-modal__wrapper ${dragging ? 'add-translated-file-modal__wrapper--dragging' : ''} ${
          showError ? 'add-translated-file-modal__wrapper--error' : ''
        }`}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDragExit}
        onDrop={handleDrop}>
        <SVG type={SVG_TYPE.SEND_FILE} />
        <p className="add-translated-file-modal__text">
          {isMobile() ? null : 'Przeciągnij i upuść lub '}
          <span className="add-translated-file-modal__text--download" onClick={() => handleButtonClick()}>
            pobierz pliki
          </span>
          <input
            type="file"
            ref={fileInputRef}
            className="drop-file-input__input"
            onChange={handleFileSelection}
            accept=".doc,.docx,.txt,.pdf,.txt"
          />
        </p>
      </div>
      <p className={`add-translated-file-modal__error ${showError ? 'add-translated-file-modal__error--show' : ''}`}>To pole jest wymagane </p>
      <div className="add-translated-file-modal__files">
        {files?.length
          ? files.map((item: any) => (
              <SingleFile
                currentStep={1}
                deleteFile={() => handleRemoveFile(item.uuid)}
                key={`${item.name} ${item.updatedAt}`}
                data={item}
                values={{ files }}
                customValues={true}
                setFieldValue={() => null}
              />
            ))
          : null}
      </div>

      <div className="add-translated-file-modal__buttons">
        <Button label="Anuluj" type="button" onClick={() => handleCancel()} cancel round stroke />
        <Button label="Wyślij" onClick={() => onSubmit()} round />
      </div>
    </Modal>
  );
}

export default AddTranslatedFileModal;
