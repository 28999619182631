import { Order } from '../EnumTypes/ActionEnumTypes';

const clearStore = () => ({
  type: Order.CLEAR_INVOICE,
});

const clearInvoice = () => async (dispatch) => {
  dispatch(clearStore());
};

export default clearInvoice;
