interface StepProps {
  steps: number;
  currentStep: number;
  className?: string;
}

function Step({ steps, currentStep, className = '' }: StepProps) {

  return (
    <div className={`step ${className}`}>
      {[...Array(steps)].map((e, i) => <span key={i} className={`step__item ${i <= currentStep-1 ? 'step__item--current' : ''}`} />)}
    </div>
  );
}

export default Step;
