import Button from 'Components/Button';
import { Input } from 'Components/form';
import Modal from 'Components/Modal';
import { Field, Formik } from 'formik';
import { useEffect, useRef } from 'react';
import { VALIDATION_MESSAGES } from 'Shared/enums';
import * as yup from 'yup';

interface ChangePasswordModalProps {
  isOpen: boolean;
  onClose?: () => void;
  changePassword: (currentPassword: string, newPassword: string) => any;
}

function ChangePasswordModal({ isOpen, onClose, changePassword }: ChangePasswordModalProps) {
  const formRef = useRef(null);
  const validationSchema = yup.object().shape({
    currentPassword: yup.string().required(VALIDATION_MESSAGES.REQUIRED).min(3, VALIDATION_MESSAGES.MIN_3).max(16, VALIDATION_MESSAGES.TOO_LONG),
    newPassword: yup.string().min(8, VALIDATION_MESSAGES.PASSWORD_LENGTH).required(VALIDATION_MESSAGES.REQUIRED),
    passwordConfirmation: yup
      .string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .oneOf([yup.ref('newPassword'), null], VALIDATION_MESSAGES.PASSWORD_MATCH),
  });

  useEffect(() => {
    isOpen && formRef.current.resetForm();
  }, [isOpen]);

  const handleOnSubmit = async (values: any) => {
    const result = await changePassword(values.currentPassword, values.newPassword);
    if (result == true) {
      onClose();
      formRef.current.resetForm();
    }
  };

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    passwordConfirmation: '',
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="add-user-modal__header"> Zmiana hasła</h2>
      <div className="add-user-modal__wrapper change-password-modal">
        <Formik
          validationSchema={validationSchema}
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values) => handleOnSubmit(values)}>
          {({ handleSubmit, errors }) => (
            <form onSubmit={handleSubmit} className="add-user-modal__form change-password-modal__form">
              <div className="change-password-modal__row">
                <Field
                  className={''}
                  label="Aktualne hasło"
                  name="currentPassword"
                  placeholder="Wpisz aktualne hasło"
                  required
                  component={Input}
                  treshold={0}
                  errors={errors}
                  maxLength={24}
                  variant="password"
                />
              </div>

              <div className="change-password-modal__row">
                <Field
                  className={''}
                  label="Nowe hasło"
                  name="newPassword"
                  placeholder="Wpisz nowe hasło"
                  required
                  component={Input}
                  treshold={0}
                  errors={errors}
                  maxLength={24}
                  variant="password"
                />
              </div>

              <div className="change-password-modal__row">
                <Field
                  className={''}
                  label="Potwierdź hasło"
                  name="passwordConfirmation"
                  placeholder="Powtórz nowe hasło"
                  required
                  component={Input}
                  treshold={0}
                  errors={errors}
                  variant="password"
                  maxLength={24}
                />
              </div>
              <div className="add-user-modal__form-row">
                <Button
                  label="Anuluj"
                  className="add-user-modal__add-button change-password-modal__add-button"
                  type="button"
                  onClick={() => onClose()}
                  round
                  stroke
                />
                <Button
                  label="Zapisz"
                  className="add-user-modal__add-button change-password-modal__add-button"
                  type="submit"
                  onClick={() => null}
                  round
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default ChangePasswordModal;
