import Button from 'Components/Button';
import MobileMenu from 'Components/MobileMenu';
import SVG from 'Components/SVG';
import { useEffect, useState } from 'react';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { router } from 'Shared/utils';
import { MainType } from './Container';
import CookieBanner from 'Components/CookieBanner/CookieBanner';
import CookieModal from 'Components/CookieModal/CookieModal';

function Navigation(props: MainType) {
  const { isNavigationHidden, actions, authorizedUser, isCookieModalOpen } = props;
  const [cookies, setCookies] = useState(JSON.parse(localStorage.getItem('cookieAgreement')));
  const { getAuthorizedUser, logout, setIsCookieModalOpen } = actions;
  const [hidden, setHidden] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const initialCookies = { necessary: true, performance: false, marketing: false, functional: false };
  useEffect(() => {
    setHidden(isNavigationHidden);
  }, [isNavigationHidden]);

  useEffect(() => {
    getAuthorizedUser();
  }, []);

  useEffect(() => {
    if (authorizedUser?.suspended) {
      logout();
    }
  }, [authorizedUser]);

  const onAcceptModal = () => {
    setIsCookieModalOpen(false);
    if (!cookies) {
      setCookies(initialCookies);
    }
  };

  const onAccept = () => {
    setCookies({ necessary: true, performance: true, marketing: true, functional: true });
  };
  const onAcceptAllModal = () => {
    setCookies({ necessary: true, performance: true, marketing: true, functional: true });
    setIsCookieModalOpen(false);
  };

  useEffect(() => {
    if (cookies) {
      localStorage.setItem('cookieAgreement', JSON.stringify(cookies));
    }
  }, [cookies]);

  useEffect(() => {
    let timerId;
    if (authorizedUser) {
      timerId = setInterval(() => {
        getAuthorizedUser();
      }, 10000);
    }
    return () => clearInterval(timerId);
  }, [authorizedUser]);

  return (
    <>
      {hidden ? null : <span className="navigation__spacer" />}
      <MobileMenu setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
      <nav className={`navigation ${hidden ? 'navigation--hidden' : ''}`}>
        <div className="navigation__wrapper">
          <div onClick={() => router.navigate(ROUTES.HOME_PAGE)}>
            <SVG type={SVG_TYPE.LOGO} className="navigation__logo" />
          </div>
          <Button className="navigation__login" stroke label="Zaloguj się" onClick={() => router.navigate(ROUTES.LOGIN_PAGE)} />
          <span className="navigation__mobile-menu" onClick={() => setIsMenuOpen(true)}>
            <SVG type={SVG_TYPE.MOBILE_MENU} />
          </span>
        </div>
      </nav>

      <CookieBanner showBanner={cookies == null && !isCookieModalOpen} onAccept={onAccept} settingsClick={() => setIsCookieModalOpen(true)} />
      <CookieModal
        isOpen={isCookieModalOpen}
        onAcceptAllModal={onAcceptAllModal}
        onAcceptModal={onAcceptModal}
        cookies={cookies || initialCookies}
        setCookies={setCookies}
      />
    </>
  );
}

export default Navigation;
