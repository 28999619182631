enum SVG_TYPE {
  ARROW_RIGHT = 'ARROW_RIGHT',
  CIRCLE = 'CIRCLE',
  CHECKED = 'CHECKED',
  CHECKED_GOLD = 'CHECKED_GOLD',
  SEND_FILE = 'SEND_FILE',
  BACK_CHEVRON = 'BACK_CHEVRON',
  CLOSE = 'CLOSE',
  SEARCH = 'SEARCH',
  PERSON = 'PERSON',
  AWARD = 'AWARD',
  USER_CHECK = 'USER_CHECK',
  RECOMMENDATION = 'RECOMMENDATION',
  LOGO = 'LOGO',
  MOBILE_MENU = 'MOBILE_MENU',
  PHONE = 'PHONE',
  LOGIN_BACKGROUND = 'LOGIN_BACKGROUND',
  WARNING = 'WARNING',
  TRANSACTION_FAILED = 'TRANSACTION_FAILED',
  TRANSACTION_FAILED_MOBILE = 'TRANSACTION_FAILED_MOBILE',
  TRANSACTION_SUCCESS = 'TRANSACTION_SUCCESS',
  TRANSACTION_SUCCESS_MOBILE = 'TRANSACTION_SUCCESS_MOBILE',
  TIE = 'TIE',
  LIST = 'LIST',
  USERS = 'USERS',
  COMMENTS = 'COMMENTS',
  UNKNOW_BADGE = 'UNKNOW_BADGE',
  CIRCLE_CHECKED = 'CIRCLE_CHECKED',
  CIRCLE_X = 'CIRCLE_X',
  SYNC = 'SYNC',
  FILE_CHECKED = 'FILE_CHECKED',
  PRICE_TAG = 'PRICE_TAG',
  HOMEPAGE_BACKGROUND = 'HOMEPAGE_BACKGROUND',
  HOMEPAGE_IMAGE = 'HOMEPAGE_IMAGE',
  WORKING = 'WORKING',
  DOTS = 'DOTS',
  RESTORE_PASSWORD = 'RESTORE_PASSWORD',
  DOWNLOAD = 'DOWNLOAD',
  LOGOUT = 'LOGOUT',
  LOCK = 'LOCK',
  HAMBURGER = 'HAMBURGER',
  FILTER = 'FILTER',
  PROCESS = 'PROCESS',
  BREIFCASE = 'BREIFCASE',
  CLOCK = 'CLOCK',
  ARCHIVED = 'ARCHIVED',
  PAGE404 = 'PAGE404',
  STRIPE = 'STRIPE',
  EYE = 'EYE',
  EYE_OFF = 'EYE_OFF',
  INFO_CIRCLE = 'INFO_CIRCLE',
  CERTIFIED_QUALITY = 'CERTIFIED_QUALITY',
  CLOSE_SELECT = 'CLOSE_SELECT',
  CHECKBOX_CHECKED = 'CHECKBOX_CHECKED',

  //flags
  FLAG_PL = 'FLAG_PL',
  FLAG_EN = 'FLAG_EN',
  FLAG_DE = 'FLAG_DE',
  FLAG_FR = 'FLAG_FR',
  FLAG_UA = 'FLAG_UA',
  FLAG_ES = 'FLAG_ES',
  FLAG_IT = 'FLAG_IT',

  //specializations
  ARCHITECTURE = 'ARCHITECTURE',
  ECONOMIC = 'ECONOMIC',
  IT = 'IT',
  MARKETING = 'MARKETING',
  MED = 'MED',
  LAW = 'LAW',
  TECHNICAL = 'TECHNICAL',

  //files
  PDF = 'PDF',
  DOC = 'DOC',
  DOCX = 'DOCX',
  JPG = 'JPG',
  PNG = 'PNG',
  TXT = 'TXT',
  CSV = 'CSV',
  XLS = 'XLS',
  SVG = 'SVG',
  JPEG = 'JPEG',

  //social
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  LINKEDIN = 'LINKEDIN',
  YOUTUBE = 'YOUTUBE',
}

export default SVG_TYPE;
