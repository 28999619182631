import * as AuthModule from 'Modules/AuthModule';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Auth.RESET_PASSWORD_INIT,
});

export const success = (authData) => ({
  type: Auth.RESET_PASSWORD_SUCCESS,
  payload: {
    authData,
  },
});

const failure = () => ({
  type: Auth.RESET_PASSWORD_FAILURE,
});

const resetPassword = (token: string, newPassword: string) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await AuthModule.Connector.resetPassword(token, newPassword);
    dispatch(success(response.data));
    return true;
  } catch (err:any) {
    dispatch(failure());
    return err.response.data;
  }
};

export default resetPassword;
