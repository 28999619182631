import * as LanguageModule from '../../../Modules/LanguagesModule';
import { Languages } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Languages.DELETE_LANGUAGE_INIT,
});

const success = (data: any) => ({
  type: Languages.DELETE_LANGUAGE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Languages.DELETE_LANGUAGE_FAILURE,
});

const deleteLanguage = (uuid) => async (dispatch) => {
  dispatch(init());
  try {
    const data = await LanguageModule.Connector.deleteLanguage(uuid);
    dispatch(success(data));
    toast.success('Pomyślnie usunięto język', { icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} /> });
    return true;
  } catch (err: any) {
    dispatch(failure());
    toast.error('Nie udało się usunąć języka, pozbądź się tłumaczeń kożystających z tego języka', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
    return false;
  }
};

export default deleteLanguage;
