import { Languages } from '../Actions/EnumTypes/ActionEnumTypes';

export type State = {
  languages: Array<any>;
  translations: Array<any>;
  translationsList: Array<any>;
  languagesList: Array<any>;
};

export const initialState: State = {
  languages: [],
  languagesList: [],
  translations: [],
  translationsList: [],
};

export function languagesReducer(state: State = initialState, action) {
  switch (action.type) {
    case Languages.GET_LANGUAGES_INIT: {
      return {
        ...state,
      };
    }
    case Languages.GET_LANGUAGES_SUCCESS: {
      return {
        ...state,
        languages: action.payload.data.data.languages,
      };
    }
    case Languages.GET_LANGUAGES_FAILURE: {
      return {
        ...state,
      };
    }

    case Languages.GET_TRANSLATIONS_INIT: {
      return {
        ...state,
      };
    }
    case Languages.GET_TRANSLATION_SUCCESS: {
      return {
        ...state,
        translations: action.payload.data.data.translationsLabel,
      };
    }
    case Languages.GET_TRANSLATION_FAILURE: {
      return {
        ...state,
      };
    }

    case Languages.GET_TRANSLATIONS_LIST_INIT: {
      return {
        ...state,
      };
    }
    case Languages.GET_TRANSLATIONS_LIST_SUCCESS: {
      return {
        ...state,
        translationsList: action.payload.data.data.translations,
      };
    }
    case Languages.GET_TRANSLATIONS_LIST_FAILURE: {
      return {
        ...state,
      };
    }
    case Languages.GET_LANGUAGES_LIST_INIT: {
      return {
        ...state,
      };
    }
    case Languages.GET_LANGUAGES_LIST_SUCCESS: {
      return {
        ...state,
        languagesList: action.payload.data.data.languages,
      };
    }
    case Languages.GET_LANGUAGES_LIST_FAILURE: {
      return {
        ...state,
      };
    }
    case Languages.ADD_TRANSLATION_INIT: {
      return {
        ...state,
      };
    }
    case Languages.ADD_TRANSLATION_SUCCESS: {
      return {
        ...state,
      };
    }
    case Languages.ADD_TRANSLATION_FAILURE: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}
