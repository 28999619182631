import React, { useEffect, useState } from 'react';
import { FieldProps } from 'formik';
import { SVG_TYPE } from 'Shared/enums';
import SVG from 'Components/SVG';
import { toast } from 'react-toastify';

interface Props extends FieldProps {
  onFileDrop: (files: FileList) => void;
  className?: string;
  label?: string;
  accept?: string;
  errors: Array<{
    [key: string]: string;
  }>;
}

const DropFileInput: React.FC<Props> = ({ field, form, onFileDrop, errors, className = '', label, accept }) => {
  const [file, setFile] = useState<any | null>(null);
  const [dragging, setDragging] = useState(false);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const fileExtension = file && (file.name?.split('.')?.pop()?.toLocaleUpperCase() || file.extname);

  const handleDrag = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragExit = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const currentFileExtension = e.dataTransfer.files[0].name.split('.').pop()?.toLocaleUpperCase();
    if (accept && !accept.toUpperCase().replaceAll('.', '').split(',').includes(currentFileExtension)) {
      toast.error(`Nie obsługiwane rozszerzenie pliku, obsługiwane formaty to: ${accept} `, {
        icon: <SVG variant={SVG_TYPE.CIRCLE_X} />,
        toastId: 'fileExtension',
        autoClose: 6000,
      });
      return;
    }
    onFileDrop(e.dataTransfer.files);

    form.setFieldValue(field.name, e.dataTransfer.files);
  };

  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    onFileDrop(e.target.files!);
    form.setFieldValue(field.name, e.target.files);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleRemoveFile = () => {
    setFile(null);
    fileInputRef.current.value = '';
    form.setFieldValue(field.name, null);
  };

  useEffect(() => {
    document.body.addEventListener('dragover', handleDrag);
    document.body.addEventListener('dragleave', handleDragExit);
    document.body.addEventListener('dragenter', handleDrag);
    document.body.addEventListener('drop', handleDrop);
    return () => {
      document.body.removeEventListener('dragover', handleDrag);
      document.body.removeEventListener('dragleave', handleDragExit);
      document.body.removeEventListener('dragenter', handleDrag);
      document.body.removeEventListener('drop', handleDrop);
    };
  }, []);

  useEffect(() => {
    setFile(!!field?.value?.length ? field.value[0] : field.value);
  }, [field.value]);

  return (
    <>
      <span className={`drop-file-input__outside ${dragging ? 'drop-file-input__outside--show' : ''}`} />
      <div
        className={`drop-file-input__wrapper ${className} ${
          form.touched[field.name] && errors[field.name] ? 'drop-file-input__wrapper--error' : ''
        }`}>
        {label ? (
          <label className="input__label" htmlFor={field.name}>
            {label}
          </label>
        ) : null}
        <div
          className={`drop-file-input ${dragging ? 'drop-file-input--dragging' : ''}`}
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDragExit}
          onDrop={handleDrop}>
          <div className="drop-file-input__content">
            {file ? (
              <div className="summary__file" key={`${file.name} ${file.lastModified}`}>
                <span>
                  <SVG type={fileExtension} />
                </span>
                {file.name}
                <span onClick={() => handleRemoveFile()} className="drop-file-input__remove">
                  <SVG type={SVG_TYPE.CLOSE} />
                </span>
              </div>
            ) : (
              <>
                Przeciągnij i upuść lub
                <button type="button" className="drop-file-input__button" onClick={handleButtonClick}>
                  Wybierz
                </button>
              </>
            )}
            <input type="file" ref={fileInputRef} className="drop-file-input__input" onChange={handleFileSelection} accept={accept} />
          </div>
        </div>
        {errors && form.touched && <div className="input__error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>}
      </div>
    </>
  );
};

export default DropFileInput;
