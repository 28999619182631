import { User } from '../EnumTypes/ActionEnumTypes';
import * as UserModule from '../../../Modules/UserModule';
import { SVG_TYPE } from 'Shared/enums';
import SVG from 'Components/SVG';
import { toast } from 'react-toastify';

const init = () => ({
  type: User.ADD_USER_INIT,
});

const success = () => ({
  type: User.ADD_USER_SUCCESS,
  payload: {
  },
});

const failure = () => ({
  type: User.ADD_USER_FAILURE,
});

const addUser = (userData) => async (dispatch) => {
  dispatch(init());
  try {
     await UserModule.Connector.addUser(userData);
    dispatch(success());
    toast.success('Pomyślnie dodano użytkownika', { icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} /> });
    return true;
  } catch (err: any) {
    dispatch(failure());
    toast.error('Nie udało się dodać użytkownika', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
    return false;
  }
};

export default addUser;
