interface SwitchWithoutTextProps {
  checked: boolean;
  className?: string;
  onClick?: (checked: boolean) => void;
}

const Switch = ({ checked, className = '', onClick }: SwitchWithoutTextProps) => {
  const handleChange = () => {
    onClick(!checked);
  };
  return (
    <label className={`switch ${className}`}>
      <input type="checkbox" className="checkbox" checked={checked} onClick={() => handleChange()} />
      <span className="switch__slider switch__slider--round" />
    </label>
  );
};

export default Switch;
