import Button from 'Components/Button';
import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';
import { MainType } from './Container';

function TransactionFailed(props: MainType) {
  const { actions } = props;
  const { createPayment } = actions;
  
  const query = new URLSearchParams(window.location.search);
  const orderUuid = query.get('orderUuid');

  const handlePayment = () => {
    createPayment(orderUuid)
  }
  return (
    <div className="page transaction-failed">
      <div className="transaction-failed__wrapper">
        <h1 className='transaction-failed__header'>
          Błąd transakcji <SVG type={SVG_TYPE.WARNING} />
        </h1>
        <p className='transaction-failed__message'>
          Nie udało się zrealizować płatności. <br />
          Spróbuj ponownie.
        </p>
        <Button label="Ponów płatność" round onClick={() => handlePayment()} />
          </div>
          <SVG type={SVG_TYPE.TRANSACTION_FAILED} className='transaction-failed__svg transaction-failed__svg--desktop' />
          <SVG type={SVG_TYPE.TRANSACTION_FAILED_MOBILE} className='transaction-failed__svg transaction-failed__svg--mobile' />
    </div>
  );
}

export default TransactionFailed;
