import { connect } from 'react-redux';
import TransactionSuccess from './Main';
import { Actions as OrderActions } from 'Modules/OrderModule';
import { initialOrderSelector } from 'Store/Selectors/OrderSelector';
import { bindActionCreators } from 'redux';

type ConnectedP = {
  initialOrder: any;
};

type DispatchedP = {
  actions: {
    getOrder: (uuid) => void;
    clearOrders: () => void;
  };
};
const mapStateToProps = (state) => ({
  initialOrder: initialOrderSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getOrder: OrderActions.getOrder,
      clearOrders: OrderActions.clearOrders,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(TransactionSuccess);
