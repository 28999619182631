import SmallLoader from 'Components/SmallLoader';
import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';

interface SingleFileProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  values: any;
  data: any;
  deleteFile?: (any) => void;
  currentStep: number;
  isDownload?: boolean;
  customValues?: any;
  variant?: string;
}

function SingleFile({ setFieldValue, values, data, deleteFile, currentStep, isDownload = false, customValues, variant }: SingleFileProps) {
  const handleRemoveFile = (uuid) => {
    const newFileList = values.files.filter((file) => file.uuid !== uuid);
    deleteFile({ uuid: data.uuid });
    !customValues && setFieldValue('files', newFileList);
  };

  const handleDownloadFile = async (file) => {
    const link = document.createElement('a');
    link.href = file.url || file.invoice_pdf;
    link.download = 'myFile.pdf';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fileExtension = variant === 'invoice' ? 'PDF' : data.name.split('.').pop().toLocaleUpperCase();
  return (
    <div className="summary__file" key={`${data.name} ${data.lastModified}`}>
      <span>
        <SVG type={fileExtension} />
      </span>
      {data.name}
      {data.uuid || variant === 'invoice' ? (
        <>
          <span className={`${currentStep !== 1 ? 'summary__file--close-hidden' : ''}`} onClick={() => handleRemoveFile(data.uuid)}>
            <SVG type={SVG_TYPE.CLOSE} />
          </span>
          {isDownload ? (
            <span className="summary__file--download" onClick={() => handleDownloadFile(data)}>
              <SVG type={SVG_TYPE.DOWNLOAD} />
            </span>
          ) : null}
        </>
      ) : (
        <SmallLoader />
      )}
    </div>
  );
}

export default SingleFile;
