import API from './Config';
import { AxiosPromise } from 'axios';

export function getUsers(): AxiosPromise<any> {
  return API.get('users');
}

export function deleteUser(uuid: string): AxiosPromise<any> {
  return API.delete(`users/${uuid}`);
}

export function addUser(userData): AxiosPromise<any> {
  return API.post('users', { ...userData });
}

export function editUser(uuid: string, userData: any): AxiosPromise<any> {
  return API.put(`users/${uuid}`, userData);
}

export function getUsersLabel(): AxiosPromise<any> {
  return API.get(`users/label`);
}

export function changeSuspendedStatus(uuid: string, ): AxiosPromise<any> {
  return API.put(`users/suspended/${uuid}`);
}

export function resendLink(email : string, ): AxiosPromise<any> {
  return API.post(`users/resend-link`,{email});
}
