import { connect } from 'react-redux';
import HomePage from './Main';

type ConnectedP = {
  changelogs: Array<any>;
};

type DispatchedP = {
  actions: {
    getChangelogs: () => void;
  };
};
const mapStateToProps = (state) => ({
  changelogs: state.changelogs,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getChangelogs: () => dispatch(()=>console.log('getChangelogs')),
  },
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
