
interface BackProps {
  className?: string;
}

function SmallLoader({ className = '' }: BackProps) {

  return (
    <div className={`small-loader ${className}`}>
      <span className="small-loader__dot"></span>
      <span className="small-loader__dot"></span>
      <span className="small-loader__dot"></span>
    </div>
  );
}

export default SmallLoader;
