import { Order } from '../EnumTypes/ActionEnumTypes';
import * as OrderModule from '../../../Modules/OrderModule';

const init = () => ({
  type: Order.GET_INVOICE_INIT,
});

const success = (data: any) => ({
  type: Order.GET_INVOICE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Order.GET_INVOICE_FAILURE,
});

const getInvoice = (orderUuid: string) => async (dispatch) => {
  dispatch(init());
  try {
    const data: any = await OrderModule.Connector.getInvoice(orderUuid);
    const invoiceArray = data.data;
    dispatch(success(invoiceArray));
    return data;
  } catch (err: any) {
    dispatch(failure());
  }
};

export default getInvoice;
