import { ViewManagementStore } from 'Shared/models/ViewManagementModel';
import { ViewManagement } from '../Actions/EnumTypes/ActionEnumTypes';
// import { ViewsManagemenetAction } from '../Actions/Models/ViewManagementActionModel';

export type State = ViewManagementStore;

export const initialState: State = {
  loaderVisible: false,
  isNaviagtionHidden: false,
  isCookieModalOpen: false,
};

export function viewManagementReducer(state: State = initialState, action) {
  switch (action.type) {
    case ViewManagement.SHOW_LOADER: {
      return {
        ...state,
        loaderVisible: true,
      };
    }
    case ViewManagement.HIDE_LOADER: {
      return {
        ...state,
        loaderVisible: false,
      };
    }
    case ViewManagement.SET_IS_NAVIGATION_HIDDEN: {
      return {
        ...state,
        isNaviagtionHidden: action.payload.boolean,
      };
    }
    case ViewManagement.SET_IS_COOKIE_MODAL_OPEN: {
      return {
        ...state,
        isCookieModalOpen: action.payload.boolean,
      };
    }
    default: {
      return state;
    }
  }
}
