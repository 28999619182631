import API from './Config';
import { AxiosPromise } from 'axios';

export function getLanguages(): AxiosPromise<any> {
  return API.get('languages/label');
}

export function getLanguagesList(search): AxiosPromise<any> {
  return API.get('languages/label', { params: { search } });
}

export function getTranslations(): AxiosPromise<any> {
  return API.get('translations/label');
}

export function getTranslationsList(page, filters): AxiosPromise<any> {
  return API.get('translations', { params: { page, ...filters } });
}

export function addTranslation(data): AxiosPromise<any> {
  return API.post('translations', { ...data });
}

export function editTranslation(uuid,data): AxiosPromise<any> {
  return API.put(`translations/${uuid}`, { ...data });
}

export function addLanguage(data): AxiosPromise<any> {
  return API.post('languages', { ...data });
}
export function editLanguage(uuid,data): AxiosPromise<any> {
  return API.put(`languages/${uuid}`, { ...data });
}
export function deleteLanguage(uuid): AxiosPromise<any> {
  return API.delete(`languages/${uuid}`);
}