import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

const getViewMAnagementStore = (state: RootState) => state.viewManagementStore;
const getLocationStore = (state: RootState) => state.router;
const loaderVisible = createSelector(getViewMAnagementStore, (state) => state.loaderVisible);
const isNaviagtionHidden = createSelector(getViewMAnagementStore, (state) => state.isNaviagtionHidden);
const isCookieModalOpen = createSelector(getViewMAnagementStore, (state) => state.isCookieModalOpen);
const location = createSelector(getLocationStore, (state) => state.location);

export { loaderVisible, isNaviagtionHidden, location, isCookieModalOpen };
