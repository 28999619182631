import SVG from 'Components/SVG';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { MainType } from './Container';
import Button from 'Components/Button';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';

function HomePage(props: MainType) {
  const {} = props;
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('lastPage');
  }, []);

  return (
    <div className="page homepage">
      <SVG type={SVG_TYPE.HOMEPAGE_IMAGE} className="homepage__svg" />
      <div className="homepage__wrapper">
        <h1 className="homepage__header">Uzyskaj wycenę swojego tłumaczenia w&nbsp;kilka sekund!</h1>
        <p className="homepage__subtitle">
          Wystarczy, że prześlesz plik do przetłumaczenia i&nbsp;wskażesz język, <br className="homepage__desktop-br" /> a&nbsp;my od razu podamy Ci
          koszt oraz termin realizacji.
        </p>
        <Button label="Wyceń tłumaczenie" className="homepage__button" onClick={() => navigate(ROUTES.ORDER_PAGE)} round />
      </div>
      <SVG type={SVG_TYPE.HOMEPAGE_BACKGROUND} className="homepage__background" />
    </div>
  );
}

export default HomePage;
