import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar/dist/Calendar.css';
import 'Assets/Sass/style.scss';
import { RouterProvider } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { router } from 'Shared/utils';
import { Provider } from 'react-redux';
import { store } from 'Store/Store';
import Navigation from 'Components/Navigation/Container';
import Footer from 'Components/Footer';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Navigation />
      <RouterProvider router={router} />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Footer />
    </Provider>
  </React.StrictMode>,
);
