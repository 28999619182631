import Button from 'Components/Button';
import SmallLoader from 'Components/SmallLoader';
import SVG from 'Components/SVG';
import { Dispatch, SetStateAction } from 'react';
import ContentLoader from 'react-content-loader';
import { SVG_TYPE } from 'Shared/enums';
import { extensionsWithAutomaticPricing } from 'Shared/utils';
import SingleFile from '../SingleFile/SingleFile';
import Checkbox from 'Components/form/Checkbox';
import { Field } from 'formik';

interface SummaryProps {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  isValid: boolean;
  values: any;
  initialOrder: any;
  handleAddFIle: (event: any) => void;
  step: number;
  handleSubmit: (event?: React.FormEvent<HTMLFormElement> | undefined) => void;
  deleteFile: (any) => void;
  confirmOrder: (dataForm, uuid) => void;
  currentOrderUuid: string;
  currentOrder: any;
  currentStep: number;
  secondSlide: boolean;
  correctFiles: boolean;
  loaderVisible: boolean;
  validateForm: () => Promise<any>;
  setTouched: (touched: any, shouldValidate?: boolean | undefined) => void;
  setDragging: (dragging: boolean) => void;
  dragging: boolean;
  errors: any;
  isPending: boolean;
}

function Summary({
  setFieldValue,
  isValid,
  values,
  handleAddFIle,
  deleteFile,
  currentOrder,
  setCurrentStep,
  currentStep,
  secondSlide,
  handleSubmit,
  correctFiles,
  loaderVisible,
  validateForm,
  setTouched,
  setDragging,
  dragging,
  errors,
  initialOrder,
  isPending,
}: SummaryProps) {
  const fileInput = document.getElementById('file-input');
  const maxSteps = correctFiles ? 3 : 2;
  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragExit = () => {
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    handleAddFIle(e);
  };

  const handleFocusOnInput = () => {
    if (Object.keys(errors).length > 0) {
      const node = document.querySelector(`#${Object.keys(errors)[0]}`);
      if (node) {
        node.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const handleError = (response) => {
    setTouched(Object.keys(response).reduce((acc, key) => ({ ...acc, [key]: true }), {}));
    handleFocusOnInput();
    //if error on acceptance scroll container-right__placeholder to bottom
    if (Object.keys(response).includes('acceptance')) {
      const node = document.querySelector('.summary__wrapper ');
      if (node) {
        node.scroll({ top: node.scrollHeight, behavior: 'smooth' });
      }
    }
  };

  const correctFilesList = currentOrder?.files
    ? currentOrder?.files?.filter((file: any) => !!extensionsWithAutomaticPricing.exec(file.name) && file.fileData.sign_count > 0)
    : values.files.filter((file) => !!extensionsWithAutomaticPricing.exec(file.name));

  const incorrectFilesList = currentOrder?.files
    ? currentOrder?.files.filter((file) => (!extensionsWithAutomaticPricing.exec(file.name) || file.fileData ? file.fileData.sign_count == 0 : false))
    : values.files.filter((file) => !extensionsWithAutomaticPricing.exec(file.name));

  return (
    <div
      className={`container-right__slide test ${secondSlide ? 'container-right__slide--second' : ''} ${
        dragging ? 'container-right__slide--dragging' : ''
      }`}
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleDragExit}
      onDrop={handleDrop}>
      {loaderVisible ? (
        <ContentLoader
          speed={2}
          width={289}
          height={586}
          viewBox="0 0 289 586"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          className="container-right__loader"
          preserveAspectRatio="xMinYMin slice">
          <rect x="0" y="0" rx="6" ry="6" width="139" height="29" />
          <rect x="0" y="52" rx="6" ry="6" width="78" height="17" />

          <rect x="0" y="89" rx="6" ry="6" width="17" height="13" />
          <rect x="39" y="89" rx="6" ry="6" width="26" height="13" />
          <rect x="113" y="89" rx="6" ry="6" width="17" height="13" />
          <rect x="139" y="89" rx="6" ry="6" width="38" height="13" />

          <rect x="0" y="132" rx="6" ry="6" width="79" height="17" />
          <rect x="268" y="132" rx="6" ry="6" width="21" height="14" />

          <rect x="0" y="170" rx="6" ry="6" width="69" height="17" />
          <rect x="194" y="170" rx="6" ry="6" width="13" height="13" />
          <rect x="217" y="170" rx="6" ry="6" width="58" height="13" />

          <rect x="0" y="210" rx="6" ry="6" width="79" height="17" />
          <rect x="248" y="209" rx="6" ry="6" width="41" height="14" />

          <rect x="0" y="246" rx="6" ry="6" width="89" height="17" />
          <rect x="235" y="246" rx="6" ry="6" width="55" height="14" />

          <rect x="0" y="303" rx="6" ry="6" width="55" height="19" />
          <rect x="250" y="303" rx="6" ry="6" width="40" height="17" />

          <rect x="0" y="362" rx="6" ry="6" width="96" height="19" />
          <rect x="0" y="362" rx="6" ry="6" width="47" height="17" />

          <rect x="0" y="395" rx="6" ry="6" width="290" height="54" />

          <rect x="0" y="485" rx="6" ry="6" width="290" height="16" />

          <rect x="0" y="556" rx="6" ry="6" width="290" height="30" />
        </ContentLoader>
      ) : (
        <>
          <h2 className="order-page__header order-page__header--right">Podsumowanie</h2>
          <div className=" summary__wrapper">
            <span className="summary">
              <div className={`summary__translations ${values?.translateTo?.length && values?.translateFrom ? '' : 'summary__item--hidden'}`}>
                <p className="summary__title">Para językowa:</p>
                <div className="summary__languages">
                  <span className="summary__badge">
                    <img
                      className="summary__flag"
                      src={values?.translateTo?.length ? values.translateFrom[0].files?.url : null}
                      alt={values?.translateTo?.length ? values.translateFrom[0].label : null}
                    />
                    {values?.translateTo?.length ? values.translateFrom[0].label : null}
                  </span>
                  <SVG type={SVG_TYPE.ARROW_RIGHT} />
                  <div className="summary__languages-item">
                    {values?.translateTo.length
                      ? values?.translateTo?.map((item: any) => (
                          <div key={item.value}>
                            <span className="summary__badge">
                              <img className="summary__flag" src={item.files?.url} alt={item?.label} />
                              {item?.label}
                            </span>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>

              <div className={`summary__item ${currentOrder.signCount || initialOrder.signCount ? '' : 'summary__item--hidden'}`}>
                <p className="summary__item-title">Liczba znaków:</p>
                <div className="summary__item-value">{currentOrder.signCount || initialOrder.signCount}</div>
              </div>

              <div className={`summary__item ${values?.specialization?.length ? '' : 'summary__item--hidden'}`}>
                <p className="summary__item-title">Specjalizacja:</p>
                <div className="summary__item-value summary__item-value--specialization">
                  {values?.specialization?.length ? values?.specialization[0].svgType && <SVG type={values?.specialization[0].svgType} /> : null}
                  {values?.specialization?.length ? values?.specialization[0]?.label : null}
                </div>
              </div>

              <div className={`summary__item ${values?.priorities ? '' : 'summary__item--hidden'}`}>
                <p className="summary__item-title">Czas realizacji:</p>
                <div className="summary__item-value">{values.priorities === 'standard' ? 'Standard' : 'Ekspres'}</div>
              </div>

              <div className={`summary__item ${values?.translationType ? '' : 'summary__item--hidden'}`}>
                <p className="summary__item-title">Typ tłumaczenia:</p>
                <div className="summary__item-value">{values.translationType === 'regular' ? 'Zwykłe' : 'Przysięgłe'}</div>
              </div>

              <div className={`summary__item ${values?.quality ? '' : 'summary__item--hidden'}`}>
                <p className="summary__item-title">Poziom usługi:</p>
                <div className="summary__item-value">
                  {values.quality === 'basic' ? 'Basic' : null}
                  {values.quality === 'standard' ? 'Comfort' : null}
                  {values.quality === 'expert' ? 'Expert' : null}
                </div>
              </div>

              {currentOrder.signCount ? (
                <div className={`summary__price`}>
                  <p>Cena od:</p>
                  <p className="summary__price--value"> {currentOrder.price ? `${currentOrder.price} zł` : <SmallLoader />}</p>
                </div>
              ) : initialOrder.price ? (
                <div className={`summary__price`}>
                  <p>Cena od:</p>
                  <p className="summary__price--value"> {initialOrder.price ? `${initialOrder.price} zł` : <SmallLoader />}</p>
                </div>
              ) : null}

              <div>
                <div className="summary__files-header">
                  <p>Załączone pliki:</p>
                  <p
                    className={`summary__files-header--add ${currentStep !== 1 ? 'summary__file--close-hidden' : ''}`}
                    onClick={() => fileInput.click()}>
                    + dodaj
                  </p>
                </div>
                <p className="order-page__text-below-button order-page__text-below-button--summary">
                  Dopuszczalne formaty plików to docx, doc, pdf. W przypadku załączenia innego formatu, nie będzie możliwa automatyczna wycena.
                  Skontaktujemy się wtedy za pomocą maila. Możesz dodać max. 10 plików.
                </p>
                <div className="summary__files-wrapper">
                  {correctFilesList.map((item: any) => (
                    <SingleFile
                      currentStep={currentStep}
                      deleteFile={deleteFile}
                      key={`${item.name} ${item.updatedAt}`}
                      data={item}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                  {incorrectFilesList.length ? (
                    <div className="summary__incorrect-format">
                      <h4 className="summary__incorrect-format-header">Osobna Wycena</h4>
                      <p className="summary__incorrect-format-description">
                        Brak możliwości zczytania ilości znaków. Pliki zostaną wycenione osobno.
                      </p>
                      {incorrectFilesList.map((item: File) => (
                        <SingleFile
                          currentStep={currentStep}
                          deleteFile={deleteFile}
                          key={item.name}
                          data={item}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>

              <input
                id="file-input"
                name="files"
                className="order-page__file-input"
                type="file"
                multiple
                accept=".doc,.docx,.txt,.pdf,.png,.txt,.jpeg,.jpg"
                onChange={(event) => {
                  handleAddFIle(event);
                }}
              />
            </span>
            {currentStep === 3 ? (
              <Field
                label={
                  <p>
                    Zapoznałem się i akceptuję warunki{' '}
                    <a href="https://highline.com.pl/regulamin.pdf" rel="noreferrer" target="_blank">
                      Regulaminu
                    </a>{' '}
                    i{' '}
                    <a href="https://highline.com.pl/polityka_prywatnosci.pdf" target="_blank" rel="noreferrer">
                      Polityki prywatności
                    </a>
                  </p>
                }
                name="acceptance"
                component={Checkbox}
                errors={errors}
              />
            ) : (
              ''
            )}
          </div>
          <div className="summary__buttons">
            <Button
              label={
                currentStep === 3
                  ? values.type === 'person'
                    ? currentOrder.isToPay
                      ? 'Przejdź do płatności'
                      : 'Złóż zamówienie'
                    : values.paymentMethod === 'instant'
                    ? 'Przejdź do płatności'
                    : 'Złóż zamówienie'
                  : 'Dalej'
              }
              className="summary__submit"
              onClick={async () => {
                const response = await validateForm();
                isValid || !Object.keys(response).length
                  ? (!values.priorities && setFieldValue('priorities', 'standard'),
                    !values.translationType && setFieldValue('translationType', 'regular'),
                    ((!correctFiles && currentStep === 2) || currentStep === 3) && handleSubmit(),
                    currentStep !== maxSteps && setCurrentStep(!correctFiles && currentStep === 2 ? 2 : currentStep + 1),
                    currentStep !== 3 && document.querySelector('html')?.scrollTo(0, 0))
                  : handleError(response);
              }}
            />
            {isPending && <SmallLoader className="summary__button-loader" />}
          </div>
        </>
      )}
    </div>
  );
}

export default Summary;
