import Button from 'Components/Button';
import Modal from 'Components/Modal';

interface DeleteLanguageModalProps {
  isOpen: boolean;
  onClose?: () => void;
  language: {
    label: string;
    value: string;
  };
  deleteLanguage: (languageUuid: string) => any;
  getLanguagesList: (filters: any) => any;
  selectedFilters: any;
}

function DeleteLanguageModal({ isOpen, onClose, language, deleteLanguage, getLanguagesList, selectedFilters }: DeleteLanguageModalProps) {
  const onClick = async () => {
    const result = await deleteLanguage(language.value);
    if (result) {
      getLanguagesList(selectedFilters.search);
    }
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="add-user-modal__header">Usuwanie języka</h2>

      <div className="add-user-modal__wrapper">
        <h3 className="single-order__modal-text">Czy jesteś pewien, że chcesz usunąć język {language?.label}?</h3>
        <div className="add-user-modal__form-row">
          <Button label="Anuluj" className="add-user-modal__add-button" type="button" cancel onClick={() => onClose()} round stroke />
          <Button label="Usuń" className="add-user-modal__add-button" type="button" onClick={() => onClick()} round />
        </div>
      </div>
    </Modal>
  );
}

export default DeleteLanguageModal;
