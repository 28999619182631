import Button from 'Components/Button';
import Modal from 'Components/Modal';
import SVG from 'Components/SVG';
import { Field } from 'formik';
import { useEffect } from 'react';
import { Select } from 'Components/form';
import { SVG_TYPE } from 'Shared/enums';

interface LanguagesModalProps {
  setIsModalOpen: (value: boolean) => void;
  isModalOpen: boolean;
  setPrevSelectedLanguages: (value: any) => void;
  values: any;
  errors: any;
  setFieldValue: (name: string, value: any) => void;
  translateToOptions: any;
  setCurrentText: (value: string) => void;
  currentText: string;
  languagesInModal: any;
  prevSelectedLanguages: any;
  setLanguagesInModal: (value: any) => void;
  wasModalOpen: boolean;
  setWasModalOpen: (value: boolean) => void;
  setFormValues: (value: any) => void;
}

function LanguagesModal({
  setIsModalOpen,
  isModalOpen,
  setPrevSelectedLanguages,
  values,
  errors,
  setFieldValue,
  translateToOptions,
  setCurrentText,
  currentText,
  languagesInModal,
  prevSelectedLanguages,
  setLanguagesInModal,
  setFormValues,
}: LanguagesModalProps) {
  useEffect(() => {
    const tempArray = [];
    if (Array.isArray(values.translateTo)) {
      values.translateTo.map((item) => {
        tempArray.push(item.value);
      });
      setLanguagesInModal(translateToOptions.filter((item) => tempArray.includes(item.value) === false));
    }
  }, [values.translateTo, translateToOptions]);

  useEffect(() => {
    setFormValues(values);
  }, [values]);

  useEffect(() => {
    const html = document.querySelector('html');
    if (isModalOpen) {
      html.style.overflow = 'hidden';
    } else {
      html.style.overflow = 'auto';
    }
  }, [isModalOpen]);

  return (
    <Modal
      className="language-form__modal"
      isOpen={isModalOpen}
      onOpen={() => setPrevSelectedLanguages(values?.translateTo)}
      onClose={() => setIsModalOpen(false)}>
      <>
        <div className="language-form__select">
          <Field
            // label="Przetłumacz na"
            name="translateTo"
            placeholder=""
            required
            component={Select}
            errors={errors}
            isMulti
            isSearchable
            options={translateToOptions}
            onInputChange={(value) => setCurrentText(value)}
          />
          <span className={`language-form__placeholder ${currentText ? 'language-form__placeholder--hidden' : ''}`}>
            <SVG type={SVG_TYPE.SEARCH} />
            Wybierz języki...
          </span>
        </div>
        <div className="language-form__selected-languages">
          {values?.translateTo?.length
            ? values?.translateTo?.map((item) => (
                <div key={item?.value} className="select__multi-value">
                  <>
                    {item?.files?.url && <img src={item.files?.url} className="select__icon select__icon--small" alt={item?.label} />}
                    <span>{item?.label}</span>
                    <div
                      className="language-form__close-wrapper"
                      onClick={() =>
                        setFieldValue(
                          'translateTo',
                          values.translateTo.filter((language) => language.value !== item?.value),
                        )
                      }>
                      <SVG type={SVG_TYPE.CLOSE} className={'language-form__close'} />
                    </div>
                  </>
                </div>
              ))
            : null}
        </div>
        <div className="language-form__most-popular">
          <p className="language-form__most-popular-header">Najpopularniejsze:</p>
          <div className="language-form__most-popular-languages">
            {languagesInModal.slice(0, 4).map((item) => (
              <div
                key={item.value}
                className="language-form__language"
                onClick={() =>
                  values.translateTo.findIndex((language) => language.value === item.value) === -1
                    ? values.translateTo.length < 20 && setFieldValue('translateTo', [...values.translateTo, item])
                    : null
                }>
                {item.files?.url && <img src={item.files?.url} className="select__icon select__icon--small" alt={item.label} />}
                <span>{item.label}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="language-form__all-languages">
          <p className="language-form__most-popular-header">Wszystkie języki:</p>
          <div className="language-form__all-languages-wrapper">
            {languagesInModal.map((item) => (
              <div
                key={item.value}
                className="language-form__all-languages-language"
                onClick={() =>
                  values.translateTo.findIndex((language) => language.value === item.value) === -1
                    ? values.translateTo.length < 20 && setFieldValue('translateTo', [...values.translateTo, item])
                    : null
                }>
                {item.files?.url && <img src={item.files?.url} className="select__icon select__icon--small" alt={item.label} />}
                <span>{item.label}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="language-form__buttons">
          <Button
            onClick={() => {
              setIsModalOpen(false);
              setFieldValue('translateTo', prevSelectedLanguages);
            }}
            label="Anuluj"
            cancel
            stroke
          />
          <Button onClick={() => setIsModalOpen(false)} label="Dodaj" />
        </div>
      </>
    </Modal>
  );
}

export default LanguagesModal;
