import { rolesOptions } from 'Assets/rolesOptions';
import Button from 'Components/Button';
import { Input, Select } from 'Components/form';
import Modal from 'Components/Modal';
import { Field, Formik } from 'formik';
import { useEffect, useRef } from 'react';
import { VALIDATION_MESSAGES } from 'Shared/enums';
import * as yup from 'yup';

interface EditUserModalProps {
  isOpen: boolean;
  onClose?: () => void;
  addUser: (data: any) => any;
  edit: boolean;
  getUsersList?: () => void;
  data?: { firstName: string; lastName: string; email: string; role: string; uuid: string };
  editUser?: (userUuid: string, data: { firstName: string; lastName: string; email: string; role: string }) => any;
  getAuthorizedUser?: () => void;
  authorizedUser?: { role: string; uuid: string };
}

function EditUserModal({ isOpen, onClose, addUser, edit, data, getUsersList, editUser, authorizedUser, getAuthorizedUser }: EditUserModalProps) {
  const formRef = useRef(null);
  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN_3)
      .max(16, VALIDATION_MESSAGES.TOO_LONG)
      .matches(/^[a-zA-ZzżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/, VALIDATION_MESSAGES.ONLY_LETTERS),
    lastName: yup
      .string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN_3)
      .max(24, VALIDATION_MESSAGES.TOO_LONG)
      .matches(/^[a-zA-Z-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/, VALIDATION_MESSAGES.ONLY_LETTERS),
    email: yup.string().email(VALIDATION_MESSAGES.EMAIL).required(VALIDATION_MESSAGES.REQUIRED),
    role: yup.mixed().required(VALIDATION_MESSAGES.REQUIRED),
  });

  useEffect(() => {
    isOpen && formRef.current.resetForm();
  }, [isOpen]);

  const handleOnSubmit = async (values: any) => {
    const valuesCopy = { ...values };
    valuesCopy.role = values.role[0].value || values.role;
    const result = edit ? await editUser(data.uuid, valuesCopy) : await addUser(valuesCopy);
    if (result == true) {
      if (data.uuid === authorizedUser.uuid) {
        getAuthorizedUser();
      }
      getUsersList();
      onClose();
      formRef.current.resetForm();
    } else {
      formRef.current.setErrors({ email: VALIDATION_MESSAGES.EMAIL_TAKEN });
    }
  };

  const initialValues = {
    firstName: edit ? data.firstName : '',
    lastName: edit ? data.lastName : '',
    email: edit ? data.email : '',
    role: edit ? data.role : '',
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="add-user-modal__header">{edit ? 'Edycja użytkownika' : 'Dodawanie użytkownika'}</h2>
      <div className="add-user-modal__wrapper">
        <Formik
          validationSchema={validationSchema}
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values) => handleOnSubmit(values)}>
          {({ handleSubmit, errors }) => (
            <form onSubmit={handleSubmit} className="add-user-modal__form">
              <div className="add-user-modal__form-row">
                <Field
                  className={''}
                  label="Imię"
                  name="firstName"
                  placeholder="Imię"
                  required
                  component={Input}
                  errors={errors}
                  treshold={0}
                  maxLength={16}
                />
                <Field
                  className={''}
                  label="Nazwisko"
                  name="lastName"
                  placeholder="Nazwisko"
                  required
                  component={Input}
                  treshold={0}
                  errors={errors}
                  maxLength={24}
                />
              </div>
              <div className="add-user-modal__form-row">
                <Field
                  className={''}
                  label="Email"
                  name="email"
                  placeholder="Email"
                  required
                  component={Input}
                  errors={errors}
                  maxLength={64}
                  treshold={0}
                />
                <Field
                  className={''}
                  treshold={0}
                  label="Rola"
                  name="role"
                  placeholder="Rola"
                  required
                  component={Select}
                  options={rolesOptions}
                  errors={errors}
                />
              </div>
              <div className="add-user-modal__form-row">
                <Button label="Anuluj" className="add-user-modal__add-button" type="button" cancel onClick={() => onClose()} round stroke />
                <Button label="Zapisz" className="add-user-modal__add-button" type="submit" onClick={() => null} round />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default EditUserModal;
