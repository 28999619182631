import { useEffect, useState } from 'react';
import { MainType } from './Container';
import { Field, Formik } from 'formik';
import Pagination from 'Components/Pagination';
import { Input, Select } from 'Components/form';
import { AddLanguageModal, DeleteLanguageModal, EditTranslationModal, Filters, Languages, Translations } from './components';
import AddTranslationModal from './components/addTranslationModal';
import { isMobile } from 'Assets/isMobile';
import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';
import Button from 'Components/Button';
import EditLanguageModal from './components/editLanguageModal';

function TranslationsList(props: MainType) {
  const { actions, translationsList, languages, languagesList, authorizedUser } = props;
  const {
    getTranslationsList,
    getLanguages,
    getLanguagesList,
    addTranslation,
    editTranslation,
    addLanguage,
    editLanguage,
    addLanguageFlag,
    deleteLanguage,
  } = actions;
  const translationList: any = translationsList.data || [];
  const [currentPage, setCurrentPage] = useState(1);
  const [isTranslationsList, setIsTranslationsList] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState<any>({ languageFromUuid: null, languageIntoUuid: null, search: '' });
  const [openedModal, setOpenedModal] = useState('');
  const [editData, setEditData] = useState(null);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const isAdmin = authorizedUser.role === 'admin';
  const [optionsLanguagesFrom, setOptionsLanguagesFrom] = useState<any>(languages);
  const [optionsLanguagesTo, setOptionsLanguagesTo] = useState<any>(languages);

  useEffect(() => {
    setOptionsLanguagesTo(languages);
    setOptionsLanguagesFrom(languages);
  }, [languages]);

  useEffect(() => {
    getLanguages();
    getLanguagesList('');
  }, []);

  useEffect(() => {
    getLanguages();
  }, [isTranslationsList]);

  useEffect(() => {
    getTranslationsList(currentPage, selectedFilters);
  }, [currentPage, isTranslationsList]);

  const handleValueChange = (values) => {
    if (values?.languageIntoUuid) {
      const filteredLanguagesFrom = languages.filter((language) => language.value !== values.languageIntoUuid[0]?.value);
      setOptionsLanguagesFrom(filteredLanguagesFrom);
    }
    if (values?.languageFromUuid) {
      const filteredLanguagesTo = languages.filter((language) => language.value !== values.languageFromUuid[0]?.value);
      setOptionsLanguagesTo(filteredLanguagesTo);
    }
    const newValues = selectedFilters;
    newValues.languageFromUuid = values.languageFromUuid
      ? values.languageFromUuid.length
        ? values.languageFromUuid[0].value
        : null
      : selectedFilters.languageFromUuid;
    newValues.languageIntoUuid = values.languageIntoUuid
      ? values.languageIntoUuid.length
        ? values.languageIntoUuid[0].value
        : null
      : selectedFilters.languageIntoUuid;
    newValues.search = typeof values === 'string' ? values : '';
    setSelectedFilters(newValues);
    if (isTranslationsList) {
      setCurrentPage(1);
      getTranslationsList(currentPage, newValues);
    } else {
      getLanguagesList(newValues.search);
    }
  };

  const handleCloseModal = () => {
    setOpenedModal('');
    setEditData(null);
  };

  const initialValues = {
    languageFromUuid: [null],
    languageIntoUuid: [null],
    search: '',
  };

  const filterOption = (option, inputValue) => {
    return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <div className="translations-list">
      <div className="translations-list__header-wrapper">
        <div className="translations-list__header-top">
          <h1 className="translations-list__header">Języki i tłumaczenia </h1>
          {isMobile() && isTranslationsList ? (
            <span onClick={() => setIsFiltersOpen(true)}>
              <SVG type={SVG_TYPE.FILTER} />
              <SVG
                type={SVG_TYPE.CIRCLE}
                className={`translations-list__filter-dot ${
                  selectedFilters.languageIntoUuid || selectedFilters.languageFromUuid ? 'translations-list__filter-dot--has-filters' : ''
                } `}
              />
            </span>
          ) : null}
        </div>
        <Formik initialValues={initialValues} onSubmit={(values) => console.log(values)}>
          {({ handleSubmit, setValues }) => (
            <form className="translations-list__form" onSubmit={handleSubmit}>
              {isTranslationsList ? (
                <>
                  {isMobile() ? (
                    <Filters
                      handleValueChange={handleValueChange}
                      languages={languages}
                      isFilersOpen={isFiltersOpen}
                      setIsFilersOpen={setIsFiltersOpen}
                      selectedFilters={selectedFilters}
                      setSelectedFilters={setSelectedFilters}
                      setValues={setValues}
                      optionsLanguagesFrom={optionsLanguagesFrom}
                      optionsLanguagesTo={optionsLanguagesTo}
                    />
                  ) : (
                    <>
                      <Field
                        component={Select}
                        options={optionsLanguagesFrom}
                        placeholder="Wybierz język"
                        name="languageFromUuid"
                        label="Tłumaczenie z"
                        required
                        filterOption={(option, inputValue) => filterOption(option, inputValue)}
                        isClearable
                        outSideValueChange={(values) => handleValueChange(values)}
                      />
                      <Field
                        component={Select}
                        options={optionsLanguagesTo}
                        placeholder="Wybierz język"
                        name="languageIntoUuid"
                        label="Tłumaczenie na"
                        required
                        isClearable
                        filterOption={(option, inputValue) => filterOption(option, inputValue)}
                        outSideValueChange={(values) => handleValueChange(values)}
                      />
                    </>
                  )}
                </>
              ) : isMobile() ? null : (
                <Field
                  component={Input}
                  placeholder="Wyszukaj język"
                  name="search"
                  // label="Szukaj"
                  theshold={500}
                  required
                  removable
                  onChange={(values) => handleValueChange(values)}
                />
              )}
            </form>
          )}
        </Formik>
      </div>
      <div className="translations-list__top">
        <p
          className={`translations-list__top-text ${isTranslationsList ? 'translations-list__top-text--active' : ''}`}
          onClick={() => setIsTranslationsList(true)}>
          Tłumaczenia
        </p>
        <p
          className={`translations-list__top-text ${isTranslationsList ? '' : 'translations-list__top-text--active'}`}
          onClick={() => setIsTranslationsList(false)}>
          Lista języków
        </p>
        {isMobile() && isAdmin ? (
          <Button
            label="+"
            className="translations-list__add-user-button"
            onClick={() => {
              isTranslationsList ? setOpenedModal('addTranslation') : setOpenedModal('addLanguage');
            }}
          />
        ) : null}
      </div>
      <AddTranslationModal
        isOpen={openedModal === 'addTranslation'}
        addTranslation={addTranslation}
        editTranslation={editTranslation}
        onClose={() => handleCloseModal()}
        edit={openedModal === 'editTranslation'}
        getTranslationsList={getTranslationsList}
        currentPage={currentPage}
        selectedFilters={selectedFilters}
        languages={languages}
        data={editData}
        filterOption={filterOption}
      />
      <EditTranslationModal
        isOpen={openedModal === 'editTranslation'}
        addTranslation={addTranslation}
        editTranslation={editTranslation}
        onClose={() => handleCloseModal()}
        edit={true}
        getTranslationsList={getTranslationsList}
        currentPage={currentPage}
        selectedFilters={selectedFilters}
        languages={languages}
        data={editData}
        filterOption={filterOption}
      />
      <AddLanguageModal
        isOpen={openedModal === 'addLanguage'}
        addLanguage={addLanguage}
        editLanguage={editLanguage}
        onClose={() => handleCloseModal()}
        edit={false}
        getLanguagesList={getLanguagesList}
        selectedFilters={selectedFilters}
        data={editData}
        currentPage={currentPage}
        addLanguageFlag={addLanguageFlag}
      />
      <EditLanguageModal
        isOpen={openedModal === 'editLanguage'}
        addLanguage={addLanguage}
        editLanguage={editLanguage}
        onClose={() => handleCloseModal()}
        edit={true}
        getLanguagesList={getLanguagesList}
        selectedFilters={selectedFilters}
        data={editData}
        currentPage={currentPage}
        addLanguageFlag={addLanguageFlag}
      />
      <DeleteLanguageModal
        isOpen={openedModal === 'deleteLanguage'}
        language={editData}
        onClose={() => handleCloseModal()}
        deleteLanguage={deleteLanguage}
        getLanguagesList={getLanguagesList}
        selectedFilters={selectedFilters}
      />
      {isTranslationsList ? (
        <Translations
          translationsList={translationList}
          setOpenedModal={setOpenedModal}
          setEditData={setEditData}
          editTranslation={editTranslation}
          getTranslationsList={getTranslationsList}
          currentPage={currentPage}
          selectedFilters={selectedFilters}
          isAdmin={isAdmin}
        />
      ) : (
        <Languages
          handleValueChange={handleValueChange}
          languagesList={languagesList}
          selectedFilters={selectedFilters}
          setOpenedModal={setOpenedModal}
          setEditData={setEditData}
          isAdmin={isAdmin}
        />
      )}
      {translationsList.meta?.last_page > 1 && isTranslationsList ? (
        <Pagination
          className="order-list__pagination"
          totalPages={translationsList.meta?.last_page || 0}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      ) : null}
    </div>
  );
}

export default TranslationsList;
