import { useEffect, useState } from 'react';

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;

  className?: string;
  onClose?: () => void;
  onOpen?: () => void;
}

function Modal({ children, isOpen, onClose, className = '', onOpen }: ModalProps) {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(false);
      onClose && onClose();
    }
  };

  useEffect(() => {
    setIsModalOpen(isOpen);
    if (isOpen && onOpen) {
      onOpen();
    }
  }, [isOpen]);

  return (
    <div onClick={(e) => handleOutsideClick(e)} className={`modal__backdrop ${isModalOpen ? 'modal__backdrop--open' : ''}`}>
      <div className={`modal__wrapper ${isModalOpen ? 'modal_wrapper--open' : ''}`}>
        <div className={`modal ${className} ${isModalOpen ? 'modal--open' : ''}`}>{children}</div>
      </div>
    </div>
  );
}

export default Modal;
