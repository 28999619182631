import * as LanguageModule from '../../../Modules/LanguagesModule';
import { Languages } from '../EnumTypes/ActionEnumTypes';
// import * as OrderActionModel from '../Models/OrderActionModel';

const init = () => ({
  type: Languages.GET_LANGUAGES_LIST_INIT,
});

const success = (data: any) => ({
  type: Languages.GET_LANGUAGES_LIST_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Languages.GET_LANGUAGES_LIST_FAILURE,
});

const getLanguagesList = (search) => async (dispatch) => {
  dispatch(init());
  try {
    const data = await LanguageModule.Connector.getLanguagesList(search);
    dispatch(success(data));
    //   dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err: any) {
    //   dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
    console.log('getLanguagesList', err.response);
    // toast.error('Niepoprawne dane logowania');
  }
};

export default getLanguagesList;
