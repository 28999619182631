import { Order } from '../EnumTypes/ActionEnumTypes';
import * as OrderModule from '../../../Modules/OrderModule';

const init = () => ({
  type: Order.GET_ORDER_INIT,
});

const success = (data: any) => ({
  type: Order.GET_ORDER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Order.GET_ORDER_FAILURE,
});

const getOrder = (uuid) => async (dispatch) => {
  dispatch(init());
  try {
    const data = await OrderModule.Connector.getOrder(uuid);
    dispatch(success(data));
  } catch (err: any) {
    dispatch(failure());
    console.log('GET_ORDER', err.response);
  }
};

export default getOrder;
