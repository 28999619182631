import { specialization } from 'Assets/specialization';
import { Radio, Select } from 'Components/form';
import { Field } from 'formik';
import { useEffect, useState } from 'react';

function LanguagesForm({
  errors,
  values,
  translateFromOptions,
  setFormValues,
  translateToOptions,
  setLanguagesInModal,
  setIsModalOpen,
  wasModalOpen,
  setWasModalOpen,
  currentStep,
  isModalOpen,
}: any) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isIntoMenuOpen, setIsIntoMenuOpen] = useState(false);
  useEffect(() => {
    const selectControlNode = document.querySelector('#translateTo .select__control');

    const handleClick = (e: any) => {
      if (wasModalOpen && !e.target.closest('.select__multi-value__remove') && values?.translateTo && values?.translateTo?.length > 1) {
        setIsModalOpen(true);
      } else {
        setIsIntoMenuOpen(!isIntoMenuOpen);
      }
    };

    const handleOutsideClick = (e: any) => {
      if (!e.target.closest('.select__control')) {
        setIsIntoMenuOpen(false);
      }
    };
    const tempArray = [];
    if (Array.isArray(values.translateTo)) {
      values.translateTo.map((item) => {
        tempArray.push(item.value);
      });
      setLanguagesInModal(translateToOptions.filter((item) => tempArray.includes(item.value) === false));
    }
    selectControlNode.addEventListener('click', handleClick);
    selectControlNode.addEventListener('touchstart', handleClick);
    document.addEventListener('click', handleOutsideClick);
    return () => {
      selectControlNode.removeEventListener('click', handleClick);
      document.removeEventListener('click', handleOutsideClick);
      selectControlNode.removeEventListener('touchstart', handleClick);
    };
  }, [values.translateTo, translateToOptions, isIntoMenuOpen]);

  useEffect(() => {
    setFormValues(values);
  }, [values]);

  useEffect(() => {
    if (isModalOpen) {
      setIsIntoMenuOpen(false);
    }
  }, [isModalOpen]);

  return (
    <div className={`container-left__slide container-left__slide--${currentStep} ${isMenuOpen ? 'container-left--language-menu-open' : ''}`}>
      <div className="order-page__languages">
        <Field
          label="Przetłumacz z"
          name="translateFrom"
          placeholder="Wybierz język"
          required
          component={Select}
          errors={errors}
          id={'translateFrom'}
          options={translateFromOptions}
        />
        <div className={`order-page__translateTo-wrapper ${values?.translateTo?.length > 1 ? 'order-page__translateTo-wrapper--multi' : ''}`}>
          <Field
            label="Przetłumacz na"
            name="translateTo"
            placeholder="Wybierz język"
            required
            component={Select}
            errors={errors}
            changeOnlyFirstElement={values?.translateTo?.length < 2}
            addMore={values?.translateTo?.length > 0 || wasModalOpen}
            // openMenuOnClick={true}
            isMulti
            isClearable={false}
            menuIsOpen={isIntoMenuOpen}
            disabled={!values?.translateFrom?.length}
            id={'translateTo'}
            addMoreOnClick={() => {
              setIsModalOpen(true);
              setWasModalOpen(true);
            }}
            options={translateToOptions}
          />
          <span className="order-page__translateTo-indicator">{values.translateTo?.length > 2 ? `+ ${values.translateTo?.length - 2}` : ``}</span>
        </div>
      </div>
      <Field
        label="Specjalizacja"
        name="specialization"
        placeholder="Wybierz specjalizację"
        isClearable={true}
        component={Select}
        errors={errors}
        className="order-page__specialization"
        options={specialization}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
      />
      <Field
        component={Radio}
        label="Czas realizacji/ Priorytet"
        name="priorities"
        row
        options={[
          { value: 'standard', label: 'Standard', subTitle: '7 stron / dzień roboczy' },
          { value: 'express', label: 'Ekspres', subTitle: '14 stron / dzień roboczy' },
        ]}
      />
      <div>
        <Field
          label="Typ tłumaczenia"
          name="translationType"
          row
          className="order-page__quality"
          options={[
            { value: 'regular', label: 'Zwykłe' },
            { value: 'certified', label: 'Przysięgłe' },
          ]}
          component={Radio}
        />
        <span className="order-page__quality-tooltip">
          {values.translationType === 'certified' ? (
            <>
              <p className="order-page__quality-tooltip--mobile">
                Po złożeniu zamówienia prześlemy Państwu skan tłumaczenia, a następnie skontaktujemy się w sprawie wysyłki listownej.
              </p>
              <p className="order-page__quality-tooltip--desktop">
                Po złożeniu zamówienia prześlemy Państwu skan tłumaczenia,
                <br /> a następnie skontaktujemy się w sprawie wysyłki listownej.
              </p>
            </>
          ) : null}
        </span>
      </div>
    </div>
  );
}

export default LanguagesForm;
