import * as FilesModule from '../../../Modules/FilesModule';
import { Files } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Files.ADD_LANGUAGE_FLAG_INIT,
});

const success = (data: any) => ({
  type: Files.ADD_LANGUAGE_FLAG_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Files.ADD_LANGUAGE_FLAG_FAILURE,
});

const addLanguageFlag =
  ({ files, languageUuid = undefined }) =>
  async (dispatch) => {
    dispatch(init());
    try {
      const formData = new FormData();
      formData.append('files', files[0]);
      const data = await FilesModule.Connector.addLanguageFlag({ formData, languageUuid });
      dispatch(success(data));
      toast.success('Pomyślnie dodano plik', { icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} /> });
      return true;
    } catch (err: any) {
      dispatch(failure());
      toast.error('Nie udało się dodać pliku', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
      console.log(err);
      return false;
    }
  };

export default addLanguageFlag;
