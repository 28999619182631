import SVG from 'Components/SVG';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { router } from 'Shared/utils';
import { ViewManagement } from 'Store/Actions/EnumTypes/ActionEnumTypes';
import { useDispatch } from 'react-redux';

const Footer = () => {
  const dispatch = useDispatch();
  const handleCookieModalOpen = () => {
    dispatch({ type: ViewManagement.SET_IS_COOKIE_MODAL_OPEN, payload: { boolean: true } });
  };

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__column">
          <div onClick={() => router.navigate(ROUTES.HOME_PAGE)}>
            <SVG type={SVG_TYPE.LOGO} className="navigation__logo" />
          </div>
          <div className="footer__socials">
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Highline.Language.Services">
              <SVG type={SVG_TYPE.FACEBOOK} className="footer__socials-icon" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://instagram.com/highline.languages?igshid=YmMyMTA2M2Y">
              <SVG type={SVG_TYPE.INSTAGRAM} className="footer__socials-icon" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://pl.linkedin.com/company/highline-language-school-and-translation-agency-for-business">
              <SVG type={SVG_TYPE.LINKEDIN} className="footer__socials-icon" />
            </a>

            <a target="_blank" rel="noreferrer" href="https://bit.ly/3SqcFfl">
              <SVG type={SVG_TYPE.YOUTUBE} className="footer__socials-icon" />
            </a>
          </div>
        </div>
        <div className="footer__column">
          <p>
            Działamy na terenie całej Polski,
            <br /> nasze dwie centrale znajdują się w:
          </p>
          <h3>
            Kraków, ul. Wadowicka 8A <br />
            Warszawa, ul. Chłodna 51
          </h3>
        </div>
        <div className="footer__column">
          <p>
            Biuro - numer główny: <a href="tel:+48733135033">+48 733 135 033</a>
            <br /> Dział Szkoleń Językowych: <a href="tel:+48733133494">+48 733 133 494</a>
            <br /> Dział Tłumaczeń: <a href="tel:+48538622882">+48 538 622 882</a> <br />
            Email: <a href="mailto:office@highline.com.pl">office@highline.com.pl</a>
          </p>
        </div>
        <div className="footer__column">
          <a className="footer__information" href="https://highline.com.pl/regulamin.pdf" target="_blank" rel="noreferrer">
            Regulamin
          </a>
          <a className="footer__information" href="https://highline.com.pl/polityka_prywatnosci.pdf" target="_blank" rel="noreferrer">
            Polityka prywatności
          </a>
          <a className="footer__information" onClick={() => handleCookieModalOpen()}>
            Polityka plików cookie
          </a>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
