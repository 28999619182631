import { Order } from '../EnumTypes/ActionEnumTypes';

const clearStore = () => ({
  type: Order.CLEAR_STORE,
});

const clearOrders = () => async (dispatch) => {
  dispatch(clearStore());
};

export default clearOrders;
