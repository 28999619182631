import SVG from 'Components/SVG';
import { Field, Formik } from 'formik';
import { ROUTES, SVG_TYPE, VALIDATION_MESSAGES } from 'Shared/enums';
import { MainType } from './Container';
import * as Yup from 'yup';
import { Input } from 'Components/form';
import Button from 'Components/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Back from 'Components/Back';

function LoginPage(props: MainType) {
  const { actions, authorisedUser, location } = props;
  const { login, setIsNaviagtionHidden } = actions;
  const [showError, setShowError] = useState<boolean>(false);
  const navigate = useNavigate();
  const backToLogin = localStorage.getItem('lastPage') === '/dashboard' || location.pathname === '/login';

  const onSubmit = async (values) => {
    const isSuccesfulLogin = await login(values.email, values.password);
    setShowError(!isSuccesfulLogin);
  };

  const validation = () =>
    Yup.object().shape({
      email: Yup.string().required(VALIDATION_MESSAGES.REQUIRED).email(VALIDATION_MESSAGES.EMAIL),
      password: Yup.string().min(8, VALIDATION_MESSAGES.PASSWORD_LENGTH).required(VALIDATION_MESSAGES.REQUIRED),
    });
  useEffect(() => {
    setIsNaviagtionHidden(true);
    return () => {
      setIsNaviagtionHidden(false);
    };
  }, []);

  useEffect(() => {
    authorisedUser && (setIsNaviagtionHidden(true), navigate(ROUTES.DASHBOARD_ORDERS));
  }, [authorisedUser]);

  const initialValues = {
    email: '',
    password: '',
  };
  return (
    <div className="page login-page">
      <span className="login-page__box"></span>
      <div className="login-page__wrapper">
        <Back onClick={() => (backToLogin ? navigate(ROUTES.HOME_PAGE) : navigate(-1))} className="login-page__back" />
        <SVG type={SVG_TYPE.LOGO} />
        <p className="login-page__subtitle">Zaloguj się, aby wejść do panelu administracyjnego</p>
        <h2 className="login-page__title">Logowanie</h2>

        <p className={`login-page__error ${showError ? 'login-page__error--show' : ''}`}>
          <SVG type={SVG_TYPE.WARNING} /> Błąd logowania: podano nieprawidłowy login lub hasło.
        </p>

        <Formik
          isInitialValid={false}
          validationSchema={validation}
          initialValues={initialValues}
          validateOnBlur={false}
          enableReinitialize
          onSubmit={async (values) => onSubmit(values)}>
          {({ handleSubmit, errors, isSubmitting }) => (
            <form className="login-page__form" onSubmit={handleSubmit}>
              <Field
                className={'login-page__input'}
                label="Email"
                name="email"
                placeholder="Wpisz email"
                required
                onChange={() => setShowError(false)}
                component={Input}
                treshold={0}
                maxLength={32}
                errors={errors}
              />
              <Field
                className={'login-page__input'}
                label="Hasło"
                name="password"
                placeholder="Wpisz hasło"
                required
                onChange={() => setShowError(false)}
                component={Input}
                errors={errors}
                treshold={0}
                maxLength={24}
                variant="password"
              />
              <p className="login-page__forgot" onClick={() => navigate(ROUTES.RESTORE_PASSWORD)}>
                Nie pamiętasz hasła?
              </p>
              <Button label="Zaloguj się" type="submit" onClick={() => null} round disabled={isSubmitting} />
            </form>
          )}
        </Formik>
      </div>
      <SVG type={SVG_TYPE.LOGIN_BACKGROUND} className="login-page__svg" />
    </div>
  );
}

export default LoginPage;
