import Button from 'Components/Button';
import { Dispatch, SetStateAction, useState } from 'react';
import { SVG_TYPE } from 'Shared/enums';
import ContentLoader from 'react-content-loader';
import SVG from 'Components/SVG';
import { isMobile } from 'Assets/isMobile';

interface AddFileProps {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  isValid: boolean;
  handleAddFIle: (event: any) => void;
  createOrder: (dataForm) => void;
  values: any;
  currentStep: number;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  secondSlide: boolean;
  loaderVisible: boolean;
  validateForm: () => Promise<any>;
  setTouched: (touched: any, shouldValidate?: boolean | undefined) => void;
  setDragging: (dragging: boolean) => void;
  dragging: boolean;
  errors: any;
  touched: any;
}

function AddFile({
  isValid,
  handleAddFIle,
  values,
  setFieldValue,
  secondSlide,
  loaderVisible,
  validateForm,
  setTouched,
  setDragging,
  dragging,
  errors,
  touched,
}: AddFileProps) {
  const [dragOverInput, setDragOverInput] = useState(false);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOverInput(true);
  };
  const handleDragLeave = () => {
    setDragOverInput(false);
  };

  const fileInput = document.getElementById('file-input');
  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragExit = () => {
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    handleAddFIle(e);
    setDragOverInput(false);
  };

  const handleFocusOnInput = () => {
    if (Object.keys(errors).length > 0) {
      console.log(document.querySelector(` #${Object.keys(errors)[0]} `));
      const node = document.querySelector(`#${Object.keys(errors)[0]}`);
      if (node) {
        console.log(node);
        node.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const handleError = (response) => {
    setTouched(Object.keys(response).reduce((acc, key) => ({ ...acc, [key]: true }), {}));
    handleFocusOnInput();
  };

  return (
    <div
      className={`container-right__slide ${secondSlide ? 'container-right__slide--second' : ''} ${
        dragging ? 'container-right__slide--dragging' : ''
      }`}
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleDragExit}
      onDrop={handleDrop}>
      {loaderVisible ? (
        <ContentLoader
          speed={2}
          width={289}
          height={586}
          viewBox="0 0 289 586"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          className="container-right__loader"
          preserveAspectRatio="xMinYMin slice">
          <rect x="0" y="0" rx="6" ry="6" width="139" height="29" />
          <rect x="0" y="52" rx="6" ry="6" width="78" height="17" />

          <rect x="0" y="89" rx="6" ry="6" width="17" height="13" />
          <rect x="39" y="89" rx="6" ry="6" width="26" height="13" />
          <rect x="113" y="89" rx="6" ry="6" width="17" height="13" />
          <rect x="139" y="89" rx="6" ry="6" width="38" height="13" />

          <rect x="0" y="132" rx="6" ry="6" width="79" height="17" />
          <rect x="268" y="132" rx="6" ry="6" width="21" height="14" />

          <rect x="0" y="170" rx="6" ry="6" width="69" height="17" />
          <rect x="194" y="170" rx="6" ry="6" width="13" height="13" />
          <rect x="217" y="170" rx="6" ry="6" width="58" height="13" />

          <rect x="0" y="210" rx="6" ry="6" width="79" height="17" />
          <rect x="248" y="209" rx="6" ry="6" width="41" height="14" />

          <rect x="0" y="246" rx="6" ry="6" width="89" height="17" />
          <rect x="235" y="246" rx="6" ry="6" width="55" height="14" />

          <rect x="0" y="303" rx="6" ry="6" width="55" height="19" />
          <rect x="250" y="303" rx="6" ry="6" width="40" height="17" />

          <rect x="0" y="362" rx="6" ry="6" width="96" height="19" />
          <rect x="0" y="362" rx="6" ry="6" width="47" height="17" />

          <rect x="0" y="395" rx="6" ry="6" width="290" height="54" />

          <rect x="0" y="485" rx="6" ry="6" width="290" height="16" />

          <rect x="0" y="556" rx="6" ry="6" width="290" height="30" />
        </ContentLoader>
      ) : (
        <>
          <span className={`order-page__add-file-wrapper`}>
            <h2 className="order-page__header order-page__header--right">Dodaj plik</h2>
            <p className="order-page__subheader">
              Prosimy o załączenie pliku, który jest wymagany do kontynuacji procesu. Bez dodania tego pliku nie będzie możliwe przejście do kolejnego
              kroku. Upewnij się, że plik jest w odpowiednim formacie.
            </p>

            <div
              onDragEnter={handleDragOver}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              id="files"
              className={`add-translated-file-modal__wrapper order-page__drop-file ${
                errors.files && touched.files ? 'order-page__drop-file--error' : ''
              } 
              ${dragOverInput ? 'order-page__drop-file--drag-over' : ''}`}>
              <SVG type={SVG_TYPE.SEND_FILE} />
              <p className="add-translated-file-modal__text">
                {isMobile() ? null : 'Przeciągnij i upuść lub '}
                <span className="add-translated-file-modal__text--download" onClick={() => fileInput.click()}>
                  {isMobile() ? 'Wgraj pliki' : 'wgraj pliki'}
                </span>
                <input
                  id="file-input"
                  name="files"
                  className="order-page__file-input"
                  type="file"
                  multiple
                  accept=".doc,.docx,.txt,.pdf,.png,.txt,.jpeg,.jpg"
                  onChange={(event) => {
                    handleAddFIle(event);
                  }}
                />
              </p>
              <p className="order-page__text-below-button">
                Dopuszczalne formaty plików to docx, doc, pdf. W przypadku załączenia innego formatu, nie będzie możliwa automatyczna wycena.
                Skontaktujemy się wtedy za pomocą maila. Możesz dodać max. 10 plików.
              </p>
            </div>
            <p className={`order-page__error ${errors.files && touched.files ? 'order-page__error--visible' : ''}`}>{errors.files}</p>
          </span>
          <Button
            label="Dalej"
            onClick={async () => {
              const response = await validateForm();
              isValid || !Object.keys(response).length
                ? (!values.priorities && setFieldValue('priorities', 'standard'),
                  !values.translationType && setFieldValue('translationType', 'regular'),
                  document.querySelector('html')?.scrollTo(0, 0))
                : handleError(response);
            }}
            className="order-page__continue-button"
          />
        </>
      )}
    </div>
  );
}

export default AddFile;
