import Button from 'Components/Button';
import Modal from 'Components/Modal';

interface EditOrderModalProps {
  isOpen: boolean;
  onClose?: () => void;
  data: {
    signCount: string;
  };
}

function EditOrderModal({ isOpen, onClose, data }: EditOrderModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="add-user-modal__header">Liczba znaków</h2>

      <div className="add-user-modal__wrapper">
        <h3 className="single-order__modal-text">Czy jesteś pewien, że wprowadzona liczba znaków: <span className='single-order__modal-text--bold'>{data.signCount}</span> jest poprawna?</h3>
        <div className="add-user-modal__form-row">
          <Button label="Wróć" className="add-user-modal__add-button" type="button" onClick={() => onClose()} round stroke />
          <Button label="Akceptuj" className="add-user-modal__add-button" type="submit" onClick={() => null} round />
        </div>
      </div>
    </Modal>
  );
}

export default EditOrderModal;
