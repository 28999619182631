interface ModalProps {
  children: React.ReactNode;
  className?: string;
}

function ContainerLeft({ children,className='' }: ModalProps) {
  return <div className={`container-left ${className}`}>{children}</div>;
}

export default ContainerLeft;
