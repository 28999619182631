import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';
import * as AuthModule from 'Modules/AuthModule';

export function login(email: string, password: string): AxiosPromise<AuthModule.Types.LogInResponse> {
  return API.post('login', { email, password });
}
export function logout(): AxiosPromise<any> {
  return API.post('logout');
  // config(null, { Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}` })
}
// }
export function getAuthorizedUser(): AxiosPromise<AuthModule.Types.AuthorizedUserData> {
  return API.get('users/me');
}

export function getRefreshToken(refreshToken: string): AxiosPromise<any> {
  return API.post('auth/refresh', { refreshToken: refreshToken });
}

export function getResetPasswordToken(email: string): AxiosPromise<any> {
  return API.post('users/password/reset', { email: email });
}
export function resetPassword(resetToken, newPassword: string): AxiosPromise<any> {
  return API.put('users/password/reset', { resetToken, newPassword });
}

export function activateUser(code , password : string): AxiosPromise<any> {
  return API.put('users/activate', { code , password  });
}

export function checkToken(token:string ): AxiosPromise<any> {
  return API.get('users/valid/token', {params: {token}  });
}
export function changePassword(currentPassword , newPassword: string): AxiosPromise<any> {
  return API.put('users/password/change', { currentPassword , newPassword  });
}