import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROUTES } from 'Shared/enums';
import { useEffect, useState } from 'react';

/* eslint-disable */
function ProtectedRoute({ children }) {
  const isAuth = useSelector((state: any) => state.authStore.authorizedUser);
  const navigate = useNavigate();
  const [showPage, setShowPage] = useState<boolean>(false);
  useEffect(() => {
    isAuth === 'failed' && navigate(ROUTES.LOGIN_PAGE);
    isAuth?.status ? setShowPage(true) : setShowPage(false);
  }, [isAuth]);

  return <>{showPage ? children : null}</>;
}
export default ProtectedRoute;
