import { SVG_TYPE } from 'Shared/enums';

export const specialization = [
  { value: 'architecture', label: 'Architektura', svgType: SVG_TYPE.ARCHITECTURE },
  { value: 'economics/finance', label: 'Ekonomia / Finanse', svgType: SVG_TYPE.ECONOMIC },
  { value: 'IT', label: 'IT / Software', svgType: SVG_TYPE.IT },
  { value: 'marketing', label: 'Marketing', svgType: SVG_TYPE.MARKETING },
  { value: 'medicine', label: 'Medycyna', svgType: SVG_TYPE.MED },
  { value: 'law/contracts', label: 'Prawo / Umowy', svgType: SVG_TYPE.LAW },
  { value: 'technical', label: 'Techniczne', svgType: SVG_TYPE.TECHNICAL },
];
