import * as Types from 'Shared/models/AuthModel';
import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';

export type State = Types.AuthStore;

export const initialState: State = {
  token: null,
  authorizedUser: 0,
};

export function authReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case ActionTypes.Auth.LOG_IN_SUCCESS: {
      localStorage.setItem('isUserLogged', JSON.stringify(true));
      return {
        ...state,
        token: action.payload.authData.token,
      };
    }
    case ActionTypes.Auth.LOG_OUT_SUCCESS: {
      console.log(state.authorizedUser);

      return {
        ...state,
        authorizedUser: 'failed',
      };
    }
    case ActionTypes.Auth.LOG_OUT_FAILURE: {
      localStorage.setItem('isUserLogged', JSON.stringify(false));
      return {
        ...state,
        token: null,
        tokenExpires: null,
        authorizedUser: null,
      };
    }
    case ActionTypes.Auth.GET_AUTHORIZED_USER_FAILURE: {
      return {
        ...state,
        authorizedUser: action.payload,
      };
    }
    case ActionTypes.Auth.GET_AUTHORIZED_USER_SUCCESS: {
      return {
        ...state,
        authorizedUser: action.payload.authorizedUserData,
      };
    }
    case ActionTypes.Auth.SET_REFRESH_TOKEN: {
      return {
        ...state,
        token: action.payload.authData.token,
      };
    }
    case ActionTypes.Auth.GET_REFRESH_TOKEN_SUCCESS: {
      localStorage.setItem('isUserLogged', JSON.stringify(true));
      return {
        ...state,
        token: action.payload.token,
      };
    }
    case ActionTypes.Auth.RESET_PASSWORD_INIT: {
      return {
        ...state,
      };
    }
    case ActionTypes.Auth.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
      };
    }
    case ActionTypes.Auth.RESET_PASSWORD_FAILURE: {
      return {
        ...state,
      };
    }
      
      
    default: {
      return state;
    }
  }
}
