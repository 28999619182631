import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions as OrderActions } from 'Modules/OrderModule';
import { Actions as LanguagesActions } from 'Modules/LanguagesModule';
import { Actions as FilesModule } from 'Modules/FilesModule';
import { Actions as ViewActions } from 'Modules/ViewManagementModule';
import OrderPage from './Main';
import { LanguagesSelector, TranslationsSelector } from 'Store/Selectors/LanguagesSelector';
import { companyInfoFromNip, currentOrderSelector, initialOrderSelector, orderUuidSelector } from 'Store/Selectors/OrderSelector';
import { loaderVisible } from 'Store/Selectors/ViewManagementSelector';

type ConnectedP = {
  languages: Array<any>;
  currentOrderUuid: any;
  currentOrder: any;
  translations: any;
  initialOrder: any;
  loaderVisible: boolean;
  companyInfoFromNip: { nip: string; city: string; companyName: string; postCode: string; street: string; houseNumber: string } | any;
  history?: any;
};

type DispatchedP = {
  actions: {
    createOrder: (formData) => void;
    getLanguages: () => void;
    getTranslations: (languageUuid) => void;
    addOrderFile: any;
    deleteFile: ({ uuid }) => void;
    confirmOrder: (formData, uuid) => void;
    changeCurrentOrder: (uuid) => void;
    getOrder: (uuid) => void;
    postOrderContactData: (formData, orderUuid) => any;
    showLoader: () => void;
    hideLoader: () => void;
    createPayment: (orderUuid) => void;
    clearOrders: () => void;
    getCompanyFromNip: (nip) => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapStateToProps = (state) => ({
  languages: LanguagesSelector(state),
  currentOrderUuid: orderUuidSelector(state),
  currentOrder: currentOrderSelector(state),
  translations: TranslationsSelector(state),
  initialOrder: initialOrderSelector(state),
  loaderVisible: loaderVisible(state),
  companyInfoFromNip: companyInfoFromNip(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      createOrder: OrderActions.createOrder,
      getLanguages: LanguagesActions.getLanguages,
      getTranslations: LanguagesActions.getTranslations,
      addOrderFile: FilesModule.addOrderFile,
      deleteFile: FilesModule.deleteFile,
      confirmOrder: OrderActions.confirmOrder,
      changeCurrentOrder: OrderActions.changeCurrentOrder,
      getOrder: OrderActions.getOrder,
      postOrderContactData: OrderActions.postOrderContactData,
      showLoader: ViewActions.showLoader,
      hideLoader: ViewActions.hideLoader,
      createPayment: OrderActions.createPayment,
      clearOrders: OrderActions.clearOrders,
      getCompanyFromNip: OrderActions.getCompanyFromNip,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
