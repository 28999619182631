import * as AuthModule from '../../../Modules/AuthModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import * as AuthActionModel from '../Models/AuthActionModel';

const setRefreshToken = (authData: AuthModule.Types.LogInResponse): AuthActionModel.SetRefreshToken => ({
  type: Auth.SET_REFRESH_TOKEN,
  payload: {
    authData,
  },
});

export default setRefreshToken;
