import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

const getAuthStore = (state: RootState) => state.authStore;
const authorizedUser = createSelector(getAuthStore, (state) => state.authorizedUser?.user);
const token = createSelector(getAuthStore, (state) => state.token);
const getUserRoles = createSelector(authorizedUser, (user) => user.roles);
const getUserLevel = createSelector(authorizedUser, ({ level }) => level);

export { authorizedUser, token, getUserRoles, getUserLevel };
