import { connect } from 'react-redux';
import OrderList from './Main';
import { Actions as viewManagmentModule } from 'Modules/ViewManagementModule';
import { Actions as OrderActions } from 'Modules/OrderModule';
import { Actions as UserActions } from 'Modules/UserModule';
import { bindActionCreators } from 'redux';
import { authorizedUser } from 'Store/Selectors/AuthSelector';
import { initialOrderSelector, invoiceSelector, ordersListSelector } from 'Store/Selectors/OrderSelector';
import { TranslationsSelector } from 'Store/Selectors/LanguagesSelector';
import { addTranslatedFile, deleteFile, downloadAllFiles, downloadAllTranslations } from 'Store/Actions/Files';
import { usersLabel } from 'Store/Selectors/UserSelector';

type ConnectedP = {
  authorizedUser: any;
  ordersList: any;
  initialOrder: any;
  translations: any;
  usersLabel: any;
  invoices: any;
};

type DispatchedP = {
  actions: {
    setIsNaviagtionHidden: (boolean) => void;
    getOrdersList: (page, search) => void;
    getOrder: (uuid) => void;
    downloadAllFiles: (uuid) => void;
    getUsersLabel: () => void;
    assignEmployee: (orderUuid: string, userUuid: string) => void;
    editOrder: (orderUuid: string, dataToSend) => any;
    addTranslatedFile: ({ files, orderUuid }) => any;
    deleteFile: ({ uuid }) => void;
    downloadAllTranslations: (uuid) => void;
    getInvoice: (uuid) => void;
    clearInvoice: () => void;
  };
};

const mapStateToProps = (state) => ({
  authorizedUser: authorizedUser(state),
  ordersList: ordersListSelector(state),
  translations: TranslationsSelector(state),
  initialOrder: initialOrderSelector(state),
  usersLabel: usersLabel(state),
  invoices: invoiceSelector(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      setIsNaviagtionHidden: viewManagmentModule.setIsNaviagtionHidden,
      getOrdersList: OrderActions.getOrdersList,
      getOrder: OrderActions.getOrder,
      downloadAllFiles: downloadAllFiles,
      getUsersLabel: UserActions.getUsersLabel,
      assignEmployee: OrderActions.assignEmployee,
      editOrder: OrderActions.editOrder,
      addTranslatedFile: addTranslatedFile,
      deleteFile: deleteFile,
      downloadAllTranslations: downloadAllTranslations,
      getInvoice: OrderActions.getInvoice,
      clearInvoice: OrderActions.clearInvoice,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
