import Button from 'Components/Button';
import SVG from 'Components/SVG';

import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { router } from 'Shared/utils';

interface MobileMenuProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
}

function MobileMenu(props: MobileMenuProps) {
  const { isMenuOpen, setIsMenuOpen } = props;

  return (
    <div className={`mobile-menu ${isMenuOpen ? 'mobile-menu--show' : ''}`}>
      <div className="mobile-menu__header">
        <div onClick={() => router.navigate(ROUTES.HOME_PAGE)}>
          <SVG type={SVG_TYPE.LOGO} className="navigation__logo" />
        </div>

        <div onClick={() => setIsMenuOpen(false)}>
          <SVG type={SVG_TYPE.CLOSE} />
        </div>
      </div>
      <div className="mobile-menu__wrapper">
        <Button
          className="mobile-menu__button"
          label="Zaloguj się"
          onClick={() => {
            router.navigate(ROUTES.LOGIN_PAGE);
            setIsMenuOpen(false);
          }}
          round
        />
      </div>
    </div>
  );
}

export default MobileMenu;
