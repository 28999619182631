import { Order } from '../Actions/EnumTypes/ActionEnumTypes';
// import { ViewsManagemenetAction } from '../Actions/Models/ViewManagementActionModel';

interface OrderState {
  currentOrderUuid: string;
  currentOrder: object;
  initialOrder: object;
  ordersList: Array<object>;
  companyDataFromNip: { nip: string; city: string; companyName: string; postCode: string; street: string; houseNumber: string } | object;
  invoice: object;
}

export type State = OrderState;

export const initialState: State = {
  currentOrderUuid: '',
  currentOrder: {},
  initialOrder: {},
  ordersList: [],
  companyDataFromNip: {},
  invoice: [],
};

export function orderReducer(state: State = initialState, action) {
  switch (action.type) {
    case Order.CREATE_ORDER_INIT: {
      return {
        ...state,
      };
    }
    case Order.CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        currentOrderUuid: action.payload.data.data.orderUuid,
      };
    }
    case Order.CREATE_ORDER_FAILURE: {
      return {
        ...state,
      };
    }
    case Order.CONFIRM_ORDER_INIT: {
      return {
        ...state,
      };
    }
    case Order.CONFIRM_ORDER_SUCCESS: {
      return {
        ...state,
        currentOrder: action.payload.data.data.order,
      };
    }
    case Order.CONFIRM_ORDER_FAILURE: {
      return {
        ...state,
      };
    }
    case Order.CHANGE_CURRENT_ORDER_INIT: {
      return {
        ...state,
      };
    }
    case Order.CHANGE_CURRENT_ORDER_SUCCESS: {
      return {
        ...state,
        currentOrderUuid: action.payload.data,
      };
    }
    case Order.CHANGE_CURRENT_ORDER_FAILURE: {
      return {
        ...state,
      };
    }
    case Order.GET_ORDER_INIT: {
      return {
        ...state,
      };
    }
    case Order.GET_ORDER_SUCCESS: {
      return {
        ...state,
        initialOrder: action.payload.data.data.order,
        currentOrderUuid: { uuid: action.payload.data.data.order.uuid },
        currentOrder: action.payload.data.data.order,
      };
    }
    case Order.GET_ORDER_FAILURE: {
      return {
        ...state,
      };
    }
    case Order.GET_ORDERS_LIST_INIT: {
      return {
        ...state,
      };
    }
    case Order.GET_ORDERS_LIST_SUCCESS: {
      return {
        ...state,
        ordersList: action.payload.data.data.orders,
      };
    }
    case Order.GET_ORDERS_LIST_FAILURE: {
      return {
        ...state,
      };
    }

    case Order.POST_ORDER_CONTACT_DATA_INIT: {
      return {
        ...state,
      };
    }
    case Order.POST_ORDER_CONTACT_DATA_SUCCESS: {
      return {
        ...state,
      };
    }
    case Order.POST_ORDER_CONTACT_DATA_FAILURE: {
      return {
        ...state,
      };
    }
    case Order.CLEAR_STORE: {
      return {
        currentOrderUuid: '',
        currentOrder: {},
        initialOrder: {},
        ordersList: [],
        companyDataFromNip: {},
      };
    }
    case Order.CREATE_PAYMENT_INIT: {
      return {
        ...state,
      };
    }
    case Order.CREATE_PAYMENT_SUCCESS: {
      return {
        ...state,
      };
    }
    case Order.CREATE_PAYMENT_FAILURE: {
      return {
        ...state,
      };
    }
    case Order.GET_COMPANY_DATA_FROM_NIP_INIT: {
      return {
        ...state,
      };
    }
    case Order.GET_COMPANY_DATA_FROM_NIP_SUCCESS: {
      return {
        ...state,
        companyDataFromNip: action.payload.data.data.companyInfo,
      };
    }
    case Order.GET_COMPANY_DATA_FROM_NIP_FAILURE: {
      return {
        ...state,
      };
    }
    case Order.GET_INVOICE_INIT: {
      return {
        ...state,
      };
    }
    case Order.GET_INVOICE_SUCCESS: {
      return {
        ...state,
        invoice: action.payload.data,
      };
    }
    case Order.GET_INVOICE_FAILURE: {
      return {
        ...state,
      };
    }

    case Order.CLEAR_INVOICE: {
      return {
        ...state,
        invoice: [],
      };
    }

    default: {
      return state;
    }
  }
}
