import { connect } from 'react-redux';
import TranslationsList from './Main';
import { Actions as viewManagmentModule } from 'Modules/ViewManagementModule';
import { Actions as LanguagesActions } from 'Modules/LanguagesModule';
import { bindActionCreators } from 'redux';
import { LanguagesListSelector, LanguagesSelector, TranslationsListSelector } from 'Store/Selectors/LanguagesSelector';
import { addLanguageFlag } from 'Store/Actions/Files';
import { authorizedUser } from 'Store/Selectors/AuthSelector';

type ConnectedP = {
  translationsList: any;
  languages: any;
  languagesList: any;
  authorizedUser: any;
};

type DispatchedP = {
  actions: {
    setIsNaviagtionHidden: (boolean) => void;
    getTranslationsList: (number, filters) => void;
    getLanguages: () => void;
    getLanguagesList: (search) => void;
    addTranslation: (data) => any;
    editTranslation: (uuid, data) => any;
    addLanguage: (data) => any;
    editLanguage: (uuid, data,isFileChange) => any;
    addLanguageFlag: (data) => any;
    deleteLanguage: (uuid) => any;
  };
};

const mapStateToProps = (state) => ({
  translationsList: TranslationsListSelector(state),
  languages: LanguagesSelector(state),
  languagesList: LanguagesListSelector(state),
  authorizedUser: authorizedUser(state)
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      setIsNaviagtionHidden: viewManagmentModule.setIsNaviagtionHidden,
      getTranslationsList: LanguagesActions.getTranslationsList,
      getLanguages: LanguagesActions.getLanguages,
      getLanguagesList: LanguagesActions.getLanguagesList,
      addTranslation: LanguagesActions.addTranslation,
      editTranslation: LanguagesActions.editTranslation,
      addLanguage: LanguagesActions.addLanguage,
      editLanguage: LanguagesActions.editLanguage,
      addLanguageFlag: addLanguageFlag,
      deleteLanguage: LanguagesActions.deleteLanguage,
    },
    dispatch,
  ),
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(TranslationsList);
