import { Order } from '../EnumTypes/ActionEnumTypes';
import * as OrderModule from '../../../Modules/OrderModule';
import { toast } from 'react-toastify';
import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Order.ASSING_EMPLOYEE_INIT,
});

const success = (data: any) => ({
  type: Order.ASSING_EMPLOYEE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Order.ASSING_EMPLOYEE_FAILURE,
});

const assignEmployee = (orderUuid: string, userUuid: string) => async (dispatch) => {
  dispatch(init());
  try {
    const data = await OrderModule.Connector.assignEmployee(orderUuid, userUuid);
    dispatch(success(data));
    toast.success('Pomyślnie przypisano pracownika', { icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} /> });
  } catch (err: any) {
    dispatch(failure());
    toast.error('Nie udało się przypisać pracownika', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
  }
};

export default assignEmployee;
