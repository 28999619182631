import { orderStatus } from 'Assets/orderStatus';
import { paymentStatus } from 'Assets/paymentStatus';
import { Select } from 'Components/form';
import { Field } from 'formik';
import { useEffect } from 'react';

interface StatusDataProps {
  data: any;
  values: any;
  handleSubmit: (e: any) => void;
  usersLabel: [{ value: string; label: string }];
  assignEmployeeToOrder: (orderUuid: string, userUuid: any) => void;
  getOrder: (uuid: string) => void;
  search: string;
}

function StatusData({ data, values, handleSubmit, usersLabel, assignEmployeeToOrder, getOrder, search }: StatusDataProps) {
  const assingEmployee = async (uuid: string, userUuid: any) => {
    await assignEmployeeToOrder(uuid, userUuid);
    getOrder(uuid);
  };

  useEffect(() => {
    if (Array.isArray(values.coordinator)) assingEmployee(data.uuid, values.coordinator[0].value);
  }, [values.coordinator]);

  return (
    <div className="single-order__content-wrap single-order__content-wrap--status">
      <Field
        name="coordinator"
        className={`order-list__select order-list__select--payment-status `}
        required
        component={Select}
        label="Koordynator"
        placeholder="Wybierz koordynatora"
        options={usersLabel}
      />
      <Field
        name="paymentStatus"
        className={`order-list__select order-list__select--payment-status `}
        required
        component={Select}
        label="Status płatności"
        placeholder=""
        options={paymentStatus}
        handleSubmit={handleSubmit}
        filterOption={(option, rawInput) => {
          if (!option.data.hidden) {
            return option.data.value.toLowerCase().includes(rawInput.toLowerCase());
          }
          return false;
        }}
        disabled={
          !data.user ||
          data.user?.length === 0 ||
          values.paymentStatus[0]?.value === 'paid' ||
          values.paymentStatus[0]?.value === 'stripe_payment' ||
          data.status === 'impossible_to_valuation' ||
          data?.user[0].suspended ||
          !data?.user[0].activate
        }
      />
      <Field
        name="orderStatus"
        className={`order-list__select order-list__select--payment-status `}
        required
        component={Select}
        label="Status zlecenia"
        placeholder=""
        options={orderStatus}
        disabled={
          !data.user ||
          data.user.length === 0 ||
          data.user[0].suspended ||
          !data.user[0].activate ||
          data.status === 'impossible_to_valuation' ||
          search === 'completed'
        }
        handleSubmit={handleSubmit}
        filterOption={(option, rawInput) => {
          if (data.status === 'archivied') {
            if (data.savedStatus === option.data.value) {
              return option.data.value.toLowerCase().includes(rawInput.toLowerCase());
            }
          } else if (
            ((data.status === 'impossibleToValuation' || data.status === 'valued') && option.data.color === 'yellow') ||
            option.data.color === 'any'
          ) {
            return option.data.value.toLowerCase().includes(rawInput.toLowerCase());
          } else if (data.status !== 'impossibleToValuation' && data.status !== 'valued' && option.data.color === 'green') {
            return option.data.value.toLowerCase().includes(rawInput.toLowerCase());
          } else {
            return false;
          }
        }}
      />
    </div>
  );
}

export default StatusData;
