import * as LanguageModule from '../../../Modules/LanguagesModule';
import { Languages } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Languages.ADD_LANGUAGE_INIT,
});

const success = (data: any) => ({
  type: Languages.ADD_LANGUAGE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Languages.ADD_LANGUAGE_FAILURE,
});

const addLanguage = (formData) => async (dispatch) => {
  const dataToSend = {
    ...formData,
  };
  dataToSend.initialFiles = undefined;
  dataToSend.initialLanguage = undefined;
  dispatch(init());
  const id = toast.loading('Trwa dodawanie języka...');
  try {
    const data = await LanguageModule.Connector.addLanguage(dataToSend);
    dispatch(success(data));
    toast.update(id, {
      render: 'Pomyślnie dodano język',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} />,
    });

    return data.data.language;
  } catch (err: any) {
    dispatch(failure());
    const error = err.response.data.errors[0];
    if (error && error.field === 'language' && error.rule === 'unique') {
      toast.update(id, {
        render: 'Język o takiej nazwie już istnieje',
        type: toast.TYPE.ERROR,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.CIRCLE_X} />,
      });
    } else {
      toast.update(id, {
        render: 'Nie udało się dodać języka',
        type: toast.TYPE.ERROR,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.CIRCLE_X} />,
      });
      return false;
    }
  }
};

export default addLanguage;
