import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

const getOrderStore = (state: RootState) => state.orderStore;
const orderUuidSelector = createSelector(getOrderStore, (state) => state.currentOrderUuid);
const currentOrderSelector = createSelector(getOrderStore, (state) => state.currentOrder);
const initialOrderSelector = createSelector(getOrderStore, (state) => state.initialOrder);
const ordersListSelector = createSelector(getOrderStore, (state) => state.ordersList);
const companyInfoFromNip = createSelector(getOrderStore, (state) => state.companyDataFromNip);
const invoiceSelector = createSelector(getOrderStore, (state) => state.invoice);

export { orderUuidSelector, currentOrderSelector, initialOrderSelector, ordersListSelector, companyInfoFromNip, invoiceSelector };
