import * as OrderModule from '../../../Modules/OrderModule';
import { Order } from '../EnumTypes/ActionEnumTypes';
// import * as OrderActionModel from '../Models/OrderActionModel';

const init = () => ({
  type: Order.CONFIRM_ORDER_INIT,
});

const success = (data: any) => ({
  type: Order.CONFIRM_ORDER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Order.CONFIRM_ORDER_FAILURE,
});

const confirmOrder = (orderForm: any, uuid: string) => async (dispatch) => {
  const dataToSend = { ...orderForm };
  dataToSend.specialization = dataToSend.specialization?.length ? dataToSend.specialization[0].value : '';
  dataToSend.translateFrom = undefined;
  dataToSend.files = undefined;
  dataToSend?.priorities ? null : (dataToSend.priorities = 'standard');
  //remove duplicates
  dataToSend.translationsUuids = dataToSend.translateTo
    .map((item) => item.translationUuid)
    .filter((item, index, self) => self.indexOf(item) === index);
  dataToSend.translateTo = undefined;
  dataToSend?.translationType ? null : (dataToSend.translationType = 'regular');
  dataToSend.quality = dataToSend?.translationType === 'certified' ? 'basic' : dataToSend?.quality;
  dispatch(init());
  try {
    const data = await OrderModule.Connector.confirmOrder({ dataToSend, uuid });
    dispatch(success(data));
    //   dispatch(ViewManagementModule.Actions.hideLoader());
    return data.data;
  } catch (err: any) {
    //   dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
    return false;
    // toast.error('Niepoprawne dane logowania');
  }
};

export default confirmOrder;
