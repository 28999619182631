import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';
import * as OrderModule from 'Modules/OrderModule';

export function addOrderFile({ files, languageUuid, orderUuid }): AxiosPromise<OrderModule.Types.OrderForm> {
  return API.post('files/order', files, {
    params: { languageUuid, orderUuid: orderUuid?.uuid || orderUuid || undefined, newOrder: !orderUuid && !languageUuid ? true : undefined },
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function addTranslatedFile({ files, orderUuid }): AxiosPromise<OrderModule.Types.OrderForm> {
  return API.post('files/translated-file', files, {
    params: { orderUuid },
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function addLanguageFlag({ formData, languageUuid }): AxiosPromise<OrderModule.Types.OrderForm> {
  return API.post('files/language', formData, {
    params: { languageUuid },
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function deleteFile({ uuid }): AxiosPromise<OrderModule.Types.OrderForm> {
  return API.delete(`files/${uuid}`);
}

export function downloadAllFiles(uuid): AxiosPromise<OrderModule.Types.OrderForm> {
  return API.get(`files/zip/${uuid}`, { responseType: 'blob' });
}

export function downloadAllTranslations(uuid): AxiosPromise<OrderModule.Types.OrderForm> {
  return API.get(`files/zip/translated-file/${uuid}`, { responseType: 'blob' });
}
