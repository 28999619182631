import SVG from 'Components/SVG';
import { SelectOption } from 'Shared/models/SelectOptionsModel';
import Select, { components } from 'react-select';
import { FieldProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { SVG_TYPE } from 'Shared/enums';

interface SelectProps {
  label?: string;
  options: SelectOption[];
  placeholder: string;
  name: string;

  isMulti?: boolean;
  svgType?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  initialValue?: SelectOption;
  addMore?: boolean;
  addMoreOnClick?: () => void;
  onInputChange?: (value) => void;
  isSearchable?: boolean;
  changeOnlyFirstElement?: boolean;
  openMenuOnClick?: boolean;
  id?: string;
  menuIsOpen?: boolean;
  isClearable?: boolean;
  outSideValueChange?: (value) => void;
  handleSubmit?: () => void;
  filterOption?: any;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;

  //formik
  field?: any;
  errors?: any;
  form?: any;
  touched?: any;
  onChange?: (value) => void;
  handleOnChange?: any;
  isOnBlurAction?: boolean;
  editUser?: (data) => void;
  onFocus?: any;
}

function SelectComponent({
  field,
  form,
  errors,
  label,
  className,
  disabled,
  name,
  options,
  placeholder,
  required,
  isMulti,
  initialValue,
  addMore,
  onInputChange,
  isSearchable = true,
  addMoreOnClick,
  changeOnlyFirstElement,
  openMenuOnClick,
  menuIsOpen,
  id,
  isClearable = false,
  outSideValueChange,
  handleSubmit,
  filterOption,
  onMenuOpen,
  onMenuClose,
}: SelectProps & FieldProps) {
  const [value, setValue] = useState([]);
  const maxLimit = 20;
  const selectInputRef = useRef<any>();

  const handleChange = (newValue) => {
    const tempArray = [];
    if (Array.isArray(newValue) && isMulti) {
      newValue.map((item) => {
        tempArray.push(item.value);
      });
    } else {
      newValue?.value && tempArray.push(newValue?.value);
    }
    if (changeOnlyFirstElement) {
      setValue([newValue[newValue.length - 1]]);
      form.setFieldValue(field.name, [newValue[newValue.length - 1]]);
    } else {
      setValue(options.filter((option) => tempArray.includes(option.value)));
      if (tempArray.length) {
        form.setFieldValue(
          field.name,
          options.filter((option) => tempArray.includes(option.value)),
        );
      } else {
        form.setFieldValue(field.name, []);
      }
      outSideValueChange && outSideValueChange({ [field.name]: options.filter((option) => tempArray.includes(option.value)) });
      handleSubmit && handleSubmit();
    }
  };

  useEffect(() => {
    const tempArray = [];
    if (options.length) {
      if (Array.isArray(field.value)) {
        field.value?.map((item) => {
          tempArray.push(item?.value);
        });
      } else {
        tempArray.push(field.value);
      }
      const filteredValue = options.filter((option) => tempArray.includes(option.value));
      setValue(filteredValue);
    }
  }, [field.value, options]);

  const ClearIndicator = (props) => (
    <components.ClearIndicator {...props}>
      <SVG type={SVG_TYPE.CLOSE_SELECT} />
    </components.ClearIndicator>
  );

  const isAlone = isMulti && value.length === 1;
  return (
    <div className={`select__wrapper ${disabled ? 'select__wrapper--disabled' : ''}`}>
      <>
        <label className={`select__label ${!required ? 'select__label--optional' : ''}`} htmlFor={field.name}>
          {label} {!required && <span className="select__optional">(opcjonalne)</span>}
          {addMore ? (
            <span className="select__add-more" onClick={addMoreOnClick}>
              + dodaj kolejny
            </span>
          ) : null}
        </label>
        <Select
          {...field}
          options={options}
          ref={selectInputRef}
          placeholder={placeholder}
          isDisabled={disabled}
          name={name}
          className={`select ${className ? className : ''} ${isAlone ? 'select--alone' : ''} ${
            isClearable && value.length ? 'select--clearable' : ''
          } ${form.touched && errors && errors[field?.name] && form.touched[field?.name] ? 'select--error' : ''} `}
          isClearable={isClearable}
          classNamePrefix={'select'}
          required={required}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          onChange={handleChange}
          value={isMulti ? value || '' : value[0] || ''}
          isMulti={isMulti}
          id={id}
          key={field.name}
          menuIsOpen={menuIsOpen}
          openMenuOnClick={openMenuOnClick}
          defaultValue={initialValue}
          noOptionsMessage={() => 'Brak wyników'}
          onInputChange={onInputChange}
          isOptionDisabled={() => (isMulti ? field.value?.length >= maxLimit : false)}
          isSearchable={isSearchable}
          menuPlacement="auto"
          components={{ ClearIndicator }}
          filterOption={filterOption}
          getOptionLabel={(props) => {
            const { label, svgType, flag, files }: any = props;
            return (
              <div className="select__option-container">
                {svgType && <SVG type={svgType} />}
                {flag?.url && <img src={flag?.url} className="select__icon" alt={label} />}
                {files?.url && <img src={files?.url} className="select__icon" alt={label} />}
                <span>{label}</span>
              </div>
            ) as unknown as string;
          }}
        />
        {errors && form.touched && <div className="input__error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>}
      </>
    </div>
  );
}

export default SelectComponent;
