import * as Types from 'Shared/models/UserModel';
import { User } from '../Actions/EnumTypes/ActionEnumTypes';

export type State = Types.UserStore;

export const initialState: State = {
  usersList: [],
  usersLabel: [],
};

export function userReducer(state: any = initialState, action) {
  switch (action.type) {
    case User.GET_USERS_INIT: {
      return {
        ...state,
      };
    }
    case User.GET_USERS_SUCCESS: {
      return {
        ...state,
        usersList: action.payload,
      };
    }
    case User.GET_USERS_FAILURE: {
      return {
        ...state,
      };
    }
    case User.GET_USERS_LABEL_INIT: {
      return {
        ...state,
      };
    }
    case User.GET_USERS_LABEL_SUCCESS: {
      return {
        ...state,
        usersLabel: action.payload.data,
      };
    }
    case User.GET_USERS_LABEL_FAILURE: {
      return {
        ...state,
      };
    }
    case User.RESENT_LINK_INIT: {
      return {
        ...state,
      };
    }
    case User.RESENT_LINK_SUCCESS: {
      return {
        ...state,
      };
    }
    case User.RESENT_LINK_FAILURE: {
      return {
        ...state,
      };
    }
      
    default: {
      return state;
    }
  }
}
