import * as OrderModule from '../../../Modules/OrderModule';
import { Order } from '../EnumTypes/ActionEnumTypes';
import { SVG_TYPE } from 'Shared/enums';
import SVG from 'Components/SVG';
import { toast } from 'react-toastify';

const init = () => ({
  type: Order.EDIT_ORDER_INIT,
});

const success = (data: any) => ({
  type: Order.EDIT_ORDER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Order.EDIT_ORDER_FAILURE,
});

interface EditOrderProps {
  priorities: string;
  quality: string;
  specialization: string;
  signCount: number;
  orderStatus: [any];
  status: string;
  paymentStatus: [any];
  price: number;
}
const editOrder = (orderUuid: string, orderForm: EditOrderProps) => async (dispatch) => {
  const dataToSend = { ...orderForm };
  dataToSend.status = Array.isArray(dataToSend.orderStatus) ? dataToSend.orderStatus[0].value : dataToSend.status;
  dataToSend.paymentStatus = Array.isArray(dataToSend.paymentStatus) ? dataToSend.paymentStatus[0].value : dataToSend.paymentStatus;
  // dataToSend.signCount = undefined;p
  dataToSend.orderStatus = undefined;
  // dataToSend.price = undefined;
  dispatch(init());
  try {
    const data:any = await OrderModule.Connector.editOrder(orderUuid, dataToSend);
    dispatch(success(data));
    toast.success('Pomyślnie edytowano zgłoszenie', { icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} /> });
    return data.data.Order;
  } catch (err: any) {
    toast.error('Nie udało się edytować zgłoszenia', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
    dispatch(failure());
    return false;
  }
};

export default editOrder;
