import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

const getLanguagesStore = (state: RootState) => state.languagesStore;
const LanguagesSelector = createSelector(getLanguagesStore, (state) => state.languages);
const TranslationsSelector = createSelector(getLanguagesStore, (state) => state.translations);
const TranslationsListSelector = createSelector(getLanguagesStore, (state) => state.translationsList);
const LanguagesListSelector = createSelector(getLanguagesStore, (state) => state.languagesList);

export { LanguagesSelector, TranslationsSelector, TranslationsListSelector, LanguagesListSelector };
