import Button from 'Components/Button';
import SVG from 'Components/SVG';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { MainType } from './Container';

function Page404({ actions }: MainType) {
  const { clearOrders } = actions;
  const naviage = useNavigate();

  useEffect(() => {
    return () => {
      clearOrders();
    };
  }, []);

  return (
    <div className="page page404">
      <div className="page404__wrapper">
        <h1 className="page404__header">Błąd 404</h1>
        <p className="page404__message">
          Przepraszamy, ale strona, której szukasz, nie została znaleziona. Może to oznaczać, że adres URL, który wprowadziłeś jest błędny lub strona
          została usunięta.
        </p>
        <Button label="Wróć do widoku początkowego" round onClick={() => naviage(ROUTES.HOME_PAGE)} />
      </div>
      <SVG type={SVG_TYPE.PAGE404} className="page404__svg page404__svg--desktop" />
    </div>
  );
}

export default Page404;
