import * as LanguageModule from '../../../Modules/LanguagesModule';
import { Languages } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Languages.ADD_TRANSLATION_INIT,
});

const success = (data: any) => ({
  type: Languages.ADD_TRANSLATION_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Languages.ADD_TRANSLATION_FAILURE,
});

const addTranslation = (formData) => async (dispatch) => {
  const dataToSend = {
    ...formData,
  };
  dataToSend.translationFromLanguageUuid = dataToSend.translationFromLanguageUuid[0].value;
  dataToSend.translationIntoLanguageUuid = dataToSend.translationIntoLanguageUuid[0].value;
  dispatch(init());
  try {
    const data = await LanguageModule.Connector.addTranslation(dataToSend);
    dispatch(success(data));
    toast.success('Pomyślnie dodano tłumaczenie', { icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} /> });
    return true;
  } catch (err: any) {
    dispatch(failure());
    if (err.response.data === 'TRANSLATION_ALREADY_EXIST: Translation already exist') {
      toast.error('Tłumaczenie już istnieje', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
    } else {
      toast.error('Nie udało się dodać tłumaczenia', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
    }
    return false;
  }
};

export default addTranslation;
