import * as FilesModule from '../../../Modules/FilesModule';
import { Files } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Files.DELETE_FILE_INIT,
});

const success = (data: any) => ({
  type: Files.DELETE_FILE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Files.DELETE_FILE_FAILURE,
});

const deleteFile =
  ({ uuid }) =>
  async (dispatch) => {
    dispatch(init());
    try {
      const data = await FilesModule.Connector.deleteFile({ uuid });
      dispatch(success(data));
      toast.success('Pomyślnie usunięto plik', { icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} /> });
      return true
    } catch (err: any) {
      dispatch(failure());
      toast.error('Nie udało się usunąć pliku', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
      return false;
    }
  };

export default deleteFile;
