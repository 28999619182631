import Button from 'Components/Button';
import { Input } from 'Components/form';
import Modal from 'Components/Modal';
import { Field, Formik } from 'formik';
import { useEffect, useRef } from 'react';
import { VALIDATION_MESSAGES } from 'Shared/enums';
import * as yup from 'yup';

interface EditTranslationModalProps {
  isOpen: boolean;
  onClose?: () => void;
  addTranslation: (data: any) => any;
  edit: boolean;
  getTranslationList?: () => void;
  data?: {
    translationFromLanguageUuid: string;
    translationIntoLanguageUuid: string;
    pricePerSign: string;
    pricePerSignCertifiedTranslation: string;
    uuid: string;
    translationInto: {
      uuid: string;
    };
    translationFrom: {
      uuid: string;
    };
    active: boolean;
  };
  editTranslation?: (userUuid: string, data: { firstName: string; lastName: string; email: string; role: string }) => any;
  getTranslationsList?: (page, filters) => void;
  currentPage: number;
  selectedFilters: any;
  languages: any;
  filterOption: (input, option) => boolean;
}

function EditTranslationModal({
  isOpen,
  onClose,
  edit,
  data,
  addTranslation,
  editTranslation,
  getTranslationsList,
  currentPage,
  selectedFilters,
}: EditTranslationModalProps) {
  const formRef = useRef(null);
  const validationSchema = yup.object().shape({
    pricePerSign: yup
      .string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(0.0001, VALIDATION_MESSAGES.MIN_0)
      .test('is-digit1', VALIDATION_MESSAGES.DIGITS, (value: any) => {
        return value && value.match(/^\d+(\.\d{1,2})?$/);
      })
      .test('max-100', VALIDATION_MESSAGES.MAX_999, (value: any) => {
        return value && Number(value) <= 999;
      }),
    pricePerSignCertifiedTranslation: yup
      .string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(0.0001, VALIDATION_MESSAGES.MIN_0)
      .test('is-digit', VALIDATION_MESSAGES.DIGITS, (value: any) => {
        return value && value.match(/^\d+(\.\d{1,2})?$/);
      })
      .test('max-100', VALIDATION_MESSAGES.MAX_999, (value: any) => {
        return value && Number(value) <= 999;
      }),
  });

  useEffect(() => {
    isOpen && formRef.current.resetForm();
  }, [isOpen]);

  const handleOnSubmit = async (values: any) => {
    const valuesCopy = { ...values };
    const result = edit ? await editTranslation(data.uuid, { ...valuesCopy, active: !!data.active }) : await addTranslation(valuesCopy);
    if (result == true) {
      getTranslationsList(currentPage, selectedFilters);
      onClose();
      formRef.current.resetForm();
    } else {
      formRef.current.setErrors({ email: VALIDATION_MESSAGES.EMAIL_TAKEN });
    }
  };
  const initialValues = {
    translationIntoLanguageUuid: data?.translationInto?.uuid || '',
    translationFromLanguageUuid: data?.translationFrom?.uuid || '',
    pricePerSign: data?.pricePerSign || '',
    pricePerSignCertifiedTranslation: data?.pricePerSignCertifiedTranslation || '',
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="add-user-modal__header">{edit ? 'Edycja tłumaczenia' : 'Dodawanie tłumaczenia'}</h2>
      <div className="add-user-modal__wrapper">
        <Formik
          validationSchema={validationSchema}
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values) => handleOnSubmit(values)}>
          {({ handleSubmit, errors }) => (
            <form onSubmit={handleSubmit} className="add-user-modal__form">
              <div className="add-user-modal__form-row">
                <Field
                  label="Stawka za tłum. zwykłe"
                  name="pricePerSign"
                  required
                  component={Input}
                  errors={errors}
                  treshold={0}
                  type="number"
                  inputmode="decimal"
                  className="add-translation-modal__input"
                  maxLength={5}
                />
                <Field
                  className="add-translation-modal__input"
                  label="Stawka za tłum. przysięgłe"
                  name="pricePerSignCertifiedTranslation"
                  required
                  component={Input}
                  errors={errors}
                  inputmode="decimal"
                  treshold={0}
                  type="number"
                  maxLength={5}
                />
              </div>
              <div className="add-user-modal__form-row add-translation-modal__buttons">
                <Button label="Anuluj" className="add-user-modal__add-button" cancel type="button" onClick={() => onClose()} round stroke />
                <Button label="Zapisz" className="add-user-modal__add-button" type="submit" onClick={() => null} round />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default EditTranslationModal;
