export enum Auth {
  LOG_IN_INIT = 'LOG_IN_INIT',
  LOG_IN_SUCCESS = 'LOG_IN_SUCCESS',
  LOG_IN_FAILURE = 'LOG_IN_FAILURE',
  LOG_OUT_INIT = 'LOG_OUT_INIT',
  LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS',
  LOG_OUT_FAILURE = 'LOG_OUT_FAILURE',
  GET_AUTHORIZED_USER_INIT = 'GET_AUTHORIZED_USER_INIT',
  GET_AUTHORIZED_USER_SUCCESS = 'GET_AUTHORIZED_USER_SUCCESS',
  GET_AUTHORIZED_USER_FAILURE = 'GET_AUTHORIZED_USER_FAILURE',
  SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN',
  SEND_LINK_INIT = 'SEND_LINK_INIT',
  SEND_LINK_SUCCESS = 'SEND_LINK_SUCCESS',
  SEND_LINK_FAILURE = 'SEND_LINK_FAILURE',
  GET_REFRESH_TOKEN_INIT = 'GET_REFRESH_TOKEN_INIT',
  GET_REFRESH_TOKEN_SUCCESS = 'GET_REFRESH_TOKEN_SUCCESS',
  GET_REFRESH_TOKEN_FAILURE = 'GET_REFRESH_TOKEN_FAILURE',
  ADD_NEW_DEVICE_INIT = 'ADD_NEW_DEVICE_INIT',
  ADD_NEW_DEVICE_SUCCESS = 'ADD_NEW_DEVICE_SUCCESS',
  ADD_NEW_DEVICE_FAILURE = 'ADD_NEW_DEVICE_FAILURE',

  RESET_PASSWORD_INIT = 'RESET_PASSWORD_INIT',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE',

  GET_RESET_PASSWORD_TOKEN_INIT = 'GET_RESET_PASSWORD_TOKEN_INIT',
  GET_RESET_PASSWORD_TOKEN_SUCCESS = 'GET_RESET_PASSWORD_TOKEN_SUCCESS',
  GET_RESET_PASSWORD_TOKEN_FAILURE = 'GET_RESET_PASSWORD_TOKEN_FAILURE',
}

export enum ViewManagement {
  SHOW_LOADER = 'SHOW_LOADER',
  HIDE_LOADER = 'HIDE_LOADER',
  SET_PREV_PATH = 'SET_PREV_PATH',
  EXPAND_MENU = 'EXPAND_MENU',
  EXPAND_ADD_REMINDER_VIEW = 'EXPAND_ADD_REMINDER_VIEW',
  OPEN_COMPENDIUM_PREVIEW_MOBILE = 'OPEN_COMPENDIUM_PREVIEW_MOBILE',
  CLOSE_COMPENDIUM_PREVIEW_MOBILE = 'CLOSE_COMPENDIUM_PREVIEW_MOBILE',
  SET_IS_NAVIGATION_HIDDEN = 'SET_IS_NAVIGATION_HIDDEN',
  SET_IS_COOKIE_MODAL_OPEN = 'SET_IS_COOKIE_MODAL_OPEN',
}

export enum User {
  GET_USERS_INIT = 'GET_USERS_INIT',
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE = 'GET_USERS_FAILURE',

  GET_USERS_LABEL_INIT = 'GET_USERS_LABEL_INIT',
  GET_USERS_LABEL_SUCCESS = 'GET_USERS_LABEL_SUCCESS',
  GET_USERS_LABEL_FAILURE = 'GET_USERS_LABEL_FAILURE',

  ADD_USER_INIT = 'ADD_USER_INIT',
  ADD_USER_SUCCESS = 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE = 'ADD_USER_FAILURE',

  EDIT_USER_INIT = 'EDIT_USER_INIT',
  EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE = 'EDIT_USER_FAILURE',

  CHANGE_SUSPENDED_STATUS_INIT = 'CHANGE_SUSPENDED_STATUS_INIT',
  CHANGE_SUSPENDED_STATUS_SUCCESS = 'CHANGE_SUSPENDED_STATUS_SUCCESS',
  CHANGE_SUSPENDED_STATUS_FAILURE = 'CHANGE_SUSPENDED_STATUS_FAILURE',

  RESENT_LINK_INIT = 'RESENT_LINK_INIT',
  RESENT_LINK_SUCCESS = 'RESENT_LINK_SUCCESS',
  RESENT_LINK_FAILURE = 'RESENT_LINK_FAILURE',
}

export enum Order {
  CREATE_ORDER_INIT = 'CREATE_ORDER_INIT',
  CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE',

  CONFIRM_ORDER_INIT = 'CONFIRM_ORDER_INIT',
  CONFIRM_ORDER_SUCCESS = 'CONFIRM_ORDER_SUCCESS',
  CONFIRM_ORDER_FAILURE = 'CONFIRM_ORDER_FAILURE',

  CHANGE_CURRENT_ORDER_INIT = 'CHANGE_CURRENT_ORDER_INIT',
  CHANGE_CURRENT_ORDER_SUCCESS = 'CHANGE_CURRENT_ORDER_SUCCESS',
  CHANGE_CURRENT_ORDER_FAILURE = 'CHANGE_CURRENT_ORDER_FAILURE',

  GET_ORDER_INIT = 'GET_ORDER_INIT',
  GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS',
  GET_ORDER_FAILURE = 'GET_ORDER_FAILURE',

  GET_ORDERS_LIST_INIT = 'GET_ORDERS_LIST_INIT',
  GET_ORDERS_LIST_SUCCESS = 'GET_ORDERS_LIST_SUCCESS',
  GET_ORDERS_LIST_FAILURE = 'GET_ORDERS_LIST_FAILURE',

  POST_ORDER_CONTACT_DATA_INIT = 'POST_ORDER_CONTACT_DATA_INIT',
  POST_ORDER_CONTACT_DATA_SUCCESS = 'POST_ORDER_CONTACT_DATA_SUCCESS',
  POST_ORDER_CONTACT_DATA_FAILURE = 'POST_ORDER_CONTACT_DATA_FAILURE',

  ASSING_EMPLOYEE_INIT = 'ASSING_EMPLOYEE_INIT',
  ASSING_EMPLOYEE_SUCCESS = 'ASSING_EMPLOYEE_SUCCESS',
  ASSING_EMPLOYEE_FAILURE = 'ASSING_EMPLOYEE_FAILURE',

  EDIT_ORDER_INIT = 'EDIT_ORDER_INIT',
  EDIT_ORDER_SUCCESS = 'EDIT_ORDER_SUCCESS',
  EDIT_ORDER_FAILURE = 'EDIT_ORDER_FAILURE',

  CLEAR_STORE = 'CLEAR_STORE',

  CLEAR_INVOICE = 'CLEAR_INVOICE',

  CREATE_PAYMENT_INIT = 'CREATE_PAYMENT_INIT',
  CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS',
  CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE',

  GET_COMPANY_DATA_FROM_NIP_INIT = 'GET_COMPANY_DATA_FROM_NIP_INIT',
  GET_COMPANY_DATA_FROM_NIP_SUCCESS = 'GET_COMPANY_DATA_FROM_NIP_SUCCESS',
  GET_COMPANY_DATA_FROM_NIP_FAILURE = 'GET_COMPANY_DATA_FROM_NIP_FAILURE',

  GET_INVOICE_INIT = 'GET_INVOICE_INIT',
  GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS',
  GET_INVOICE_FAILURE = 'GET_INVOICE_FAILURE',
}

export enum Languages {
  GET_LANGUAGES_INIT = 'GET_LANGUAGES_INIT',
  GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS',
  GET_LANGUAGES_FAILURE = 'GET_LANGUAGES_FAILURE',

  GET_LANGUAGES_LIST_INIT = 'GET_LANGUAGES_LIST_INIT',
  GET_LANGUAGES_LIST_SUCCESS = 'GET_LANGUAGES_LIST_SUCCESS',
  GET_LANGUAGES_LIST_FAILURE = 'GET_LANGUAGES_LIST_FAILURE',

  GET_TRANSLATIONS_INIT = 'GET_TRANSLATIONS_INIT',
  GET_TRANSLATION_SUCCESS = 'GET_TRANSLATIONS_SUCCESS',
  GET_TRANSLATION_FAILURE = 'GET_TRANSLATIONS_FAILURE',

  GET_TRANSLATIONS_LIST_INIT = 'GET_TRANSLATIONS_LIST_INIT',
  GET_TRANSLATIONS_LIST_SUCCESS = 'GET_TRANSLATIONS_LIST_SUCCESS',
  GET_TRANSLATIONS_LIST_FAILURE = 'GET_TRANSLATIONS_LIST_FAILURE',

  ADD_TRANSLATION_INIT = 'ADD_TRANSLATION_INIT',
  ADD_TRANSLATION_SUCCESS = 'ADD_TRANSLATION_SUCCESS',
  ADD_TRANSLATION_FAILURE = 'ADD_TRANSLATION_FAILURE',

  EDIT_TRANSLATION_INIT = 'EDIT_TRANSLATION_INIT',
  EDIT_TRANSLATION_SUCCESS = 'EDIT_TRANSLATION_SUCCESS',
  EDIT_TRANSLATION_FAILURE = 'EDIT_TRANSLATION_FAILURE',

  ADD_LANGUAGE_INIT = 'ADD_LANGUAGE_INIT',
  ADD_LANGUAGE_SUCCESS = 'ADD_LANGUAGE_SUCCESS',
  ADD_LANGUAGE_FAILURE = 'ADD_LANGUAGE_FAILURE',

  EDIT_LANGUAGE_INIT = 'EDIT_LANGUAGE_INIT',
  EDIT_LANGUAGE_SUCCESS = 'EDIT_LANGUAGE_SUCCESS',
  EDIT_LANGUAGE_FAILURE = 'EDIT_LANGUAGE_FAILURE',

  DELETE_LANGUAGE_INIT = 'DELETE_LANGUAGE_INIT',
  DELETE_LANGUAGE_SUCCESS = 'DELETE_LANGUAGE_SUCCESS',
  DELETE_LANGUAGE_FAILURE = 'DELETE_LANGUAGE_FAILURE',
}

export enum Files {
  ADD_ORDER_FILE_INIT = 'ADD_ORDER_FILE_INIT',
  ADD_ORDER_FILE_SUCCESS = 'ADD_ORDER_FILE_SUCCESS',
  ADD_ORDER_FILE_FAILURE = 'ADD_ORDER_FILE_FAILURE',

  DELETE_FILE_INIT = 'DELETE_FILE_INIT',
  DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS',
  DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE',

  DOWNLOAD_ALL_FILES_INIT = 'DOWNLOAD_ALL_FILES_INIT',
  DOWNLOAD_ALL_FILES_SUCCESS = 'DOWNLOAD_ALL_FILES_SUCCESS',
  DOWNLOAD_ALL_FILES_FAILURE = 'DOWNLOAD_ALL_FILES_FAILURE',

  ADD_LANGUAGE_FLAG_INIT = 'ADD_LANGUAGE_FLAG_INIT',
  ADD_LANGUAGE_FLAG_SUCCESS = 'ADD_LANGUAGE_FLAG_SUCCESS',
  ADD_LANGUAGE_FLAG_FAILURE = 'ADD_LANGUAGE_FLAG_FAILURE',

  ADD_TRANSLATED_FILE_INIT = 'ADD_TRANSLATED_FILE_INIT',
  ADD_TRANSLATED_FILE_SUCCESS = 'ADD_TRANSLATED_FILE_SUCCESS',
  ADD_TRANSLATED_FILE_FAILURE = 'ADD_TRANSLATED_FILE_FAILURE',
}
