import Button from 'Components/Button';
import { Select } from 'Components/form';
import { Field } from 'formik';

interface FiltersProps {
  languages: Array<any>;
  handleValueChange: (values: any) => void;
  isFilersOpen: boolean;
  setIsFilersOpen: (value: boolean) => void;
  selectedFilters: { languageFromUuid: string; languageIntoUuid: string; search: string };
  setSelectedFilters: (value: { languageFromUuid: string; languageIntoUuid: string; search: string }) => void;
  setValues: (value: any) => void;
  optionsLanguagesFrom: any;
  optionsLanguagesTo: any;
}

function Filters({
  handleValueChange,
  setIsFilersOpen,
  isFilersOpen,
  selectedFilters,
  setSelectedFilters,
  setValues,
  optionsLanguagesFrom,
  optionsLanguagesTo,
}: FiltersProps) {
  const handleCancel = () => {
    setSelectedFilters({ languageFromUuid: '', languageIntoUuid: '', search: '' });
    handleValueChange({ languageFromUuid: [], languageIntoUuid: [], search: '' });
    setValues({ languageFromUuid: [], languageIntoUuid: [], search: '' });
    setIsFilersOpen(false);
  };

  const handleApply = () => {
    setIsFilersOpen(false);
  };

  const filterOption = (option, inputValue) => {
    return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <>
      <span onClick={() => setIsFilersOpen(false)} className={`filters__backdrop ${isFilersOpen ? 'filters__backdrop--show' : ''}`} />
      <div className={`filters ${isFilersOpen ? 'filters--open' : ''}`}>
        <h2 className="filters__header">Filtrowanie</h2>

        <Field
          component={Select}
          options={optionsLanguagesFrom}
          placeholder="Wybierz język"
          name="languageFromUuid"
          label="Tłumaczenie z"
          filterOption={(option, inputValue) => filterOption(option, inputValue)}
          required
          outSideValueChange={(values) => handleValueChange(values)}
        />
        <Field
          component={Select}
          options={optionsLanguagesTo}
          placeholder="Wybierz język"
          name="languageIntoUuid"
          label="Tłumaczenie na"
          filterOption={(option, inputValue) => filterOption(option, inputValue)}
          required
          outSideValueChange={(values) => handleValueChange(values)}
        />

        <div className="filters__buttons">
          <Button
            label="Usuń filtry"
            className="filters__button filters__button--cancel"
            disabled={!selectedFilters.languageFromUuid && !selectedFilters.languageIntoUuid}
            stroke
            round
            onClick={() => handleCancel()}
          />
          <Button label="Filtruj" className="filters__button filters__button--apply" round onClick={() => handleApply()} />
        </div>
      </div>
    </>
  );
}

export default Filters;
