type CookieBannerProps = {
  settingsClick: () => void;
  showBanner: boolean;
  onAccept: () => void;
};

const CookieBanner = ({ settingsClick, showBanner, onAccept }: CookieBannerProps) => {
  const onMoreInfo = () => {
    settingsClick();
  };

  return (
    <div className={`cookie-banner ${showBanner ? 'cookie-banner--show' : ''}`}>
      <p className="cookie-banner__title">
        Wskaż proszę czy nasza strona może używać cookies / innych podobnych technologii („cookies”). Możesz dowiedzieć się więcej w jaki sposób
        używamy plików cookies na tej stronie zapoznając się z naszą polityką cookies. W każdej chwili możesz zmienić ustawienia dotyczące plików
        cookies, usuwając je ze swojej przeglądarki, jak i blokując możliwość ich instalacji. Aby dowiedzieć się jak tego dokonać - skorzystaj z okna
        Pomocy dostępnego w Twojej przeglądarce. <br />
        <br />
        Więcej informacji na temat plików cookies znajdziesz w naszej{' '}
        <a href="https://highline.com.pl/polityka_prywatnosci.pdf" rel="noreferrer" target="_blank">
          {' '}
          Polityce Prywatności
        </a>
      </p>
      <div className="cookie-banner__buttons">
        <button onClick={onAccept}>Akceptuj wszystkie</button>
        <button onClick={onMoreInfo}>Ustawienia</button>
      </div>
    </div>
  );
};

export default CookieBanner;
