import { connect } from 'react-redux';
import Navigation from './Navigation';
import { Actions as AuthActions } from 'Modules/AuthModule';
import { bindActionCreators } from 'redux';
import { Actions as viewManagmentModule } from 'Modules/ViewManagementModule';
import { isCookieModalOpen, isNaviagtionHidden } from 'Store/Selectors/ViewManagementSelector';
import { authorizedUser } from 'Store/Selectors/AuthSelector';

type ConnectedP = {
  isNavigationHidden: boolean;
  authorizedUser: any;
  isCookieModalOpen: boolean;
};

type DispatchedP = {
  actions: {
    login: (email: string, password: string) => void;
    logout: () => void;
    setIsNaviagtionHidden: (boolean) => void;
    getAuthorizedUser: () => void;
    setIsCookieModalOpen: (boolean) => void;
  };
};
const mapStateToProps = (state) => ({
  isNavigationHidden: isNaviagtionHidden(state),
  authorizedUser: authorizedUser(state),
  isCookieModalOpen: isCookieModalOpen(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      login: AuthActions.login,
      getAuthorizedUser: AuthActions.getAuthorizedUser,
      setIsNaviagtionHidden: viewManagmentModule.setIsNaviagtionHidden,
      logout: AuthActions.logout,
      setIsCookieModalOpen: viewManagmentModule.setIsCookieModalOpen,
    },
    dispatch,
  ),
});
export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
