import { Input } from 'Components/form';
import SVG from 'Components/SVG';
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import { SVG_TYPE } from 'Shared/enums';

function IncorrectFilesForm({ values, setFieldValue, currentOrder, setFormValues, currentStep, errors }: any) {
  const [selectedService, setSelectedService] = useState(undefined);
  const sliderWrapper = document.querySelector('.service-levels__wrapper');
  const orderQualityPrices = currentOrder?.orderQualityPrices;
  const isExpertAvailable = !!values?.specialization?.length && !!orderQualityPrices?.expert;

  useEffect(() => {
    setSelectedService(values.quality);
  }, [values?.quality]);

  useEffect(() => {
    if (!isExpertAvailable && selectedService === 'expert') {
      setSelectedService(undefined);
    }
  }, [isExpertAvailable]);

  useEffect(() => {
    if (sliderWrapper) {
      const sliderWidth = sliderWrapper?.clientWidth;
      const sliderScrollWidth = sliderWrapper?.scrollWidth;
      const maxScroll = sliderScrollWidth - sliderWidth;
      const scrollPosition = maxScroll / 2;
      sliderWrapper.scrollTo(scrollPosition, 0);
    }
  }, [sliderWrapper]);

  useEffect(() => {
    if (selectedService) {
      setFieldValue('quality', selectedService);
    }
  }, [selectedService]);

  useEffect(() => {
    setFormValues(values);
  }, [values]);
  return (
    <div className={`container-left__slide container-left__slide--${currentStep}`}>
      <div className="incorrect-files-form">
        <div className="incorrect-files-form__top">
          <div className="incorrect-files-form__top-container">
            <p className="incorrect-files-form__top-text">
              Ups! Naszemu systemowi nie udało się przekonwertować Twojego pliku automatycznie. Ale wszystko jest pod kontrolą!
              <br />
              <br /> Nasi specjaliści już pracują nad Twoim plikiem i prześlą Ci wycenę do max. 30 minut*. Żeby to było możliwe, zostaw nam proszę
              swój adres e-mail i nr telefonu!
            </p>
            <p className="incorrect-files-form__top-subtext">*w godzinach pracy - 8:00-18:00, nie dotyczy weekendów</p>
          </div>
          <div className="incorrect-files-form__top-container">
            <SVG type={SVG_TYPE.WORKING} />
          </div>
        </div>

        <div className="incorrect-files-form__bottom">
          <div className="incorrect-files-form__row">
            <Field
              className={'contact-form__input'}
              label="Email"
              name="email"
              id="email"
              placeholder="Wpisz adres email"
              required
              component={Input}
              errors={errors}
              theshold={500}
            />
            <div className="contact-form__row contact-form__row--splited">
              <Field
                className={'contact-form__input contact-form__input--small'}
                label="Telefon"
                name="phonePrefix"
                id="phonePrefix"
                placeholder="+48"
                required
                component={Input}
                theshold={500}
                errors={errors}
                maxLength={64}
              />
              <Field
                className={'contact-form__input'}
                theshold={500}
                name="phoneNumber"
                id={currentStep === 2 ? 'phoneNumber' : null}
                placeholder="Wpisz nr telefonu"
                required
                component={Input}
                maxLength={9}
                errors={errors}
              />
            </div>
          </div>

          <div className="incorrect-files-form__row">
            <Field
              className={'contact-form__input'}
              theshold={500}
              label="Imię"
              name="firstName"
              placeholder="Wpisz swoje imię"
              component={Input}
              errors={errors}
              maxLength={16}
            />
            <Field
              className={'contact-form__input'}
              label="Nazwisko"
              name="lastName"
              placeholder="Wpisz swoje Nazwisko"
              component={Input}
              theshold={500}
              maxLength={24}
              errors={errors}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncorrectFilesForm;
