import * as AuthModule from 'Modules/AuthModule';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';
import * as AuthActionModel from '../Models/AuthActionModel';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Auth.LOG_IN_INIT,
});

export const success = (authData: AuthModule.Types.LogInResponse): AuthActionModel.LoginSuccess => ({
  type: Auth.LOG_IN_SUCCESS,
  payload: {
    authData,
  },
});

const failure = (): AuthActionModel.LoginFailure => ({
  type: Auth.LOG_IN_FAILURE,
});

const login = (email: string, password: string) => async (dispatch) => {
  dispatch(init());
  try {
    // dispatch(ViewManagementModule.Actions.showLoader());
    const response = await AuthModule.Connector.login(email, password);
    dispatch(success(response.data));
    await dispatch(AuthModule.Actions.getAuthorizedUser());
    // dispatch(ViewManagementModule.Actions.hideLoader());
    toast.success('Pomyślnie zalogowano', { icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} /> });
    return true;
  } catch (err) {
    toast.error('Nie udało się zalogować', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
    // dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
    return false;
  }
};

export default login;
