import Button from 'Components/Button';
import SVG from 'Components/SVG';
import { useNavigate } from 'react-router';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { MainType } from './Container';

function RequestSend(props: MainType) {
  const {} = props;
  const naviage = useNavigate();
  return (
    <div className="page transaction-success">
      <div className="transaction-success__wrapper">
        <h1 className="transaction-success__header">Prośba o wycenę została wysłana</h1>
        <p className="transaction-success__message transaction-success__message--no-margin">Nasi specjaliści już pracują nad Twoim plikiem i prześlą Ci wycenę na maila do max. 30 minut*.</p>
        <p className="transaction-success__description">*w godzinach pracy -  8:00-18:00, nie dotyczy weekendów</p>
        <Button label="Wróć do widoku początkowego" round onClick={() => naviage(ROUTES.HOME_PAGE)} />
      </div>
      <SVG type={SVG_TYPE.TRANSACTION_SUCCESS} className="transaction-success__svg transaction-success__svg--desktop" />
      <SVG type={SVG_TYPE.TRANSACTION_SUCCESS_MOBILE} className="transaction-success__svg transaction-success__svg--mobile" />
    </div>
  );
}

export default RequestSend;
