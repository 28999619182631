import SVG from 'Components/SVG';
import { Field, Formik } from 'formik';
import { ROUTES, SVG_TYPE, VALIDATION_MESSAGES } from 'Shared/enums';
import { MainType } from './Container';
import * as Yup from 'yup';
import { Input } from 'Components/form';
import Button from 'Components/Button';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Back from 'Components/Back';

function LinkExpiredPage(props: MainType) {
  const { actions, authorisedUser } = props;
  const { getResetPasswordToken, setIsNaviagtionHidden, resetPassword, checkToken } = actions;
  const navigate = useNavigate();
  const backToLogin = localStorage.getItem('lastPage') === '/dashboard';
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const formRef = useRef(null);
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');

  const onSubmit = async (values) => {
    if (token) {
      const result = await resetPassword(token, values.newPassword);
      if (result == true) {
        navigate(ROUTES.SUCCESFUL_PASSWORD_RESET);
      } else {
        result.messsages === "You can't set an old password" && formRef.current.setFieldError('newPassword', 'Nie możesz ustawić starego hasła');
      }
    } else {
      getResetPasswordToken(values.email);
      setIsSubmitted(true);
    }
  };

  const validation = () => {
    if (token) {
      return Yup.object().shape({
        newPassword: Yup.string().min(8, VALIDATION_MESSAGES.PASSWORD_LENGTH).required(VALIDATION_MESSAGES.REQUIRED),
        passwordConfirmation: Yup.string()
          .required(VALIDATION_MESSAGES.REQUIRED)
          .oneOf([Yup.ref('newPassword'), null], VALIDATION_MESSAGES.PASSWORD_MATCH),
      });
    } else
      return Yup.object().shape({
        email: Yup.string().email(VALIDATION_MESSAGES.EMAIL).required(VALIDATION_MESSAGES.REQUIRED),
      });
  };

  const checkIfTokenIsValid = async () => {
    const result = await checkToken(token);
    if (!result) {
      navigate({ search: '?variant=reset', pathname: ROUTES.LINK_EXPIRED });
    }
  };

  useEffect(() => {
    if (token) {
      checkIfTokenIsValid();
    }
  }, [token]);

  useEffect(() => {
    setIsNaviagtionHidden(true);
    return () => {
      setIsNaviagtionHidden(false);
    };
  }, []);

  useEffect(() => {
    authorisedUser && (setIsNaviagtionHidden(true), navigate(ROUTES.DASHBOARD_ORDERS));
  }, [authorisedUser]);

  const initialValues = {
    email: '',
  };
  return (
    <div className="page restore-password">
      <span className="restore-password__box"></span>
      <div className="restore-password__wrapper">
        <Back onClick={() => (backToLogin ? navigate(ROUTES.HOME_PAGE) : navigate(-1))} className="restore-password__back" />
        <SVG type={SVG_TYPE.LOGO} />
        <h2 className="restore-password__title">
          {token
            ? 'Utwórz nowe hasło'
            : isSubmitted
            ? 'Jeśli podany przez Ciebie adres mailowy jest zgodny z adresem wprowadzonym przy zakładaniu konta to powinieneś otrzymać wiadomość na Twoją skrzynkę mailową.'
            : 'Nie pamiętasz hasła?'}
        </h2>
        <p className="restore-password__subtitle">
          {token ? null : isSubmitted ? (
            <>
              W celu zresetowania hasła kliknij w przesłany link.
              <br />
              <br />
              Nie otrzymałeś maila?
            </>
          ) : (
            'Wprowadź swój adres e-mail, aby otrzymać instrukcje dotyczące resetowania hasła.'
          )}
        </p>

        <Formik
          isInitialValid={false}
          validationSchema={validation}
          initialValues={initialValues}
          validateOnBlur={false}
          enableReinitialize
          innerRef={formRef}
          onSubmit={async (values) => onSubmit(values)}>
          {({ handleSubmit, errors, isSubmitting }) => (
            <form className="restore-password__form" onSubmit={handleSubmit}>
              {token ? (
                <>
                  <Field
                    className={'restore-password__input'}
                    label="Nowe hasło"
                    name="newPassword"
                    placeholder="Wpisz nowe hasło"
                    required
                    component={Input}
                    errors={errors}
                    maxLength={24}
                    variant="password"
                    treshold={0}
                  />
                  <Field
                    className={'restore-password__input'}
                    label="Powtórz hasło"
                    name="passwordConfirmation"
                    placeholder="Powtórz nowe hasło"
                    required
                    variant="password"
                    component={Input}
                    maxLength={24}
                    treshold={0}
                    errors={errors}
                  />
                </>
              ) : isSubmitted ? null : (
                <Field
                  className={'restore-password__input'}
                  label="Email"
                  name="email"
                  placeholder="Wpisz email"
                  required
                  treshold={0}
                  maxLength={64}
                  component={Input}
                  errors={errors}
                />
              )}
              {isSubmitted ? (
                <Button label="Wyślij ponownie" type="submit" onClick={() => null} round disabled={isSubmitting} />
              ) : (
                <Button label={token ? 'Zapisz' : 'Wyślij'} type="submit" onClick={() => null} round disabled={isSubmitting} />
              )}
            </form>
          )}
        </Formik>
      </div>
      <SVG type={SVG_TYPE.RESTORE_PASSWORD} className="restore-password__svg" />
    </div>
  );
}

export default LinkExpiredPage;
