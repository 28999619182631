import Button from 'Components/Button';
import SVG from 'Components/SVG';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { MainType } from './Container';

function TransactionSuccess({ actions, initialOrder }: MainType) {
  const { getOrder, clearOrders } = actions;
  const naviage = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const orderUuid = query.get('orderUuid');
  const variant = query.get('variant');
  const isOutSystemPayment = variant === 'outSystemPayment';
  useEffect(() => {
    if (orderUuid) {
      getOrder(orderUuid);
    }
  }, [orderUuid]);

  useEffect(() => {
    return () => {
      clearOrders();
    };
  }, []);

  return (
    <div className="page transaction-success">
      <div className="transaction-success__wrapper">
        <h1 className="transaction-success__header">
          {isOutSystemPayment ? 'Dziękujemy za złożenie zamówienia' : 'Transakcja przebiegła poprawnie'}
        </h1>
        {isOutSystemPayment ? (
          <>
            <p className="transaction-success__message transaction-success__message--no-margin">
              Nasi specjaliści już pracują nad Twoim plikiem i&nbsp;skontaktują się w sprawie przelewu bankowego do max. 30 minut*.
            </p>
            <p className="transaction-success__description">*w godzinach pracy - 8:00-18:00, nie dotyczy weekendów</p>
          </>
        ) : initialOrder.translationType === 'regular' ? (
          <p className="transaction-success__message">
            Gotowe tłumaczenie zostanie przesłane drogą mailową na adres:{' '}
            <span className="transaction-success__bold">{initialOrder?.contactData?.email}</span> w terminie do{' '}
            {moment(initialOrder.deadline).format('DD.MM.YYYY')}
          </p>
        ) : (
          <p className="transaction-success__message">
            Gotowe tłumaczenie zostanie przesłane drogą mailową w terminie do {moment(initialOrder.deadline).format('DD.MM.YYYY')} a orginał doślemy
            pocztą.
          </p>
        )}
        <Button label="Wróć do widoku początkowego" round onClick={() => naviage(ROUTES.HOME_PAGE)} />
      </div>
      <SVG type={SVG_TYPE.TRANSACTION_SUCCESS} className="transaction-success__svg transaction-success__svg--desktop" />
      <SVG type={SVG_TYPE.TRANSACTION_SUCCESS_MOBILE} className="transaction-success__svg transaction-success__svg--mobile" />
    </div>
  );
}

export default TransactionSuccess;
