import { MainType } from './Container';
import { ContainerLeft, ContainerRight } from 'Components/form';
import Back from 'Components/Back';
import { useEffect, useRef, useState } from 'react';
import { AddFile, CertifiedQualityForm, ContactForm, IncorrectFilesForm, LanguagesForm, LanguagesModal, ServiceLevels, Summary } from './components';
import Step from 'Components/Step';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ROUTES, SVG_TYPE, VALIDATION_MESSAGES } from 'Shared/enums';
import { allowedFilesExtensions, extensionsWithAutomaticPricing } from 'Shared/utils';
import { specialization } from 'Assets/specialization';
import { useNavigate } from 'react-router';
import DeadlineModal from './components/DeadlineModal';
import { toast } from 'react-toastify';
import SVG from 'Components/SVG';
import { isMobile } from 'Assets/isMobile';

function OrderPage(props: MainType) {
  const { history, actions, currentOrderUuid, currentOrder, translations, initialOrder, loaderVisible, companyInfoFromNip } = props;

  const {
    createOrder,
    getLanguages,
    getTranslations,
    addOrderFile,
    deleteFile,
    confirmOrder,
    changeCurrentOrder,
    getOrder,
    postOrderContactData,
    showLoader,
    hideLoader,
    createPayment,
    clearOrders,
    getCompanyFromNip,
  } = actions;

  const [currentStep, setCurrentStep] = useState(1);
  const [translateFromOptions, setTranslateFromOptions] = useState([]);
  const [translateToOptions, setTranslateToOptions] = useState([]);
  const [formValues, setFormValues] = useState<any>({});
  const [flagsUrls, setFlagsUrls] = useState<any>([]);
  const [prevSelectedLanguages, setPrevSelectedLanguages] = useState([]);
  const [currentText, setCurrentText] = useState('');
  const [languagesInModal, setLanguagesInModal] = useState(translateToOptions);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wasModalOpen, setWasModalOpen] = useState(false);
  const [isInitialOrder, setIsInitialOrder] = useState(true);
  const [previousTranslateFromUuid, setPreviousTranslateFromUuid] = useState('');
  const [correctFiles, setCorrectFiles] = useState<boolean>(true);
  const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({}));
  const [isDeadlineModalOpen, setIsDeadlineModalOpen] = useState(false);
  const [customDeadline, setCustomDeadline] = useState('');
  const [dragging, setDragging] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const uuidFromUrl = query.get('uuid');
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const [certifiedShowMore, setCertifiedShowMore] = useState(false);
  const [debouncedValues, setDebouncedValues] = useState<any>(formValues);
  const [isFirstConfirm, setIsFirstConfirm] = useState(true);
  const [isInit, setIsInit] = useState(true);

  const formRef = useRef(null);

  useEffect(() => {
    if (translations.length) {
      const tempArr = translations.map((item) => {
        item.translationFrom.label = item.translationFrom.language;
        item.translationFrom.value = item.translationFrom.language;
        return item.translationFrom;
      });
      const arrayWithoutDuplicates = tempArr.filter((value, index, self) => index === self.findIndex((t) => t.uuid === value.uuid));
      setTranslateFromOptions(arrayWithoutDuplicates);

      const translationIntoFlags = translations.map((item) => {
        return item.translationInto.files.url;
      });
      const translationFromFlags = translations.map((item) => {
        return item.translationFrom.files.url;
      });
      const flags = [...translationIntoFlags, ...translationFromFlags];
      const flagsWithoutDuplicates = flags.filter((value, index, self) => index === self.findIndex((t) => t === value));
      setFlagsUrls(flagsWithoutDuplicates);
    }
  }, [translations]);

  useEffect(() => {
    if (translations.length && formValues?.translateFrom?.length && previousTranslateFromUuid !== formValues?.translateFrom[0]?.uuid) {
      const translationsToActiveLanguage = translations.filter((item) => item.translationFrom.uuid === formValues?.translateFrom[0]?.uuid);
      const translationIntoArray = translationsToActiveLanguage.map((item) => {
        item.translationInto.label = item.translationInto.language;
        item.translationInto.value = item.translationInto.language;
        item.translationInto.translationUuid = item.uuid;
        return item.translationInto;
      });
      setTranslateToOptions(translationIntoArray);
      if (initialOrder.translations?.length && isInitialOrder) {
        setIsInitialOrder(false);
      } else {
        formRef.current.setFieldValue('translateTo', []);
      }
      setPreviousTranslateFromUuid(formValues.translateFrom[0]?.uuid);
    }
  }, [formValues?.translateFrom]);

  useEffect(() => {
    if (currentOrder && isInit && currentOrder.status) {
      if (currentOrder.status !== 'valued' && currentOrder.status !== 'impossible_to_valuation' && currentOrder.status !== 'lead') {
        sessionStorage.removeItem('orderUuid');
        history.push(ROUTES.PAGE404);
      }
      setIsInit(false);
    }
  }, [currentOrder]);

  useEffect(() => {
    if (uuidFromUrl) {
      const valuatedFiles = currentOrder?.files?.filter((file) => file.fileData?.is_valued).map((file) => file.name);
      const filteredIncorrectFiles = currentOrder.files
        ?.filter((file) => !valuatedFiles.includes(file.name))
        .filter((file) => !extensionsWithAutomaticPricing.exec(file.name) || file.fileData.sign_count == 0);
      if (filteredIncorrectFiles?.length) {
        setCorrectFiles(false);
      } else {
        setCorrectFiles(true);
      }
    } else {
      const incorrectFiles = currentOrder?.files?.filter((file: any) =>
        !extensionsWithAutomaticPricing.exec(file.name) || file.fileData ? file.fileData.sign_count == 0 : false,
      );
      if (incorrectFiles?.length) {
        setCorrectFiles(false);
      } else {
        setCorrectFiles(true);
      }
    }
  }, [currentOrder.files]);

  const onSubmit = async () => {
    const isSuccess = await postOrderContactData(formValues, currentOrderUuid);
    if (isSuccess) {
      const result: any = await confirmOrder({ ...formValues, accepted: true }, currentOrderUuid);
      sessionStorage.removeItem('orderUuid');
      if (correctFiles) {
        if ((formValues.type === 'person' && result?.order?.isToPay) || (formValues.type === 'company' && formValues.paymentMethod === 'instant')) {
          setIsPending(true);
          await createPayment(currentOrderUuid.uuid);
        } else {
          clearOrders();
          setIsPending(false);
          navigate({
            pathname: ROUTES.TRANSACTION_SUCCESS,
            search: `?orderUuid=${currentOrderUuid.uuid}&variant=outSystemPayment`,
          });
        }
      } else {
        sessionStorage.removeItem('orderUuid');
        navigate(ROUTES.REQUEST_SEND);
      }
      localStorage;
    }
    return isSuccess;
  };

  const handleBack = () => {
    if (currentStep === 1) {
      navigate(ROUTES.HOME_PAGE);
    }
    setCurrentStep(currentStep - 1);
  };
  const initialValues = {
    priorities: initialOrder.priorities || null,
    quality: initialOrder.quality || '',
    translateFrom: '',
    translateTo: '',
    translationsUuids: initialOrder.translations?.map((item) => item.uuid) || null,
    translationType: initialOrder.translationType || null,
    specialization: initialOrder.specialization || null,
    files: [],
    type: 'person',
    email: initialOrder?.contactData?.email || '',
    firstName: initialOrder?.contactData?.firstName || '',
    lastName: initialOrder?.contactData?.lastName || '',
    phoneNumber: initialOrder?.contactData?.phoneNumber || '',
    phonePrefix: initialOrder?.contactData?.phonePrefix || '',
    nip: initialOrder?.contactData?.nip || companyInfoFromNip?.nip || '',
    street: initialOrder?.contactData?.street || companyInfoFromNip?.street || '',
    houseNumber: initialOrder?.contactData?.houseNumber || companyInfoFromNip?.houseNumber || '',
    city: initialOrder?.contactData?.city || companyInfoFromNip?.city || '',
    postCode: initialOrder?.contactData?.postCode || companyInfoFromNip?.postCode || '',
    companyName: initialOrder?.contactData?.companyName || companyInfoFromNip?.companyName || '',
    nipCountry: initialOrder?.contactData?.nipCountry || '',
    acceptance: false,
  };

  useEffect(() => {
    validation(currentStep);
  }, [currentStep, correctFiles]);

  useEffect(() => {
    formRef.current.validateForm();
  }, [validationSchema]);

  const validation = (step) => {
    switch (step) {
      case 1:
        setValidationSchema(
          Yup.object().shape({
            translateFrom: Yup.array().required(VALIDATION_MESSAGES.REQUIRED),
            translateTo: Yup.array().min(1, VALIDATION_MESSAGES.REQUIRED),
            files: Yup.array().min(1, VALIDATION_MESSAGES.REQUIRED),
          }),
        );
        break;
      case 2:
        setValidationSchema(
          correctFiles
            ? Yup.object().shape({
                translateFrom: Yup.array().min(1, VALIDATION_MESSAGES.REQUIRED),
                translateTo: Yup.array().min(1, VALIDATION_MESSAGES.REQUIRED),
                files: Yup.array().min(1, VALIDATION_MESSAGES.REQUIRED),
                quality: Yup.string().required(VALIDATION_MESSAGES.QUALITY_REQUIED),
              })
            : Yup.object().shape({
                translateFrom: Yup.array().min(1, VALIDATION_MESSAGES.REQUIRED),
                translateTo: Yup.array().min(1, VALIDATION_MESSAGES.REQUIRED),
                files: Yup.array().min(1, VALIDATION_MESSAGES.REQUIRED),
                email: Yup.string().email(VALIDATION_MESSAGES.EMAIL).required(VALIDATION_MESSAGES.REQUIRED),
                phonePrefix: Yup.string()
                  .required(VALIDATION_MESSAGES.REQUIRED)
                  .matches(/^\+[0-9]{1,3}$/, VALIDATION_MESSAGES.PHONE_PREFIX),
                phoneNumber: Yup.string()
                  .required(VALIDATION_MESSAGES.REQUIRED)
                  .matches(/^[0-9]{9,}$/, VALIDATION_MESSAGES.PHONE)
                  .max(9, VALIDATION_MESSAGES.PHONE),
              }),
        );
        break;
      case 3:
        setValidationSchema(
          Yup.object().shape({
            translateFrom: Yup.array().min(1, VALIDATION_MESSAGES.REQUIRED),
            translateTo: Yup.array().min(1, VALIDATION_MESSAGES.REQUIRED),
            acceptance: Yup.bool().oneOf([true], VALIDATION_MESSAGES.REQUIRED),
            files: Yup.array().min(1, VALIDATION_MESSAGES.REQUIRED),
            email: Yup.string().email(VALIDATION_MESSAGES.EMAIL).required(VALIDATION_MESSAGES.REQUIRED),
            phonePrefix: Yup.string()
              .required(VALIDATION_MESSAGES.REQUIRED)
              .matches(/^\+[0-9]{1,3}$/, VALIDATION_MESSAGES.PHONE_PREFIX),
            phoneNumber: Yup.string()
              .required(VALIDATION_MESSAGES.REQUIRED)
              .matches(/^[0-9]{9,}$/, VALIDATION_MESSAGES.PHONE)
              .max(9, VALIDATION_MESSAGES.PHONE),
            street: Yup.string().required(VALIDATION_MESSAGES.REQUIRED).min(3, VALIDATION_MESSAGES.MIN_3),
            city: Yup.string().required(VALIDATION_MESSAGES.REQUIRED).min(3, VALIDATION_MESSAGES.MIN_3),
            postCode: Yup.string()
              .required(VALIDATION_MESSAGES.REQUIRED)
              .matches(/^[0-9]{2}-[0-9]{3}$/, VALIDATION_MESSAGES.POST_CODE),
            houseNumber: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
            companyName: Yup.string().when('type', {
              is: 'company',
              then: Yup.string().required(VALIDATION_MESSAGES.REQUIRED).min(3, VALIDATION_MESSAGES.MIN_3),
            }),
            nip: Yup.string().when('type', {
              is: 'company',
              then: Yup.string().required(VALIDATION_MESSAGES.REQUIRED).min(10, VALIDATION_MESSAGES.NIP).max(10, VALIDATION_MESSAGES.NIP),
            }),
            firstName: Yup.string().when('type', {
              is: 'person',
              then: Yup.string().required(VALIDATION_MESSAGES.REQUIRED).min(3, VALIDATION_MESSAGES.MIN_3),
            }),
            lastName: Yup.string().when('type', {
              is: 'person',
              then: Yup.string().required(VALIDATION_MESSAGES.REQUIRED).min(3, VALIDATION_MESSAGES.MIN_3),
            }),
            nipCountry: Yup.string().when('type', {
              is: 'company',
              then: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
            }),
            paymentMethod: Yup.string().when('type', {
              is: 'company',
              then: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
            }),
          }),
        );
    }
  };

  useEffect(() => {
    if (
      formValues.firstName !== debouncedValues.firstName ||
      formValues.lastName !== debouncedValues.lastName ||
      formValues.nip !== debouncedValues.nip ||
      formValues.street !== debouncedValues.street ||
      formValues.houseNumber !== debouncedValues.houseNumber ||
      formValues.city !== debouncedValues.city ||
      formValues.postCode !== debouncedValues.postCode ||
      formValues.companyName !== debouncedValues.companyName ||
      formValues.email !== debouncedValues.email ||
      formValues.phoneNumber !== debouncedValues.phoneNumber ||
      formValues.phonePrefix !== debouncedValues.phonePrefix
    ) {
      const timeoutId = setTimeout(() => {
        setDebouncedValues(formValues);
      }, 500);
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      setDebouncedValues(formValues);
    }
  }, [formValues]);

  useEffect(() => {
    if (isFirstConfirm && formValues?.translateTo?.length && formValues?.files?.length) {
      setIsFirstConfirm(false);
      confirmOrder(formValues, currentOrderUuid);
    }
  }, [uuidFromUrl, formValues?.translateTo?.length]);

  useEffect(() => {
    if (formValues?.translateFrom?.length && formValues?.translateTo?.length && formValues?.files?.length) {
      confirmOrder(formValues, currentOrderUuid);
    }
  }, [debouncedValues]);

  useEffect(() => {
    if (formValues?.translationType === 'certified') {
      formRef.current.setFieldValue('quality', 'basic');
    }
  }, [formValues?.translationType]);

  useEffect(() => {
    const files = initialOrder.files;
    if (initialOrder.files?.length) {
      const filesWithoutDuplicates = files.filter((value, index, self) => index === self.findIndex((t) => t.uuid === value.uuid));
      formRef.current.setFieldValue('files', filesWithoutDuplicates);
    }
    if (initialOrder?.translations?.length && translations.length) {
      const initialTranslationUuids = initialOrder.translations;
      const initialTranslations = initialTranslationUuids?.map((item) => translations.find((translation) => translation.uuid === item.uuid));
      const translationIntoArray = initialTranslations?.map((item) => {
        item.translationInto.label = item.translationInto.language;
        item.translationInto.value = item.translationInto.language;
        item.translationInto.translationUuid = item.uuid;
        return item.translationInto;
      });
      initialTranslations[0].translationFrom && formRef.current.setFieldValue('translateFrom', [initialTranslations[0].translationFrom]);
      formRef.current.setFieldValue('translateTo', translationIntoArray);
      if (translationIntoArray.length > 1) {
        setWasModalOpen(true);
      }
      initialOrder.specialization &&
        formRef.current.setFieldValue('specialization', [specialization.find((item) => item.value === initialOrder.specialization)]);
    }
  }, [initialOrder, translations]);

  const handleAddFIle = async (e) => {
    const newFiles = e.currentTarget.files || e.dataTransfer.files;
    const fileArray = formRef.current.values.files;
    const fileArrayNames = fileArray.map((item) => item.name);
    const tempArr = [...fileArray];
    let temporaryUuid = undefined;
    showLoader();
    for (const file of newFiles) {
      if (allowedFilesExtensions.exec(file.name)) {
        if (!fileArrayNames.includes(file.name) && file.size < 20000000 && tempArr.length < 10) {
          const formData = new FormData();
          formData.append('files', file);

          const newUuid = currentOrderUuid || temporaryUuid || undefined;
          console.log(newUuid, 'newUuid');
          const respond = await addOrderFile({ files: formData, orderUuid: newUuid });

          respond.orderUuid && changeCurrentOrder({ uuid: respond.orderUuid });
          respond.orderUuid && (temporaryUuid = respond.orderUuid);
          Object.defineProperty(file, 'uuid', {
            value: Array.isArray(respond) ? respond[0].uuid : respond.filesArray[0].uuid,
            enumerable: true,
          });
          tempArr.push(file);
        } else {
          if (file.size >= 20000000) {
            toast.error('Maksymalny rozmiar pliku to 20mb', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
          } else if (tempArr.length >= 10) {
            toast.error('Osiągnięto limit ilości plików', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
          } else {
            toast.error('Plik o takiej nazwie już istnieje', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
          }
        }
      } else {
        toast.error('Nieobsługiwany format pliku', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
      }
    }
    e.target.value = null;
    formRef.current.setFieldValue('files', tempArr);
    hideLoader();
  };

  useEffect(() => {
    if (currentOrderUuid?.uuid) {
      sessionStorage.setItem('orderUuid', currentOrderUuid.uuid);
    }
  }, [currentOrderUuid, currentOrder]);

  function handleDragOver(event) {
    event.preventDefault();
    document.body.style.cursor = 'pointer';
    setDragging(true);
  }

  let counter = 0;
  function handleDragLeave(event) {
    event.preventDefault();
    counter--;
    if (counter === 0) {
      setDragging(false);
      document.body.style.cursor = '';
    }
  }
  function handleDragEnter(event) {
    event.preventDefault();
    counter++;
  }

  function handleDrop(event) {
    event.preventDefault();
    counter = 0;
  }

  useEffect(() => {
    document.body.addEventListener('dragover', handleDragOver);
    document.body.addEventListener('dragleave', handleDragLeave);
    document.body.addEventListener('dragenter', handleDragEnter);
    document.body.addEventListener('drop', handleDrop);
    return () => {
      document.body.removeEventListener('dragover', handleDragOver);
      document.body.removeEventListener('dragleave', handleDragLeave);
      document.body.removeEventListener('dragenter', handleDragEnter);
      document.body.removeEventListener('drop', handleDrop);
    };
  }, []);

  useEffect(() => {
    getLanguages();
    getTranslations(null);
    const orderUuid = sessionStorage.getItem('orderUuid');
    if (uuidFromUrl || orderUuid) {
      getOrder(uuidFromUrl || orderUuid);
    }
    if (uuidFromUrl) {
      setCurrentStep(2);
      setCorrectFiles(true);
    }
  }, []);
  return (
    <div className="page order-page">
      <div className="order-page__top">
        <Back onClick={() => handleBack()} />
        <Step steps={correctFiles ? 3 : 2} currentStep={currentStep} />
        <span className="order-page__top-spacer" />
      </div>
      <Formik
        validateOnChange
        isInitialValid={false}
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        innerRef={formRef}
        onSubmit={async () => onSubmit()}>
        {({ handleSubmit, errors, values, setFieldValue, isValid, validateForm, setTouched, setFieldError, touched, setValues }) => (
          <form className="order-page__form" onSubmit={handleSubmit}>
            <DeadlineModal
              selectedQuality={formValues.quality}
              isOpen={isDeadlineModalOpen}
              onClose={() => setIsDeadlineModalOpen(false)}
              setFieldValue={setFieldValue}
              setCustomDeadline={setCustomDeadline}
            />
            <LanguagesModal
              setIsModalOpen={setIsModalOpen}
              isModalOpen={isModalOpen}
              setPrevSelectedLanguages={setPrevSelectedLanguages}
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
              translateToOptions={translateToOptions}
              setCurrentText={setCurrentText}
              currentText={currentText}
              languagesInModal={languagesInModal}
              setLanguagesInModal={setLanguagesInModal}
              prevSelectedLanguages={prevSelectedLanguages}
              wasModalOpen={wasModalOpen}
              setWasModalOpen={setWasModalOpen}
              setFormValues={setFormValues}
            />
            <ContainerLeft
              className={`${dragging ? 'container-left--dragging' : ''} ${
                !certifiedShowMore && currentStep === 2 && values.translationType === 'certified' ? 'container-left--certified-show-more' : ''
              }`}>
              <div className="order-page__form-top">
                <Back onClick={() => handleBack()} />
                <h2 className="order-page__header order-page__header--left">
                  {currentStep === 1 ? 'Uzupełnij treść tłumaczenia' : null}
                  {currentStep === 2 ? (correctFiles ? 'Wybierz poziom usługi' : 'Wypełnij swoje dane') : null}
                  {currentStep === 3 ? 'Uzupełnij dane kontaktowe' : null}
                </h2>
                <Step steps={correctFiles ? 3 : 2} currentStep={currentStep} />
              </div>
              <div
                className={`container-left__slider ${
                  currentStep === 2 && correctFiles && values.translationType !== 'certified' && isMobile()
                    ? 'container-left__slider--full-width'
                    : ''
                }`}>
                <LanguagesForm
                  translateFromOptions={translateFromOptions}
                  translateToOptions={translateToOptions}
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFormValues={setFormValues}
                  setLanguagesInModal={setLanguagesInModal}
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
                  setCurrentText={setCurrentText}
                  currentText={currentText}
                  prevSelectedLanguages={prevSelectedLanguages}
                  wasModalOpen={wasModalOpen}
                  setWasModalOpen={setWasModalOpen}
                  currentStep={currentStep}
                />
                {correctFiles ? (
                  values.translationType === 'certified' ? (
                    <CertifiedQualityForm
                      setIsDeadlineModalOpen={setIsDeadlineModalOpen}
                      currentStep={currentStep}
                      customDeadline={customDeadline}
                      currentOrder={currentOrder}
                      setCertifiedShowMore={setCertifiedShowMore}
                      certifiedShowMore={certifiedShowMore}
                    />
                  ) : (
                    <ServiceLevels
                      setFormValues={setFormValues}
                      currentOrder={currentOrder}
                      errors={errors}
                      values={values}
                      setIsDeadlineModalOpen={setIsDeadlineModalOpen}
                      setFieldValue={setFieldValue}
                      currentStep={currentStep}
                      setFieldError={setFieldError}
                      customDeadline={customDeadline}
                      setCustomDeadline={setCustomDeadline}
                      touched={touched}
                    />
                  )
                ) : (
                  <IncorrectFilesForm
                    setFormValues={setFormValues}
                    currentOrder={currentOrder}
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                    currentStep={currentStep}
                  />
                )}

                <ContactForm
                  getCompanyFromNip={getCompanyFromNip}
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                  currentStep={currentStep}
                  setValues={setValues}
                />
              </div>
            </ContainerLeft>
            <ContainerRight className={`${currentStep === 1 ? 'order-page__add-file' : ''} ${dragging ? 'container-right--dragging' : ''}`}>
              <div className="container-right__slider">
                <AddFile
                  setFieldValue={setFieldValue}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  createOrder={createOrder}
                  values={values}
                  isValid={isValid}
                  handleAddFIle={handleAddFIle}
                  secondSlide={values?.files?.length > 0}
                  loaderVisible={loaderVisible}
                  validateForm={validateForm}
                  setTouched={setTouched}
                  setDragging={setDragging}
                  dragging={dragging}
                  errors={errors}
                  touched={touched}
                />
                <Summary
                  setCurrentStep={setCurrentStep}
                  setFieldValue={setFieldValue}
                  isValid={isValid}
                  step={currentStep}
                  values={values}
                  handleAddFIle={handleAddFIle}
                  handleSubmit={handleSubmit}
                  deleteFile={deleteFile}
                  confirmOrder={confirmOrder}
                  currentOrderUuid={currentOrderUuid}
                  currentOrder={currentOrder}
                  currentStep={currentStep}
                  secondSlide={values?.files?.length > 0}
                  correctFiles={correctFiles}
                  loaderVisible={loaderVisible}
                  validateForm={validateForm}
                  setTouched={setTouched}
                  setDragging={setDragging}
                  dragging={dragging}
                  errors={errors}
                  initialOrder={initialOrder}
                  isPending={isPending}
                />
              </div>
            </ContainerRight>
          </form>
        )}
      </Formik>
      <div className="order-page__hidden-flags">{flagsUrls.length ? flagsUrls.map((item) => <img key={item} src={item} alt="" />) : null}</div>
    </div>
  );
}

export default OrderPage;
