import { isMobile } from 'Assets/isMobile';
import { orderStatus } from 'Assets/orderStatus';
import { paymentStatus } from 'Assets/paymentStatus';
import { specialization } from 'Assets/specialization';
import Button from 'Components/Button';
import SVG from 'Components/SVG';
import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { SVG_TYPE } from 'Shared/enums';
import * as Yup from 'yup';
import ContactData from './ContactData';
import EditOrderModal from './EditOrderModal';
import OrderData from './OrderData';
import StatusData from './StatusData';

interface SingleOrderProps {
  data: any;
  setShowOrder: (value: string) => void;
  showOrder: string;
  translations: any;
  downloadAllFiles: (uuid: string) => void;
  editOrder: (uuid: string, data: any) => boolean;
  currentPage: number;
  getOrders: () => void;
  usersLabel: [{ value: string; label: string }];
  assignEmployeeToOrder: (orderUuid: string, userUuid: any) => void;
  search: string;
  getOrder: (uuid: string) => void;
  downloadAllTranslations: (uuid: string) => void;
  getInvoice: (uuid: string) => void;
  invoices: { invoice_pdf: string; number: string };
}

function SingleOrder({
  data,
  setShowOrder,
  showOrder,
  translations,
  downloadAllFiles,
  editOrder,
  getOrders,
  usersLabel,
  assignEmployeeToOrder,
  search,
  downloadAllTranslations,
  getOrder,
  getInvoice,
  invoices,
}: SingleOrderProps) {
  const [selectedOption, setSelectedOption] = useState<any>(isMobile() ? 'statusy' : 'zlecenie');
  const specializations = specialization.find((item) => item.value === data.specialization);
  const isEditable = data.status === 'impossible_to_valuation';
  const formRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCoordinator, setIsCoordinator] = useState(data.user?.length ? !data.user[0].suspended && data.user[0].activate : false);

  const handleOnSubmit = async (values: any) => {
    const response = await editOrder(data.uuid, { ...data, ...values });
    if (response) {
      getOrders();
      getOrder(data.uuid);
      setShowOrder('');
      setIsModalOpen(false);
    }
  };

  const validationSchema = Yup.object().shape({
    signCount: Yup.number().required('Pole wymagane'),
  });

  const initialValues = {
    signCount: data.signCount || '',
    paymentStatus: paymentStatus.filter((status) => status.value === data.paymentStatus),
    orderStatus: orderStatus.filter((status) => status.value === data.status),
    coordinator: data.user?.length ? data.user[0].uuid : '',
  };
  useEffect(() => {
    formRef.current.resetForm();
    setIsCoordinator(data.user?.length ? !data.user[0].suspended && data.user[0].activate : false);
    setSelectedOption((data.user?.length ? !data.user[0].suspended && data.user[0].activate : false) ? 'zlecenie' : 'statusy');
    if (data.paymentStatus === 'stripe_payment') {
      getInvoice(data.uuid);
    }
  }, [data]);

  const handleShowError = () => {
    formRef.current.validateForm();
    formRef.current.setFieldTouched('signCount');
  };
  return (
    <div className={`single-order ${showOrder ? '' : 'single-order--hidden'}`}>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={async (values) => handleOnSubmit(values)}>
        {({ handleSubmit, errors, values }) => (
          <form className="single-order__form" onSubmit={handleSubmit}>
            <EditOrderModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} data={values} />
            <div className="single-order__header">
              <div className="single-order__header-content">
                <h2>Zlecenie nr. {data.number}</h2>
                <span onClick={() => setShowOrder('')} className={'single-order__close'}>
                  <SVG type={SVG_TYPE.CLOSE} />
                </span>
              </div>
              <div className="single-order__header-select">
                {isMobile() ? (
                  <div
                    className={`single-order__header-item ${selectedOption === 'statusy' ? 'single-order__header-item--active' : ''}`}
                    onClick={() => setSelectedOption('statusy')}>
                    Statusy
                  </div>
                ) : null}
                <div
                  className={`single-order__header-item ${selectedOption === 'zlecenie' ? 'single-order__header-item--active' : ''}`}
                  onClick={() => isCoordinator && setSelectedOption('zlecenie')}>
                  Zlecenie
                </div>
                <div
                  className={`single-order__header-item ${selectedOption === 'zleceniodawca' ? 'single-order__header-item--active' : ''}`}
                  onClick={() => isCoordinator && setSelectedOption('zleceniodawca')}>
                  Zleceniodawca
                </div>
              </div>
            </div>
            <div className="single-order__content">
              {selectedOption === 'statusy' ? (
                <StatusData
                  data={data}
                  assignEmployeeToOrder={assignEmployeeToOrder}
                  values={values}
                  handleSubmit={handleSubmit}
                  usersLabel={usersLabel}
                  getOrder={getOrder}
                  search={search}
                />
              ) : null}
              {selectedOption === 'zlecenie' ? (
                <OrderData
                  data={data}
                  translations={translations}
                  isEditable={isEditable}
                  errors={errors}
                  specializations={specializations}
                  downloadAllFiles={downloadAllFiles}
                  search={search}
                  downloadAllTranslations={downloadAllTranslations}
                  invoices={invoices}
                />
              ) : selectedOption === 'zleceniodawca' ? (
                <ContactData data={data} />
              ) : null}
              {isEditable && selectedOption === 'zlecenie' ? (
                <div className={'single-order__buttons'}>
                  <Button label="Cofnij" onClick={() => setShowOrder('')} round stroke />
                  <Button label="Zapisz zmiany" onClick={() => (values.signCount ? setIsModalOpen(true) : handleShowError())} round />
                </div>
              ) : null}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default SingleOrder;
