import SVG from 'Components/SVG';
import { useEffect, useState } from 'react';
import { ROUTES, SVG_TYPE } from 'Shared/enums';

interface LeftBarProps {
  pathname: string;
  navigate: (path: string) => void;
  authorizedUser: any;
}

function LeftBar({ pathname, navigate, authorizedUser }: LeftBarProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isAdmin = authorizedUser?.role === 'admin';

  useEffect(() => {
    function handleClickOutside(event) {
      if (!event.target.closest('.left-bar')) {
        setIsMenuOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className={`left-bar ${isMenuOpen ? 'left-bar--open' : ''}`}>
      <div className="left-bar__top">
        <div className="left-bar__logo" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <SVG type={SVG_TYPE.TIE} />
        </div>
        <div className={`left-bar__close ${isMenuOpen ? 'left-bar__close--show' : ''}`} onClick={() => setIsMenuOpen(false)}>
          <SVG type={SVG_TYPE.CLOSE} />
        </div>
      </div>

      <div
        className={`left-bar__item-wrapper ${pathname === ROUTES.DASHBOARD_ORDERS ? 'left-bar__item-wrapper--current' : ''}`}
        onClick={() => navigate(ROUTES.DASHBOARD_ORDERS)}>
        <div className={`left-bar__navigation-item ${pathname === ROUTES.DASHBOARD_ORDERS ? 'left-bar__navigation-item--current' : ''}`}>
          <SVG type={SVG_TYPE.LIST} />
        </div>
        <p className="left-bar__item-description">Lista zleceń</p>
      </div>

      <div
        className={`left-bar__item-wrapper ${pathname === ROUTES.DASHBOARD_TRANSLATIONS ? 'left-bar__item-wrapper--current' : ''}`}
        onClick={() => navigate(ROUTES.DASHBOARD_TRANSLATIONS)}>
        <div className={`left-bar__navigation-item ${pathname === ROUTES.DASHBOARD_TRANSLATIONS ? 'left-bar__navigation-item--current' : ''}`}>
          <SVG type={SVG_TYPE.COMMENTS} />
        </div>
        <p className="left-bar__item-description">Języki i tłumaczenia</p>
      </div>

      {isAdmin ?<div
        className={`left-bar__item-wrapper ${pathname === ROUTES.DASHBOARD_USERS ? 'left-bar__item-wrapper--current' : ''}`}
        onClick={() => navigate(ROUTES.DASHBOARD_USERS)}>
        <div className={`left-bar__navigation-item ${pathname === ROUTES.DASHBOARD_USERS ? 'left-bar__navigation-item--current' : ''}`}>
          <SVG type={SVG_TYPE.USERS} />
        </div>
        <p className="left-bar__item-description">Lista użytkowników</p>
      </div> : null}
    </div>
  );
}

export default LeftBar;
