import { Order } from '../EnumTypes/ActionEnumTypes';
import * as OrderModule from '../../../Modules/OrderModule';
import { SVG_TYPE } from 'Shared/enums';
import SVG from 'Components/SVG';
import { toast } from 'react-toastify';

const init = () => ({
  type: Order.GET_COMPANY_DATA_FROM_NIP_INIT,
});

const success = (data: any) => ({
  type: Order.GET_COMPANY_DATA_FROM_NIP_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Order.GET_COMPANY_DATA_FROM_NIP_FAILURE,
});

const getCompanyFromNip = (nip) => async (dispatch) => {
  dispatch(init());
  const id = toast.loading('Pobieranie danych firmy...');
  try {
    const data: any = await OrderModule.Connector.getCompanyFromNip(nip);
    dispatch(success(data));
    toast.update(id, {
      render: 'Pomyślnie pobrano dane firmy',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} />,
      autoClose: 3000,
      closeButton: true,
    });
    return data.data.companyInfo;
  } catch (err: any) {
    dispatch(failure());
    toast.update(id, {
      render: 'Nie udało się pobrać danych firmy',
      type: toast.TYPE.ERROR,
      isLoading: false,
      autoClose: 3000,
      closeButton: true,
      icon: <SVG variant={SVG_TYPE.CIRCLE_X} />,
    });
  }
};

export default getCompanyFromNip;
