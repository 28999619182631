import * as OrderModule from '../../../Modules/OrderModule';
import { Order } from '../EnumTypes/ActionEnumTypes';
// import * as OrderActionModel from '../Models/OrderActionModel';

const init = () => ({
  type: Order.CREATE_ORDER_INIT,
});

const success = (data: any) => ({
  type: Order.CREATE_ORDER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Order.CREATE_ORDER_FAILURE,
});

const createOrder = (orderForm: OrderModule.Types.OrderForm) => async (dispatch) => {
  const dataToSend = { ...orderForm.formData };
  dataToSend.specialization = dataToSend.specialization?.length ? dataToSend.specialization[0].value : undefined;
  dataToSend.translateFrom = undefined;
  dataToSend.files = undefined;
  dataToSend.accepted = undefined;
  dataToSend.translationsUuids = dataToSend.translateTo.map(item=> item.translationUuid)
  dataToSend.translateTo = undefined;
  dispatch(init());
  try {
    const data = await OrderModule.Connector.createOrder(dataToSend);
    dispatch(success(data));
    //   dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err: any) {
    //   dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
    console.log('CREATE_ORDER_FAILURE', err.response);
    // toast.error('Niepoprawne dane logowania');
  }
};

export default createOrder;
