import { Order } from '../EnumTypes/ActionEnumTypes';
import * as OrderModule from '../../../Modules/OrderModule';

const init = () => ({
  type: Order.GET_ORDERS_LIST_INIT,
});

const success = (data: any) => ({
  type: Order.GET_ORDERS_LIST_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Order.GET_ORDERS_LIST_FAILURE,
});

const getOrdersList = (page, search) => async (dispatch) => {
  dispatch(init());
  try {
    const data = await OrderModule.Connector.getOrdersList(page, search);
    dispatch(success(data));
  } catch (err: any) {
    dispatch(failure());
    console.log('GET_ORDERS_LIST', err.response);
  }
};

export default getOrdersList;
