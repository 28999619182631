import * as FilesModule from '../../../Modules/FilesModule';
import { Files } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';


const init = () => ({
  type: Files.DOWNLOAD_ALL_FILES_INIT,
});

const success = (data: any) => ({
  type: Files.DOWNLOAD_ALL_FILES_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Files.DOWNLOAD_ALL_FILES_FAILURE,
});

const downloadAllFiles = (uuid) => async (dispatch) => {
  dispatch(init());
  try {
    const data: any = await FilesModule.Connector.downloadAllFiles(uuid);
    console.log(data);
    const href = URL.createObjectURL(data.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'files.zip');
    document.body.appendChild(link);
    link.click();
    link.remove();
    dispatch(success(data));
    toast.success('Pomyślnie pobrano pliki', { icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} /> });
  } catch (err: any) {
    dispatch(failure());
    toast.error('Nie udało się pobrać plików', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
    return err.response;
  }
};

export default downloadAllFiles;
