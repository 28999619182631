import { Files } from '../Actions/EnumTypes/ActionEnumTypes';
// import { ViewsManagemenetAction } from '../Actions/Models/ViewManagementActionModel';

export type State = {
  files: Array<any>;
};

export const initialState: State = {
  files: [],
};

export function filesReducer(state: State = initialState, action) {
  switch (action.type) {
    case Files.ADD_ORDER_FILE_INIT: {
      return {
        ...state,
      };
    }
    case Files.ADD_ORDER_FILE_SUCCESS: {
      return {
        ...state,
      };
    }
    case Files.ADD_ORDER_FILE_FAILURE: {
      return {
        ...state,
      };
    }
    case Files.DELETE_FILE_INIT: {
      return {
        ...state,
      };
    }
    case Files.DELETE_FILE_SUCCESS: {
      return {
        ...state,
      };
    }
    case Files.DELETE_FILE_FAILURE: {
      return {
        ...state,
      };
    }
    case Files.ADD_LANGUAGE_FLAG_INIT: {
      return {
        ...state,
      };
    }
    case Files.ADD_LANGUAGE_FLAG_SUCCESS: {
      return {
        ...state,
      };
    }
    case Files.ADD_LANGUAGE_FLAG_FAILURE: {
      return {
        ...state,
      };
    }

    case Files.ADD_TRANSLATED_FILE_INIT: {
      return {
        ...state,
      };
    }
    case Files.ADD_TRANSLATED_FILE_SUCCESS: {
      return {
        ...state,
      };
    }
    case Files.ADD_TRANSLATED_FILE_FAILURE: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}
