import { isMobile } from 'Assets/isMobile';
import Button from 'Components/Button';
import { Input } from 'Components/form';
import SVG from 'Components/SVG';
import { Field, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { SVG_TYPE } from 'Shared/enums';

interface TranslationListProps {
  languagesList: Array<any>;
  selectedFilters: any;
  setOpenedModal: (value: string) => void;
  setEditData: (value: any) => void;
  handleValueChange: (values: any) => void;
  isAdmin: boolean;
}

function Languages(props: TranslationListProps) {
  const [openMenu, setOpenMenu] = useState('');
  const { languagesList, setOpenedModal, setEditData, handleValueChange, isAdmin } = props;

  useEffect(() => {
    function handleClickOutside(event) {
      if (!event.target.closest('.users-list__menu')) {
        setOpenMenu('');
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className="languages-list">
      <div className="languages-list__top">
        {isMobile() ? (
          <>
            <Formik initialValues={{ search: '' }} onSubmit={(values) => console.log(values)}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="languages-list__form">
                  <Field
                    component={Input}
                    placeholder="Wyszukaj język"
                    name="search"
                    className="languages-list__search"
                    theshold={500}
                    maxLength={32}
                    required
                    removable
                    onChange={(values) => handleValueChange(values)}
                  />
                </form>
              )}
            </Formik>
          </>
        ) : isAdmin ? (
          <Button label="Dodaj +" className="languages-list__button" onClick={() => setOpenedModal('addLanguage')} />
        ) : null}
      </div>

      <div className="languages-list__wrapper">
        {languagesList.map((language) => (
          <div key={language.value} className="languages-list__language">
            <img width={27} src={language.flag?.url} alt={language.label} />
            <p>{language.label}</p>
            <div className="users-list__td users-list__menu">
              <span onClick={() => (language.value === openMenu ? setOpenMenu('') : setOpenMenu(language.value))}>
                <SVG type={SVG_TYPE.DOTS} />
              </span>
              <div className={`users-list__menu-dropdown ${language.value === openMenu ? 'users-list__menu-dropdown--show' : ''}`}>
                <div
                  className="users-list__menu-dropdown-item"
                  onClick={() => {
                    setEditData(language);
                    setOpenedModal('editLanguage');
                    setOpenMenu('');
                  }}>
                  Edytuj
                </div>
                <div
                  className="users-list__menu-dropdown-item"
                  onClick={async () => {
                    setEditData(language);
                    setOpenedModal('deleteLanguage');
                    setOpenMenu('');
                  }}>
                  Usuń
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Languages;
