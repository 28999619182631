import { Order } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Order.CHANGE_CURRENT_ORDER_INIT,
});

const success = (data: any) => ({
  type: Order.CHANGE_CURRENT_ORDER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Order.CHANGE_CURRENT_ORDER_FAILURE,
});

const changeCurrentOrder = (uuid) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(success(uuid));
  } catch (err: any) {
    dispatch(failure());
    console.log('CHANGE_CURRENT_ORDER', err.response);
  }
};

export default changeCurrentOrder;
