import { Input, Radio } from 'Components/form';
import SVG from 'Components/SVG';
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import { SVG_TYPE } from 'Shared/enums';

function ContactForm({ errors, translateToOptions, currentStep, setFieldValue, getCompanyFromNip, values, setValues }: any) {
  const [selectedForm, setSelectedForm] = useState('person');
  const isIndividual = selectedForm === 'person';
  const [nip, setNip] = useState<string>(values.nip);

  useEffect(() => {
    setFieldValue('type', selectedForm);
  }, [selectedForm]);

  useEffect(() => {
    setFieldValue('type', 'person');
  }, []);

  const updateNip = async () => {
    const result = await getCompanyFromNip(nip);
    setValues({
      ...values,
      nip: result.nip,
      companyName: result.companyName,
      street: result.street,
      city: result.city,
      postCode: result.postCode,
      houseNumber: result.houseNumber,
    });
  };

  useEffect(() => {
    if (nip?.length === 10) {
      updateNip();
    }
  }, [nip]);

  return (
    <div className={`contact-form container-left__slide container-left__slide--${currentStep}`}>
      <div className="contact-form__header">
        <p
          onClick={() => setSelectedForm('person')}
          className={`contact-form__header-item ${selectedForm === 'person' ? 'contact-form__header-item--active' : ''}`}>
          Osoba indywidualna
        </p>
        <p
          onClick={() => setSelectedForm('company')}
          className={`contact-form__header-item ${selectedForm === 'company' ? 'contact-form__header-item--active' : ''}`}>
          Firma
        </p>
      </div>
      <div className="contact-form__wrapper">
        <div className="contact-form__row">
          {isIndividual ? (
            <Field
              className={'contact-form__input'}
              label="Imię"
              name="firstName"
              id="firstName"
              placeholder="Wpisz swoje imię"
              required
              component={Input}
              errors={errors}
              maxLength={16}
              theshold={500}
              options={translateToOptions}
            />
          ) : (
            <div className="contact-form__row contact-form__row--splited">
              <Field
                className={'contact-form__input contact-form__input--small'}
                label="NIP"
                name="nipCountry"
                id="nipCountry"
                placeholder="PL"
                required
                component={Input}
                theshold={500}
                maxLength={3}
                errors={errors}
                options={translateToOptions}
              />
              <Field
                className={'contact-form__input'}
                name="nip"
                id="nip"
                placeholder="Wpisz nr NIP"
                required
                component={Input}
                theshold={0}
                errors={errors}
                maxLength={10}
                type="number"
                pattern="[0-9]*"
                inputmode="decimal"
                onChange={(e) => {
                  setNip(e);
                }}
                options={translateToOptions}
              />
              <div className="contact-form__tooltip-wrapper">
                <SVG type={SVG_TYPE.INFO_CIRCLE} />
                <span className="contact-form__tooltip">
                  Po wpisaniu nr NIP automatycznie uzupełnimy pozostałe dane firmy, jeśli znajduje się ona w bazie danych GUS.
                </span>
              </div>
            </div>
          )}
          {isIndividual ? (
            <Field
              className={'contact-form__input'}
              label="Nazwisko"
              name="lastName"
              id="lastName"
              placeholder="Wpisz swoje nazwisko"
              required
              component={Input}
              theshold={500}
              maxLength={24}
              errors={errors}
              options={translateToOptions}
            />
          ) : (
            <Field
              className={'contact-form__input'}
              label="Nazwa firmy"
              name="companyName"
              id="companyName"
              placeholder="Wpisz nazwę firmy"
              required
              component={Input}
              errors={errors}
              theshold={500}
              maxLength={64}
              options={translateToOptions}
            />
          )}
        </div>

        {isIndividual ? (
          <div className="contact-form__row">
            <Field
              className={'contact-form__input'}
              label="Email"
              name="email"
              id={currentStep === 3 ? 'email' : null}
              placeholder="Wpisz adres email"
              required
              component={Input}
              theshold={500}
              inputmode="email"
              errors={errors}
              maxLength={64}
              options={translateToOptions}
            />

            <div className="contact-form__row contact-form__row--splited">
              <Field
                className={'contact-form__input contact-form__input--small'}
                label="Telefon"
                name="phonePrefix"
                placeholder="+48"
                id={currentStep === 3 ? 'phonePrefix' : null}
                required
                component={Input}
                inputmode="numeric"
                theshold={500}
                maxLength={3}
                errors={errors}
                options={translateToOptions}
              />
              <Field
                className={'contact-form__input'}
                name="phoneNumber"
                id={currentStep === 3 ? 'phoneNumber' : null}
                placeholder="Wpisz nr telefonu"
                required
                component={Input}
                errors={errors}
                inputmode="tel"
                theshold={500}
                maxLength={9}
                type="number"
                pattern="[0-9]*"
                options={translateToOptions}
              />
            </div>
          </div>
        ) : null}

        <div className="contact-form__row">
          <Field
            className={'contact-form__input'}
            label="Ulica"
            id="street"
            name="street"
            placeholder="Wpisz ulicę"
            required
            component={Input}
            theshold={500}
            maxLength={48}
            errors={errors}
            options={translateToOptions}
          />
          <Field
            className={'contact-form__input'}
            label="Nr"
            id="houseNumber"
            name="houseNumber"
            placeholder="Wpisz nr budynku/mieszkania"
            required
            component={Input}
            theshold={500}
            maxLength={10}
            errors={errors}
          />
        </div>

        <div className="contact-form__row">
          <Field
            className={'contact-form__input'}
            label="Kod pocztowy"
            name="postCode"
            id="postCode"
            placeholder="Wpisz kod pocztowy"
            required
            maxLength={6}
            component={Input}
            theshold={500}
            errors={errors}
            options={translateToOptions}
          />
          <Field
            className={'contact-form__input'}
            label="Miasto"
            name="city"
            id="city"
            maxLength={24}
            placeholder="Wpisz miasto"
            required
            onBlur={(e) => {
              setFieldValue('city', e.target.value.toUpperCase());
            }}
            component={Input}
            errors={errors}
            theshold={500}
            options={translateToOptions}
          />
        </div>
        {!isIndividual ? (
          <div className="contact-form__row">
            <Field
              className={'contact-form__input'}
              label="Email"
              name="email"
              id={currentStep === 3 ? 'email' : null}
              placeholder="Wpisz adres email"
              required
              component={Input}
              maxLength={64}
              theshold={500}
              errors={errors}
              inputmode="email"
              options={translateToOptions}
            />

            <div className="contact-form__row contact-form__row--splited">
              <Field
                className={'contact-form__input contact-form__input--small'}
                label="Telefon"
                name="phonePrefix"
                placeholder="+48"
                id={currentStep === 3 ? 'phonePrefix' : null}
                required
                maxLength={3}
                component={Input}
                inputmode="numeric"
                theshold={500}
                errors={errors}
                options={translateToOptions}
              />
              <Field
                className={'contact-form__input'}
                name="phoneNumber"
                id={currentStep === 3 ? 'phoneNumber' : null}
                placeholder="Wpisz nr telefonu"
                required
                component={Input}
                errors={errors}
                theshold={500}
                inputmode="tel"
                maxLength={9}
                type="number"
                pattern="[0-9]*"
                options={translateToOptions}
              />
            </div>
          </div>
        ) : null}
        {!isIndividual ? (
          <div className="contact-form__row">
            <Field
              component={Radio}
              label="Forma płatności"
              name="paymentMethod"
              id="paymentMethod"
              required
              className={'contact-form__input contact-form__input--radio'}
              row
              errors={errors}
              options={[
                { value: 'instant', label: 'Płatność natychmiastowa' },
                { value: 'bank', label: 'Przelew bankowy' },
              ]}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ContactForm;
