import { specialization } from 'Assets/specialization';
import Button from 'Components/Button';
import { Select } from 'Components/form';
import SVG from 'Components/SVG';
import { ErrorMessage, Field } from 'formik';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { SVG_TYPE } from 'Shared/enums';

function ServiceLevels({
  values,
  setFieldValue,
  currentOrder,
  setFormValues,
  currentStep,
  setIsDeadlineModalOpen,
  setFieldError,
  customDeadline,
  setCustomDeadline,
  errors,
  touched,
}: any) {
  const [selectedService, setSelectedService] = useState(undefined);
  const [centralService, setCentralService] = useState(undefined);
  const [deadline, setDeadline] = useState(undefined);
  const sliderWrapper = document.querySelector('.service-levels__wrapper');
  const orderQualityPrices = currentOrder?.orderQualityPrices;
  const orderQualityDeadline = currentOrder?.orderQualityDeadline;
  const isExpertAvailable = !!values?.specialization?.length && !!orderQualityPrices?.expert;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [initial, setInitial] = useState(true);
  const phoneRef = useRef(null);

  useEffect(() => {
    const temporaryDeadline = moment(values.deadline);
    setDeadline(temporaryDeadline);
  }, [values?.deadline]);

  useEffect(() => {
    setSelectedService(values.quality);
  }, [values?.quality]);

  useEffect(() => {
    if (!isExpertAvailable && selectedService === 'expert') {
      setSelectedService(undefined);
    }
  }, [isExpertAvailable]);

  useEffect(() => {
    if (currentStep === 2 && initial) {
      setInitial(false);
      if (sliderWrapper) {
        const sliderWidth = sliderWrapper?.clientWidth;
        const sliderScrollWidth = sliderWrapper?.scrollWidth;
        const maxScroll = sliderScrollWidth - sliderWidth;
        const scrollPosition = maxScroll / 2;
        sliderWrapper.scrollTo(scrollPosition, 0);
      }
    }
  }, [currentStep]);

  useEffect(() => {
    const sliderScrollWidth = sliderWrapper?.scrollWidth;
    const sliderWidth = sliderWrapper?.clientWidth;
    const maxScroll = sliderScrollWidth - sliderWidth;
    const scrollPosition = maxScroll / 2;
    switch (centralService) {
      case 'basic':
        sliderWrapper.scrollTo({ left: 0, behavior: 'smooth' });
        break;
      case 'standard':
        sliderWrapper.scrollTo({ left: scrollPosition, behavior: 'smooth' });
        break;
      case 'expert':
        sliderWrapper.scrollTo({ left: sliderScrollWidth, behavior: 'smooth' });
        break;
    }
  }, [centralService]);

  useEffect(() => {
    const sliderWrapper = document.querySelector('.service-levels__wrapper');
    if (sliderWrapper.getAttribute('hasEvent') !== 'true') {
      sliderWrapper.addEventListener('scroll', (e: any) => {
        const sliderWidth = sliderWrapper?.clientWidth;
        const sliderScrollWidth = sliderWrapper?.scrollWidth;
        const maxScroll = sliderScrollWidth - sliderWidth;
        const scrollPosition = e.target.scrollLeft;
        const scrollPercent = (scrollPosition / maxScroll) * 100;
        if (scrollPercent < 20) {
          setCentralService('basic');
        } else if (scrollPercent > 20 && scrollPercent < 80) {
          setCentralService('standard');
        } else if (scrollPercent > 80) {
          setCentralService('expert');
        }
      });
      sliderWrapper.setAttribute('hasEvent', 'true');
    }
  }, []);

  useEffect(() => {
    if (selectedService && orderQualityDeadline) {
      setFieldValue('quality', selectedService);
      setFieldError('quality', undefined);
      setFieldValue('deadline', orderQualityDeadline[selectedService]);
      setCustomDeadline('');
    }
  }, [selectedService]);

  useEffect(() => {
    setFormValues(values);
  }, [values]);
  return (
    <div className={`container-left__slide ${isMenuOpen ? 'container-left__slide--opened-menu' : ''} container-left__slide--${currentStep}`}>
      <div className="service-levels__wrapper">
        <div className={`service-levels ${orderQualityDeadline?.standard ? '' : 'service-levels--single'}`} id="quality">
          <div className={`${orderQualityDeadline?.standard ? 'service-levels__first-card' : ''}`}>
            <div
              className={`service-levels__card ${centralService === 'basic' ? 'service-levels__card--center' : ''} ${
                selectedService ? (selectedService === 'basic' ? 'service-levels__card--selected' : 'service-levels__card--not-selected') : ''
              } ${orderQualityDeadline?.basic ? '' : 'service-levels__card--hidden'}`}
              onClick={() => orderQualityDeadline?.basic && setSelectedService('basic')}>
              <div className={`service-levels__header`}>
                <h2>BASIC</h2>
                <div className="service-levels__check-wrapper">
                  <SVG type={SVG_TYPE.CIRCLE} className={selectedService === 'basic' ? 'svg--circle-hidden' : ''} />
                  <SVG type={SVG_TYPE.CHECKED_GOLD} className={selectedService === 'basic' ? 'svg--checked-visible' : ''} />
                </div>
              </div>
              <div className="service-levels__card-wrapper">
                <div className="service-levels__team">
                  <div className="service-levels__team-icons">
                    <SVG type={SVG_TYPE.PERSON} />
                  </div>
                  <div className={`service-levels__team-item `}>Tłumacz</div>
                  <div className="service-levels__tooltip-wrapper">
                    <p className={`service-levels__tooltip-header `}>Więcej szczegółów</p>
                    <span className="service-levels__tooltip">
                      Pakiet BASIC obejmuje tłumaczenie dokumentów z języka źródłowego na język obcy przez wykwalifikowanego tłumacza z naszego
                      zespołu.
                    </span>
                  </div>
                </div>
                <p className={`service-levels__price `}>{orderQualityPrices?.basic} zł</p>
                <div className={`service-levels__time `}>
                  Termin realizacji do:
                  <span className={`service-levels__date `}>
                    {selectedService === 'basic' && currentOrder.deadline && customDeadline === 'basic'
                      ? deadline.format('DD.MM.yyyy, HH:mm')
                      : moment(orderQualityDeadline?.basic).format('DD.MM.yyyy, HH:mm')}
                  </span>
                  <p className={`service-levels__need-it-quicker `} onClick={() => setIsDeadlineModalOpen(true)}>
                    Potrzebujesz go szybciej?
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`service-levels__card ${centralService === 'standard' ? 'service-levels__card--center' : ''} ${
              selectedService ? (selectedService === 'standard' ? 'service-levels__card--selected' : 'service-levels__card--not-selected') : ''
            } ${orderQualityDeadline?.standard ? '' : 'service-levels__card--hidden'}`}
            onClick={() => orderQualityDeadline?.standard && setSelectedService('standard')}>
            {/* <SVG type={SVG_TYPE.RECOMMENDATION} className="svg--recommendation" /> */}
            <div
              className={`service-levels__header service-levels__header--standard ${
                orderQualityDeadline?.standard ? '' : 'service-levels__header--disabled'
              }`}>
              <h2>COMFORT</h2>
              <div className="service-levels__check-wrapper">
                <SVG type={SVG_TYPE.CIRCLE} className={selectedService === 'standard' ? 'svg--circle-hidden' : ''} />
                <SVG type={SVG_TYPE.CHECKED_GOLD} className={selectedService === 'standard' ? 'svg--checked-visible' : ''} />
              </div>
            </div>
            <div className="service-levels__recommendation">
              <p>REKOMENDOWANY</p>
            </div>
            <div className="service-levels__card-wrapper">
              <div className="service-levels__team">
                <div className="service-levels__team-icons">
                  <SVG type={SVG_TYPE.PERSON} />
                  <SVG type={SVG_TYPE.PERSON} />
                </div>
                <div className={`service-levels__team-item `}>Tłumacz</div>
                <div
                  className={`service-levels__team-item service-levels__team-item--plus ${
                    orderQualityDeadline?.standard ? '' : 'service-levels--disabled'
                  }`}>
                  +
                </div>
                <div className={`service-levels__team-item `}>Kontrola jakości</div>
                <div className="service-levels__tooltip-wrapper">
                  <p className={`service-levels__tooltip-header `}>Więcej szczegółów</p>
                  <span className="service-levels__tooltip">
                    Pakiet COMFORT obejmuje tłumaczenie dokumentów z języka źródłowego na język obcy przez wykwalifikowanego tłumacza z naszego
                    zespołu, a następnie przetłumaczony tekst jest sprawdzany pod względem poprawności językowej przez native speakera.
                  </span>
                </div>
              </div>
              <p className={`service-levels__price `}>{orderQualityPrices?.standard} zł</p>
              <div className={`service-levels__time `}>
                Termin realizacji do:
                <span className={`service-levels__date `}>
                  {selectedService === 'standard' && currentOrder.deadline && customDeadline === 'standard'
                    ? deadline.format('DD.MM.yyyy, HH:mm')
                    : moment(orderQualityDeadline?.standard).format('DD.MM.yyyy, HH:mm')}
                </span>
                <p className={`service-levels__need-it-quicker `} onClick={() => setIsDeadlineModalOpen(true)}>
                  Potrzebujesz go szybciej?
                </p>
              </div>
            </div>
          </div>

          <div className={`service-levels__last-card ${orderQualityDeadline?.expert ? '' : 'service-levels__card--hidden'}`}>
            <div
              className={`service-levels__card ${centralService === 'expert' ? 'service-levels__card--center' : ''} ${
                selectedService && isExpertAvailable
                  ? selectedService === 'expert'
                    ? 'service-levels__card--selected'
                    : 'service-levels__card--not-selected'
                  : ''
              } ${orderQualityDeadline?.expert ? '' : 'service-levels__card--hidden'}`}
              onClick={() => isExpertAvailable && orderQualityDeadline?.expert && setSelectedService('expert')}>
              <div className={`service-levels__card-overlay ${isExpertAvailable ? '' : 'service-levels__card-overlay--show'}`}>
                <p>Pakiet EXPERT dostępny po wybraniu specjalizacji tłumaczenia.</p>
                <Field
                  label="Specjalizacja"
                  name="specialization"
                  placeholder="Wybierz specjalizację"
                  component={Select}
                  errors={ErrorMessage}
                  options={specialization}
                  onMenuOpen={() => setIsMenuOpen(true)}
                  onMenuClose={() => setIsMenuOpen(false)}
                  // menuIsOpen={true}
                />
              </div>
              <div className={`service-levels__header ${isExpertAvailable ? '' : 'service-levels__header--disabled'}`}>
                <h2>EXPERT</h2>
                <div className={`service-levels__check-wrapper`}>
                  <SVG type={SVG_TYPE.CIRCLE} className={selectedService === 'expert' ? 'svg--circle-hidden' : ''} />
                  <SVG type={SVG_TYPE.CHECKED_GOLD} className={selectedService === 'expert' ? 'svg--checked-visible' : ''} />
                </div>
              </div>
              <div className="service-levels__card-wrapper">
                <div className="service-levels__team">
                  <div className={`service-levels__team-icons ${isExpertAvailable ? '' : 'service-levels--disabled'}`}>
                    <SVG type={SVG_TYPE.PERSON} />
                    <SVG type={SVG_TYPE.PERSON} />
                    <SVG type={SVG_TYPE.PERSON} />
                  </div>
                  <div className={`service-levels__team-item ${isExpertAvailable ? '' : 'service-levels--disabled'}`}>Tłumacz</div>
                  <div className={`service-levels__team-item service-levels__team-item--plus ${isExpertAvailable ? '' : 'service-levels--disabled'}`}>
                    +
                  </div>
                  <div className={`service-levels__team-item ${isExpertAvailable ? '' : 'service-levels--disabled'}`}>Kontrola jakości</div>
                  <div className={`service-levels__team-item service-levels__team-item--plus ${isExpertAvailable ? '' : 'service-levels--disabled'}`}>
                    +
                  </div>
                  <div className={`service-levels__team-item ${isExpertAvailable ? '' : 'service-levels--disabled'}`}>Weryfikator branżowy</div>
                  <div className="service-levels__tooltip-wrapper">
                    <p className={`service-levels__tooltip-header ${isExpertAvailable ? '' : 'service-levels--disabled'}`}>Więcej szczegółów</p>
                    <span className="service-levels__tooltip">
                      Pakiet EXPERT obejmuje tłumaczenie dokumentów z języka źródłowego na język obcy przez wykwalifikowanego tłumacza z naszego
                      zespołu, a następnie przetłumaczony tekst jest sprawdzany pod względem poprawności językowej przez native speakera. Ostatnim
                      procesem jest weryfikacja branżowa użytych zwrotów przez weryfikatora branżowego.
                    </span>
                  </div>
                </div>
                {isExpertAvailable ? (
                  <p className={`service-levels__price ${isExpertAvailable ? '' : 'service-levels--disabled'}`}>{orderQualityPrices?.expert} zł</p>
                ) : (
                  <p className={`service-levels__expert-not-available ${isExpertAvailable ? '' : 'service-levels--disabled'}`}>
                    Pakiet EXPERT dostępny po wybraniu specjalizacji tłumaczenia
                  </p>
                )}
                <div className={`service-levels__time ${isExpertAvailable ? '' : 'service-levels--disabled'}`}>
                  Termin realizacji do:
                  <span className={`service-levels__date ${isExpertAvailable ? '' : 'service-levels--disabled'}`}>
                    {' '}
                    {selectedService === 'expert' && currentOrder.deadline && customDeadline === 'expert'
                      ? deadline.format('DD.MM.yyyy, HH:mm')
                      : moment(orderQualityDeadline?.expert).format('DD.MM.yyyy, HH:mm')}
                  </span>
                  <p
                    className={`service-levels__need-it-quicker ${isExpertAvailable ? '' : 'service-levels--disabled'}`}
                    onClick={() => setIsDeadlineModalOpen(true)}>
                    Potrzebujesz go szybciej?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className={`service-levels__error ${errors.quality && touched.quality ? 'service-levels__error--visible' : ''}`}>{errors.quality}</p>
      </div>
      <div className="service-levels__contact">
        <span className="service-levels__contact-text">
          <p>Nie jesteś pewny, który pakiet wybrać?</p>
          <p> Masz więcej pytań?</p>
          <p className="service-levels__contact-us">
            {' '}
            Skontaktuj się z nami! Jesteśmy dostępni w godzinach<strong> 8:00 - 18:00</strong>
          </p>
        </span>
        <a href="tel:48733135033">
          <Button
            className="service-levels__contact-us--desktop"
            onClick={() => console.log('')}
            label="+48 733 135 033"
            stroke
            iconOnLeft
            svg_type={SVG_TYPE.PHONE}
          />
          <Button
            className="service-levels__contact-us--mobile"
            onClick={() => phoneRef.current.click()}
            label="+48 733 135 033"
            stroke
            iconOnLeft
            svg_type={SVG_TYPE.PHONE}
          />
        </a>
      </div>
    </div>
  );
}

export default ServiceLevels;
