import Button from 'Components/Button';
import DropFileInput from 'Components/DropFile/DropFile';
import { Input } from 'Components/form';
import Modal from 'Components/Modal';
import { Field, Formik } from 'formik';
import { useEffect, useRef } from 'react';
import { VALIDATION_MESSAGES } from 'Shared/enums';
import * as yup from 'yup';

interface EditLanguageProps {
  isOpen: boolean;
  onClose?: () => void;
  edit: boolean;
  data?: {
    label: string;
    value: string;
    flag: File & {
      uuid: string;
    };
  };
  currentPage: number;
  selectedFilters: any;
  addLanguage: (data: { language: string }) => any;
  editLanguage: (userUuid: string, data: { language: string }, isFileChange: boolean) => any;
  getLanguagesList: (selectedFilters) => any;
  addLanguageFlag: (data: { files: FileList; languageUuid: string }) => any;
}

function EditLanguageModal({
  isOpen,
  onClose,
  edit,
  data,
  selectedFilters,
  addLanguage,
  editLanguage,
  getLanguagesList,
  addLanguageFlag,
}: EditLanguageProps) {
  const formRef = useRef(null);
  const validationSchema = yup.object().shape({
    language: yup.string().required(VALIDATION_MESSAGES.REQUIRED).min(5, VALIDATION_MESSAGES.MIN_5).max(16, VALIDATION_MESSAGES.TOO_LONG),
    files: yup.mixed().required(VALIDATION_MESSAGES.REQUIRED),
  });

  useEffect(() => {
    isOpen && formRef.current.resetForm();
  }, [isOpen]);

  const handleFileDrop = (files: FileList) => {
    console.log(files);
  };

  const handleOnSubmit = async (values: any) => {
    const valuesCopy = { ...values };
    const result = edit ? await editLanguage(data.value, { ...valuesCopy }, values.files[0]) : await addLanguage(valuesCopy);
    if (result.uuid) {
      const response = edit ? true : await addLanguageFlag({ files: values.files, languageUuid: result.uuid });
      if (response) {
        getLanguagesList(selectedFilters.search);
        onClose();
      }
      formRef.current.resetForm();
    } else {
      formRef.current.setErrors({ email: VALIDATION_MESSAGES.EMAIL_TAKEN });
    }
  };
  const initialValues = {
    language: data?.label || '',
    files: data?.flag || '',
    initialFiles: data?.flag || '',
    initialLanguage: data?.label || '',
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="add-user-modal__header">{edit ? 'Edycja języka' : 'Dodawanie języka'}</h2>
      <div className="add-user-modal__wrapper">
        <Formik
          validationSchema={validationSchema}
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values) => handleOnSubmit(values)}>
          {({ handleSubmit, errors, values, isSubmitting }) => (
            <form onSubmit={handleSubmit} className="add-user-modal__form">
              <div className="add-user-modal__form-row">
                <Field label="Język" name="language" required component={Input} errors={errors} treshold={0} placeholder="Język" maxLength={32} />
                {isOpen ? (
                  <Field
                    component={DropFileInput}
                    onFileDrop={handleFileDrop}
                    placeholder="Wybierz język"
                    name="files"
                    label="Flaga"
                    required
                    errors={errors}
                    accept=".png,.jpg,.jpeg"
                  />
                ) : null}
              </div>
              <div className="add-user-modal__form-row add-translation-modal__buttons">
                <Button label="Anuluj" className="add-user-modal__add-button" type="button" cancel onClick={() => onClose()} round stroke />
                <Button
                  label="Zapisz"
                  className="add-user-modal__add-button"
                  type="submit"
                  onClick={() => console.log(values)}
                  disabled={isSubmitting}
                  round
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default EditLanguageModal;
