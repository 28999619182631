import { Order } from '../EnumTypes/ActionEnumTypes';
import * as OrderModule from '../../../Modules/OrderModule';
import { toast } from 'react-toastify';
import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Order.CREATE_PAYMENT_INIT,
});

const success = (data: any) => ({
  type: Order.CREATE_PAYMENT_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Order.CREATE_PAYMENT_FAILURE,
});

const createPayment = (orderUuid: string) => async (dispatch) => {
  dispatch(init());
  const id = toast.loading('trwa tworzenie płatności...');
  try {
    const data: any = await OrderModule.Connector.createPayment(orderUuid);
    toast.update(id, {
      render: 'Pomyślnie utworzono płatność, za chwilę nastąpi przekierowanie do płatności',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} />,
    });
    const link = document.createElement('a');
    link.href = data.data.session_url;
    link.download = 'download';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    dispatch(success(data));
  } catch (err: any) {
    dispatch(failure());
    toast.update(id, {
      render: 'Nie udało się utworzyć płatności',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_X} />,
    });
    
  }
};

export default createPayment;
