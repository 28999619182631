import * as LanguageModule from '../../../Modules/LanguagesModule';
import * as FilesModule from '../../../Modules/FilesModule';
import { Languages } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Languages.EDIT_LANGUAGE_INIT,
});

const success = (data: any) => ({
  type: Languages.EDIT_LANGUAGE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Languages.EDIT_LANGUAGE_FAILURE,
});

const editLanguage = (uuid, formData:any,isFileChange) => async (dispatch) => {
    const dataToSend = {
        ...formData,
    };
  dataToSend.initialFiles = undefined;
  dataToSend.initialLanguage = undefined;
  dispatch(init());
  let data:any = {uuid:true};
  try {
    console.log(formData.files)
    if (formData.initialLanguage !== formData.language) {
       data = await LanguageModule.Connector.editLanguage(uuid, dataToSend);
    }
    if (!!isFileChange) {
      // await FilesModule.Connector.deleteFile({ uuid: formData.initialFiles.uuid });
      const file = new FormData();
      file.append('files', formData.files[0]);
      await FilesModule.Connector.addLanguageFlag({ formData: file, languageUuid:uuid });
    }
      dispatch(success(data));
    toast.success('Pomyślnie edytowano język', { icon: <SVG variant={SVG_TYPE.CIRCLE_CHECKED} /> });
    return data.data?.language || {uuid:true};
  } catch (err: any) {
    dispatch(failure());
    toast.error('Nie udało się edytować języka', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> });
    console.log(err)
    return false;
  }
};

export default editLanguage;
