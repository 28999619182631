import SVG from 'Components/SVG';
import { Field, Formik } from 'formik';
import { ROUTES, SVG_TYPE, VALIDATION_MESSAGES } from 'Shared/enums';
import { MainType } from './Container';
import * as Yup from 'yup';
import { Input } from 'Components/form';
import Button from 'Components/Button';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Back from 'Components/Back';

function ActivatePage(props: MainType) {
  const { actions, authorisedUser } = props;
  const { setIsNaviagtionHidden, activateUser, checkToken } = actions;
  const navigate = useNavigate();
  const backToLogin = localStorage.getItem('lastPage') === '/dashboard';
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const formRef = useRef(null);
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');

  const onSubmit = async (values) => {
    setIsSubmitted(true);
    if (token) {
      await activateUser(token, values.newPassword);
    }
  };

  const validation = () => {
    return Yup.object().shape({
      newPassword: Yup.string().min(8, VALIDATION_MESSAGES.PASSWORD_LENGTH).required(VALIDATION_MESSAGES.REQUIRED),
      passwordConfirmation: Yup.string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .oneOf([Yup.ref('newPassword'), null], VALIDATION_MESSAGES.PASSWORD_MATCH),
    });
  };

  useEffect(() => {
    setIsNaviagtionHidden(true);
    return () => {
      setIsNaviagtionHidden(false);
    };
  }, []);

  const checkIfTokenIsValid = async () => {
    const result = await checkToken(token);
    if (!result) {
      navigate({ search: '?variant=activate', pathname: ROUTES.LINK_EXPIRED });
    }
  };

  useEffect(() => {
    checkIfTokenIsValid();
  }, [token]);

  useEffect(() => {
    authorisedUser && (setIsNaviagtionHidden(true), navigate(ROUTES.DASHBOARD_ORDERS));
  }, [authorisedUser]);

  const initialValues = {
    email: '',
  };
  return (
    <div className="page restore-password">
      <span className="restore-password__box"></span>
      <div className="restore-password__wrapper">
        <Back onClick={() => (backToLogin ? navigate(ROUTES.HOME_PAGE) : navigate(-1))} className="restore-password__back" />
        <SVG type={SVG_TYPE.LOGO} />
        <h2 className="restore-password__title">{isSubmitted ? 'Proces aktywacji przebiegł pomyślnie' : 'Utwórz nowe hasło'}</h2>
        <p className="restore-password__subtitle">{isSubmitted ? 'Możesz teraz zalogować się za pomocą nowego hasła.' : null}</p>

        <Formik
          isInitialValid={false}
          validationSchema={validation}
          initialValues={initialValues}
          validateOnBlur={false}
          enableReinitialize
          innerRef={formRef}
          onSubmit={async (values) => onSubmit(values)}>
          {({ handleSubmit, errors, isSubmitting }) => (
            <form className="restore-password__form" onSubmit={handleSubmit}>
              {isSubmitted ? null : (
                <>
                  <Field
                    className={'restore-password__input'}
                    label="Nowe hasło"
                    name="newPassword"
                    placeholder="Wpisz nowe hasło"
                    required
                    component={Input}
                    errors={errors}
                    maxLength={24}
                    variant="password"
                    treshold={0}
                  />
                  <Field
                    className={'restore-password__input'}
                    label="Powtórz hasło"
                    name="passwordConfirmation"
                    placeholder="Powtórz nowe hasło"
                    required
                    variant="password"
                    maxLength={24}
                    component={Input}
                    treshold={0}
                    errors={errors}
                  />
                </>
              )}
              {isSubmitted ? (
                <Button label="Zaloguj się" type="button" onClick={() => navigate(ROUTES.LOGIN_PAGE)} round disabled={isSubmitting} />
              ) : (
                <Button label="Zapisz" type="submit" onClick={() => null} round disabled={isSubmitting} />
              )}
            </form>
          )}
        </Formik>
      </div>
      <SVG type={SVG_TYPE.RESTORE_PASSWORD} className="restore-password__svg" />
    </div>
  );
}

export default ActivatePage;
