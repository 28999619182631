import { connect } from 'react-redux';
import ActivatePage from './Main';
import { Actions as AuthActions } from 'Modules/AuthModule';
import { bindActionCreators } from 'redux';
import { authorizedUser } from 'Store/Selectors/AuthSelector';
import { Actions as viewManagmentModule } from 'Modules/ViewManagementModule';

type ConnectedP = {
  authorisedUser: any;
};

type DispatchedP = {
  actions: {
    getResetPasswordToken: (email: string) => any;
    setIsNaviagtionHidden: (boolean) => void;
    activateUser: (token: string, newPassword: string) => any;
    checkToken: (token: string) => any;
  };
};
const mapStateToProps = (state) => ({
  authorisedUser: authorizedUser(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getResetPasswordToken: AuthActions.getResetPasswordToken,
      setIsNaviagtionHidden: viewManagmentModule.setIsNaviagtionHidden,
      activateUser: AuthActions.activateUser,
      checkToken: AuthActions.checkToken,
    },
    dispatch,
  ),
});
export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(ActivatePage);
