interface OrderDataProps {
  data: any;
}

function ContactData({ data }: OrderDataProps) {
  const contactData = data.contactData;
  const isCompany = contactData.type === 'company';
  return (
    <div className="single-order__content-wrap">
      <h2 className={`contact-data__type ${isCompany ? 'contact-data__type--company' : ''}`}> {isCompany ? 'firma' : 'osoba indywidualna'}</h2>

      {isCompany ? (
        <div className="contact-data__item">
          <p className="contact-data__item-label">Nazwa firmy</p>
          <p className="contact-data__item-value">{contactData?.companyName}</p>
        </div>
      ) : (
        <div className="contact-data__item">
          <p className="contact-data__item-label">Imię</p>
          <p className="contact-data__item-value">{contactData?.firstName}</p>
        </div>
      )}

      {isCompany ? (
        <div className="contact-data__item">
          <p className="contact-data__item-label">Nip</p>
          <div className="contact-data__item-label--wrap">
            {contactData?.nipCountry ? <p className="contact-data__item-value">{contactData?.nipCountry}</p> : null}
            <p className="contact-data__item-value">{contactData?.nip}</p>
          </div>
        </div>
      ) : (
        <div className="contact-data__item">
          <p className="contact-data__item-label">Nazwisko</p>
          <p className="contact-data__item-value">{contactData?.lastName}</p>
        </div>
      )}

      <div className="contact-data__item">
        <p className="contact-data__item-label">Email</p>
        <p className="contact-data__item-value">{contactData?.email}</p>
      </div>

      <div className="contact-data__item">
        <p className="contact-data__item-label">Telefon</p>
        <p className="contact-data__item-value">
          {contactData?.phonePrefix} {contactData?.phoneNumber.toString().replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3')}
        </p>
      </div>

      <div className="contact-data__row">
        <div className="contact-data__item">
          <p className="contact-data__item-label">Ulica</p>
          <p className="contact-data__item-value">{contactData?.street}</p>
        </div>

        <div className="contact-data__item">
          <p className="contact-data__item-label">Nr</p>
          <p className="contact-data__item-value">{contactData?.houseNumber}</p>
        </div>
      </div>

      <div className="contact-data__row">
        <div className="contact-data__item">
          <p className="contact-data__item-label">Kod pocztowy</p>
          <p className="contact-data__item-value">{contactData?.postCode}</p>
        </div>

        <div className="contact-data__item">
          <p className="contact-data__item-label">Miasto</p>
          <p className="contact-data__item-value">{contactData?.city}</p>
        </div>
      </div>
    </div>
  );
}

export default ContactData;
