enum VALIDATION_MESSAGES {
  REQUIRED = 'To pole jest wymagane',
  EMAIL = 'Podaj pełny adres email (np. jankowalski@domena.pl)',
  EMAIL_TAKEN = 'Podany adres email jest już zajęty',
  PASSWORD_LENGTH = 'Hasło musi zawierać co najmniej 8 znaków',
  PASSWORD_MATCH = 'Hasła muszą być takie same',
  NIP = 'Podaj poprawny numer NIP',
  PHONE = 'Podaj poprawny numer telefonu',
  PHONE_PREFIX = 'Podaj poprawny numer kierunkowy',
  POST_CODE = 'Podaj poprawny kod pocztowy',
  MIN_3 = 'Podaj co najmniej 3 znaki',
  MIN_5 = 'Podaj co najmniej 5 znaków',
  ONLY_LETTERS = 'Pole może zawierać tylko litery',
  TOO_LONG = 'Podana wartość jest za długa',
  NUMBER = 'Podaj liczbę',
  QUALITY_REQUIED = 'Wybierz pakiet, aby przejść dalej',
  MIN_0 = 'Podaj wartość większą niż 0',
  DIGITS = 'Podaj poprawną liczbę, zawierającą maksymalnie 2 cyfry po kropce',
  MAX_999 = 'Podaj wartość mniejszą niż 1000',
}

export default VALIDATION_MESSAGES;
