import SVG from 'Components/SVG';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { MainType } from './Container';
import Button from 'Components/Button';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Back from 'Components/Back';

function SuccesfulPasswordReset(props: MainType) {
  const { actions, authorisedUser } = props;
  const { setIsNaviagtionHidden } = actions;
  const navigate = useNavigate();
  const backToLogin = localStorage.getItem('lastPage') === '/dashboard';

  useEffect(() => {
    setIsNaviagtionHidden(true);
    return () => {
      setIsNaviagtionHidden(false);
    };
  }, []);

  useEffect(() => {
    authorisedUser && (setIsNaviagtionHidden(true), navigate(ROUTES.DASHBOARD_ORDERS));
  }, [authorisedUser]);

  return (
    <div className="page restore-password">
      <span className="restore-password__box"></span>
      <div className="restore-password__wrapper">
        <Back onClick={() => (backToLogin ? navigate(ROUTES.HOME_PAGE) : navigate(-1))} className="restore-password__back" />
        <SVG type={SVG_TYPE.LOGO} />
        <h2 className="restore-password__title succesful-password-reset__title">Proces resetu hasła przebiegł pomyślnie</h2>
        <p className="restore-password__subtitle succesful-password-reset__subtitle">Możesz teraz zalogować się za pomocą nowego hasła.</p>
        <Button label="Zaloguj się" type="submit" onClick={() => navigate(ROUTES.LOGIN_PAGE)} round />
      </div>
      <SVG type={SVG_TYPE.RESTORE_PASSWORD} className="restore-password__svg" />
    </div>
  );
}

export default SuccesfulPasswordReset;
