import Button from 'Components/Button';
import Modal from 'Components/Modal';
import SVG from 'Components/SVG';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { toast } from 'react-toastify';
import { SVG_TYPE } from 'Shared/enums';

interface DeadlineModalProps {
  isOpen: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  onClose?: () => void;
  selectedQuality: string;
  setCustomDeadline: (value: string) => void;
}

function DeadlineModal({ isOpen, onClose, setFieldValue, selectedQuality, setCustomDeadline }: DeadlineModalProps) {
  const hoursArray = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
  const [selecedDate, setSelectedDate] = useState<Date>(null);
  const [selectedHour, setSelectedHour] = useState<number>(null);
  const [maxHour, setMaxHour] = useState<number>(null);

  const onSubmit = () => {
    const combinedDate = moment(selecedDate);
    combinedDate.set('hour', selectedHour);
    combinedDate.set('minute', 0);
    setFieldValue('deadline', combinedDate.format('YYYY-MM-DD HH:mm'));
    setCustomDeadline(selectedQuality);
    onClose();
  };

  const isWorkingDay = (day) => {
    // Define your working days here
    const workingDays = [0, 1, 2, 3, 4];
    return workingDays.includes(day.getUTCDay());
  };

  const workingHours = {
    basic: 6,
    standard: 9,
    expert: 15,
  };

  const calcMinDate = () => {
    const date = moment(new Date());
    let hour = date.get('hour') + 1;
    const earliestAvailableDate = moment(new Date());

    let remainingWorkingHours = workingHours[selectedQuality];

    while (remainingWorkingHours > 0) {
      if (hour > 18) {
        earliestAvailableDate.add(1, 'day');
        earliestAvailableDate.set('hour', 9);
        earliestAvailableDate.set('minute', 0);
        hour = 9;
        if (earliestAvailableDate.weekday() === 6) {
          earliestAvailableDate.add(2, 'day');
          hour = 9;
        }
      } else {
        remainingWorkingHours -= 1;
        hour += 1;
      }
      if (hour > 18) {
        earliestAvailableDate.add(1, 'day');
        earliestAvailableDate.set('hour', 9);
        earliestAvailableDate.set('minute', 0);
        hour = 9;
        if (earliestAvailableDate.weekday() === 6) {
          earliestAvailableDate.add(2, 'day');
          hour = 9;
        }
      }
      earliestAvailableDate.set('hour', hour);
      earliestAvailableDate.set('minute', 0);
    }

    return earliestAvailableDate;
  };

  useEffect(() => {
    const minHour = calcMinDate().get('hour');
    if (moment(selecedDate).get('date') === calcMinDate().get('date')) {
      setMaxHour(minHour);
    } else {
      setMaxHour(8);
    }
  }, [selectedQuality, selecedDate]);

  useEffect(() => {
    setSelectedDate(null);
    setSelectedHour(null);
    setCustomDeadline('');
  }, [selectedQuality]);

  const formatShortWeekday = (locale, date) => ['Nd', 'Pon', 'Wt', 'Śr', 'Cz', 'Pt', 'Sb'][date.getDay()];
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="add-user-modal__header">Wybierz termin dostarczenia tłumaczenia</h2>
      <div className="deadline-modal__wrapper">
        <Calendar
          next2Label={null}
          prev2Label={null}
          className={'deadline-modal__calendar'}
          minDate={calcMinDate().toDate()}
          view={'month'}
          tileDisabled={({ date }) => !isWorkingDay(date)}
          onChange={(date) => setSelectedDate(date)}
          value={selecedDate}
          locale={'pl-PL'}
          formatShortWeekday={formatShortWeekday}
        />
        <div className="deadline-modal__hours">
          <h2 className="deadline-modal__header">Godzina</h2>
          <div className="deadline-modal__hours-list">
            {hoursArray
              .filter((hour) => hour >= maxHour)
              .map((hour) => (
                <div
                  key={hour}
                  className={`deadline-modal__hour ${selectedHour === hour ? 'deadline-modal__hour--active' : ''}`}
                  onClick={() => setSelectedHour(hour)}>
                  <p>{hour}:00</p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="deadline-modal__buttons">
        <Button label="Anuluj" onClick={() => onClose()} round cancel stroke className="deadline-modal__button" />
        <Button
          label="Potwierdź"
          onClick={() =>
            selecedDate && selectedHour
              ? onSubmit()
              : toast.error('Zaznacz datę oraz godzinę dostarczenia tłumaczenia', { icon: <SVG variant={SVG_TYPE.CIRCLE_X} /> })
          }
          round
          className="deadline-modal__button"
        />
      </div>
    </Modal>
  );
}

export default DeadlineModal;
