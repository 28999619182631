import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';
import * as OrderModule from 'Modules/OrderModule';

export function createOrder(dataToSend): AxiosPromise<OrderModule.Types.OrderForm> {
  return API.post('orders', { ...dataToSend });
}

export function confirmOrder({ dataToSend, uuid }): AxiosPromise<OrderModule.Types.OrderForm> {
  return API.put(`orders/price/${uuid.uuid}`, dataToSend);
}
export function getOrder(uuid): AxiosPromise<OrderModule.Types.OrderForm> {
  return API.get(`orders/${uuid}`);
}

export function getOrdersList(page, search): AxiosPromise<OrderModule.Types.OrderForm> {
  return API.get(`orders`, { params: { page, search } });
}

export function postOrderContactData(formData): AxiosPromise<OrderModule.Types.OrderForm> {
  return API.post(`orders/contact/data`, { ...formData });
}

export function assignEmployee(orderUuid: string, userUuid: string): AxiosPromise<OrderModule.Types.OrderForm> {
  return API.post(`orders/assign/employer`, { orderUuid, userUuid });
}

export function editOrder(orderUuid: string, dataToSend): AxiosPromise<OrderModule.Types.OrderForm> {
  return API.put(`orders/${orderUuid}`, { ...dataToSend });
}

export function createPayment(orderUuid: string): AxiosPromise<OrderModule.Types.OrderForm> {
  return API.post(`payments/create-checkout-session`, { orderUuid });
}

export function getCompanyFromNip(nip): AxiosPromise<OrderModule.Types.OrderForm> {
  return API.get(`company/info`, { params: { nip } });
}

export function getInvoice(orderUuid: string): AxiosPromise<OrderModule.Types.OrderForm> {
  return API.get(`payments/invoices/${orderUuid}`);
}
